import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {

  constructor() { }

  getParams(params) {
    let httpParams = new HttpParams();
    let paramKeys = Object.keys(params);
    for (let i = 0; i < paramKeys.length; i++) {
      let key = paramKeys[i];
      let value = params[key];
      httpParams = httpParams.append(key, value);
    }
    return httpParams;
  }
}
