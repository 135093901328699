  
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/core/services/utils/loader.service';



@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  color = 'warn';
  mode = 'indeterminate';
  isLoading: Subject<boolean> = this._loaderService.isLoading;
  showLoading: Subject<boolean> = this._loaderService.showLoading;
  
  constructor(private _loaderService: LoaderService){}
}