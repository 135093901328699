import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Roles } from 'src/app/modules/auth/model/user';
import { MatDialog } from '@angular/material';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private _router: Router,public _dialog: MatDialog) { }

  setToken(token:string):void {
    localStorage.setItem('token', token)
  }
  setRefreshToken(refreshToken:string):void {
    localStorage.setItem('refreshToken', refreshToken)
  }
  // storing to restrict unauthorized  modules loading
  setMenuList(menuList:string):void{
    localStorage.setItem('menus', menuList)
  }

  setActionList(actionList:string):void{
    localStorage.setItem('actions', actionList)
  }


// purpose to checking in guards 
  setRoles(roles:string):void{
    localStorage.setItem('roles', roles)
  }

  getRoles():any{
    return JSON.parse(localStorage.getItem('roles'));
  }

  getMenuList():any{
    return JSON.parse(localStorage.getItem('menus'));
  }
  getActionList():any{
    return JSON.parse(localStorage.getItem('actions'));
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }
  
  deleteToken():void {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    localStorage.removeItem('menus');
    localStorage.removeItem('roles');
    localStorage.removeItem('actions');
    localStorage.removeItem('filter');
    localStorage.removeItem('isfilter');
    localStorage.removeItem('isviewFilter');
    localStorage.removeItem('currentPageBooked');
    localStorage.removeItem('currentPage');

    this._dialog.closeAll();
  }

  setUserDetails(userDetails) {
    localStorage.setItem('user',userDetails);
  }
  
  getUserDetails():any {
    if(localStorage.getItem('user') === null) {
      this.clearStorageRedirect();
    }
    return JSON.parse(localStorage.getItem('user'));
  }

  redirectBasedonRoles(userDetails) {
    let role = userDetails.role.replace(/\s/g, "").toLowerCase()
    if(role == Roles.Admin.replace(/\s/g, "").toLowerCase()) {
      this._router.navigate(['beckman']);
    } 
    else if(role == Roles.Channel_Partner.replace(/\s/g, "").toLowerCase()) {
      this._router.navigate(['channel-partner']);
    }
    else if(role == Roles.Agent.replace(/\s/g, "").toLowerCase()) {
      this._router.navigate(['agent']);
    }

    else if (this.getRoles().find(currRoles => currRoles.name == userDetails.role)) {
      this._router.navigate(['beckman-billing']);
    }
   
    // else if (userDetails.role)
    else {
      this.clearStorageRedirect();
    }
  }

  clearStorageRedirect() {
    this.deleteToken();
    this._router.navigate(['auth']);
  }
  
  isPrimaryLock() {
    let userDetails = JSON.parse(localStorage.getItem('user'));
    if(userDetails.license_details[0].primaryLock == 1) {
      return true;
    }
    return false;
  }

  isSecondaryLock() {
    let userDetails = JSON.parse(localStorage.getItem('user'));
    if(userDetails.license_details[0].secondaryLock == 1) {
      return true;
    }
    return false;
  }

  isstockTransferLock() {
    let userDetails = JSON.parse(localStorage.getItem('user'));
    if(userDetails.license_details[0].stockTransferLock == 1) {
      return true;
    }
    return false;
  }

  notFoundRedirect() {
    this._router.navigate(['404']);
  }

}
