import { Injectable } from '@angular/core';
import { PromptComponent } from './prompt.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class PromptService {

  constructor(public _dialog: MatDialog) { }

   openDialog(config,closeCallback){
    const dialogRef = this._dialog.open(PromptComponent, {
      width: config.width ? config.width :'400px',
      data: config
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        closeCallback(result);
      }
    });
   }
}
