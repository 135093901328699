export class User { 
    email: string;
    role: Roles;
    access: string;
    refresh: string;
}

export enum Roles {
    Admin = 'Admin',
    Channel_Partner = 'Channel Partner',
    Agent = 'Booking agent',
    OTHERUSERS = 'otherUsers'
} 


