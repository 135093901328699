import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthService } from './../../modules/auth/service/auth.service';
import { StorageService } from '../services/storage/storage.service';
import { Roles } from 'src/app/modules/auth/model/user';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanLoad {
  constructor(private _authService: AuthService, private _storage: StorageService,
    private _router: Router) {

  }
  canLoad(): boolean {
    let resetPasswordWindow = window.location.pathname.slice(0, 21);
    if(resetPasswordWindow === "/auth/reset-password/")
    {
        return true;
    }
    if(this._authService.isLoggedIn()) {
      const currentUser = this._storage.getUserDetails();
      this._storage.redirectBasedonRoles(currentUser);
      return false;
    }
    else {
      return true;
    }
    
  }

}
