import { Component, NgZone } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router } from '@angular/router';

//removed (click)="navigate(params.inRouterLink)" to open in new tab 
@Component({
  template: '<a [routerLink]="[params.inRouterLink,id]" target="_blank">{{params.value}}</a>'
})
export class AgGridRouterComponent implements AgRendererComponent {
  params: any;
  public id;
  constructor(private ngZone: NgZone,
    private router: Router) { }

  refresh(params: any): boolean {
   return false
  }
  agInit(params: import("ag-grid-community").ICellRendererParams): void {
    this.params = params;
    if (params.data)  this.id = this.params.navigateId ? this.params.data[this.params.navigateId] :  this.params.data.id ;
  }
  navigate(link) {
    this.ngZone.run(() => {
        this.router.navigate([link, this.id]);
    });
  }

}
