import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { GuestGuard } from './core/guard/guest.guard';
import { Roles } from './modules/auth/model/user';
import { OtherUserModule } from './modules/otherUsers/other-user.module';



export const routes: Routes = [
  {
    path:'auth',
    canLoad:[GuestGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'beckman',
    canLoad:[AuthGuard],
    loadChildren: () => import('./modules/beckman/beckman.module').then(m => m.BeckmanModule),
    data: { role: Roles.Admin }
  },
  {
    path: 'channel-partner',
    canLoad:[AuthGuard],
    loadChildren: () => import('./modules/cpadmin/cpadmin.module').then(m => m.CpadminModule),
    data: { role: Roles.Channel_Partner }
  },
  {
    path: 'agent',
    canLoad:[AuthGuard],
    loadChildren: () => import('./modules/agent/agent.module').then(m => m.AgentModule),
    data: { role: Roles.Agent }
  },
  {
    path: 'beckman-billing',
    canLoad:[AuthGuard],
    loadChildren: () => import('./modules/otherUsers/other-user.module').then(m => m.OtherUserModule),
    data: { role: Roles.OTHERUSERS }
  },
  {
    path: 'settings',
    canLoad:[AuthGuard],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    data: { role: '' }
  },
  {
    path: '404',
    loadChildren: () => import('./core/modules/404/not-found.module').then(m => m.NotFoundModule),

  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch:'full'
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }



