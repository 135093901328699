import { Component, NgZone } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router } from '@angular/router';

@Component({
  template: `
  <div *ngIf = "data"> 
      <b>{{data[params?.headerName]}} </b> 
      <div *ngFor = "let item of params?.menu" style= 'margin-top:-28px;'>
       <small>  {{ item?.title | titlecase}} :   <b> {{data[item?.name]}} </b> </small>
      </div>
  </div>
  `,
  styles: ['.mat-menu-item { line-height: 30px;height: 30px;}']
})

export class AgGridStockComponent implements AgRendererComponent {
  params: any;
  public data;
  constructor(private ngZone: NgZone,
    private router: Router) { }

  refresh(params: any): boolean {
   return false
  }
  agInit(params: import("ag-grid-community").ICellRendererParams): void {
    this.params = params;
    this.data= params.data
  }
 
}
