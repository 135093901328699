import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PromptComponent implements OnInit {
  public isLargeText:boolean = false;
  public largeContent;
  constructor( 
    public _dialogRef: MatDialogRef<PromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
    
      if(data.content && data.content.replace(/\s/g,'').length > 50) {
        this.isLargeText = true
        this.largeContent = data.content.split(".\n\"");
      }
    }

  ngOnInit() {
  }

  dialogClose(params?:any) {
    this._dialogRef.close(params);
  }

}
