import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';

import { FormGroup, FormBuilder } from '@angular/forms';
import { InvoiceService } from 'src/app/modules/beckman/service/invoice/invoice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BILLING, SHIPPING, invoiceStatus, BECKMAN_GODOWN, ErrorMessage, colorCodes, SuccessMessage } from 'src/app/core/services/constants';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { FormValidatorService } from 'src/app/core/services/formValidator/form-validator.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import {AddBeckManInvoicePartsDialog } from '../../../dialogs/secondary-dialog/dialog.component';
import { element } from 'protractor';


@Component({
  selector: 'app-invoices-detail',
  templateUrl: './invoices-detail.component.html',
  styleUrls: ['./invoices-detail.component.css']
})
export class InvoicesDetailComponent implements OnInit {
 
  public invoiceId;
  public data:any;
  public shippingAddress;
  public billingAddress;
  public totalTax = 0; 
  public invoiceStatus = ''; 
  public iGst:boolean;
  public isAcknowledged = true;
  public moduleName;
  public isChannelPartner = false;
  public isAdmin = false;
  public isUpdate:Boolean= false;
  public isEditShow: boolean = true;
  public filter_array:any;
  public isAdminEdit: boolean = false;
  constructor(public route: ActivatedRoute,private _invoiceService: InvoiceService,
    public _dialog: MatDialog, private _UtilsService : UtilsService,private _StorageService : StorageService,private _snackBar: SnackbarService
  ) { }

  ngOnInit() {
    this.moduleName = this._UtilsService.moduleName();
    this.isChannelPartner = this._UtilsService.isCpRole(this._StorageService.getUserDetails().role);
    this.isAdmin = this._UtilsService.isAdminRole(this._StorageService.getUserDetails().role);
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.invoiceId = parseInt(params.get('id'));
    });

    this._invoiceService.viewInvoice(this.invoiceId,
      (response) => {
        this.data = response;       
        if(response.opf.address){
          this.billingAddress =  response.opf.address.find(address => address.type === BILLING);
          this.shippingAddress = response.opf.address.find(address => address.type === SHIPPING);
           this.checkTax();
        }
        // this.invoiceStatus = response.revenue.status == 0 ? invoiceStatus[1] : invoiceStatus[response.revenue.status]   ;
        this.invoiceStatus =invoiceStatus[response.revenue.status];
        if(response.revenue.status == 0 && this.isChannelPartner){
          this.data.parts.forEach((res, index)=>{
            res.part.forEach((element,partindex)=>{
              element['acknowledgedQuantity']= element['shippedQuantity']
            })
            })
        }
        else{
          if(response.revenue.status == 1 && this.isChannelPartner){
              this.isEditShow = false;
          }
          else if((response.revenue.status == 1 || response.revenue.status == 3) && this.isAdmin){
            this.isAdminEdit = true;
          }
          else{

          }
        }
       response.parts.map((data, index) =>{
         if (this.isAcknowledged) {
           this.isAcknowledged =data.part.find(res => !res.isAcknowledged ) ?   false : true;
         }
       })
        this.getPartsTotalAmount();
      })
  }
  editParts(index, partIndex){
       
        let partsValue = this.data.parts;
        let partsIndex = this.data.parts[index];
        partsIndex.part[partIndex]['acknowledgedQuantity'] =partsIndex.part[partIndex]['acknowledgedQuantity']?
        partsIndex.part[partIndex]['acknowledgedQuantity'] : partsIndex.part[partIndex]['shippedQuantity'];
        let partId = partsIndex.part[partIndex];
        let IndexOfParts = index;
        let dialogRef = this.openPartsDialog(partsValue, partId,IndexOfParts,this.data,partsIndex);
        dialogRef.afterClosed().subscribe(result => {
          if(result){
            const indexvalue= this.data.parts.findIndex(element=>element.partNumber == result['partNumber']);
                this.isUpdate = true;
               
              if(this.isChannelPartner){
                if(this.data.revenue.status == 0){
                  this.data.parts[index].part[partIndex]['acknowledgedQuantity']= result['acknowledgedQuantity'];
                }else{

                  this.data.parts[index].part[partIndex]['acknowledgedQuantity']= result['acknowledgedQuantity'];

                  if(result['acknowledgedQuantity']<this.data.parts[index].part[partIndex]['shippedQuantity']){
                  this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 3 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]]});
                  this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ACKNOWLEDGE, colorCodes.SUCCESS);  
                }else{
                    this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 1 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]]});
                    this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ACKNOWLEDGE, colorCodes.SUCCESS); 
                  }
                }
                
              }
              else{
                if(this.isAdmin){
                  if(this.data.revenue.status == 1 || this.data.revenue.status == 3){
                    this.data.parts[index].part[partIndex]['acknowledgedQuantity']= result['acknowledgedQuantity'];
                    if(this.data.parts[index].part[partIndex]['isAcknowledged']== 0){
                      if(result['acknowledgedQuantity']<this.data.parts[index].part[partIndex]['shippedQuantity']){
                      this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 3 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]] });
                      this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ADMIN_ACKNOWLEDGE, colorCodes.SUCCESS);  
                    }else{
                     this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 1 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]] });   
                     this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ADMIN_ACKNOWLEDGE, colorCodes.SUCCESS);   
                      }
                    }
                    else if(this.data.parts[index].part[partIndex]['isAcknowledged']== 1){
                      if(result['acknowledgedQuantity']<this.data.parts[index].part[partIndex]['shippedQuantity']){
                        this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 3 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]] });
                        this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ADMIN_ACKNOWLEDGE, colorCodes.SUCCESS);  
                      }else{
                       this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 1 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]] });
                       this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ADMIN_ACKNOWLEDGE, colorCodes.SUCCESS);      
                        }

                    }else{
                     if(result['acknowledgedQuantity']<this.data.parts[index].part[partIndex]['shippedQuantity']){
                        this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 3 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]] });
                        this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ADMIN_ACKNOWLEDGE, colorCodes.SUCCESS); 
                      }else{
                       this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 1 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]] });  
                    
                        this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ADMIN_ACKNOWLEDGE, colorCodes.SUCCESS); 
                      
                        }
                    }
                  } 

                }
              }

              // if(this.data.parts[index].part[partIndex]['isAcknowledged']== 3 && this.isChannelPartner){
              //   this.data.parts[index].part[partIndex]['acknowledgedQuantity']= result['acknowledgedQuantity'];
                

                // if(result['acknowledgedQuantity']<this.data.parts[index].part[partIndex]['shippedQuantity']){
                //   //this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 3 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]]});
                // }else{
                //   //this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 1 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]]});    
                // }
                // this.data.parts[index].part[partIndex]['acknowledgedQuantity']= result['acknowledgedQuantity'];
                // this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 3 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]]});
                
             // }
              // else if(this.data.parts[index].part[partIndex]['isAcknowledged']== 0 && this.isChannelPartner){
              //   this.data.parts[index].part[partIndex]['acknowledgedQuantity']= result['acknowledgedQuantity'];
              //   if(result['acknowledgedQuantity']<this.data.parts[index].part[partIndex]['shippedQuantity']){
              //     this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 3 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]]});
              //   }else{
              //     this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 1 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]]});    
              //   }


              // }
              
              // else{
              //   if(this.isAdmin){
                  
              //     if(this.data.parts[index].part[partIndex]['isAcknowledged']== 3){
              //       this.data.parts[index].part[partIndex]['acknowledgedQuantity']= result['acknowledgedQuantity']; 
              //       this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 1 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]] });
              //     }else{
              //       if(this.data.parts[index].part[partIndex]['isAcknowledged']== 1){
              //         this.data.parts[index].part[partIndex]['acknowledgedQuantity']= result['acknowledgedQuantity']; 
              //         this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 3 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]] });
              //       }
              //       else{
              //         if(this.data.parts[index].part[partIndex]['isAcknowledged']== 0){
              //           this.data.parts[index].part[partIndex]['acknowledgedQuantity']= result['acknowledgedQuantity']; 
              //           this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 3 ,"OPFNumber": this.data.opf.OPFNumber, "parts":[this.data.parts[indexvalue]] });   
              //         }
              //       }
              //     }

              //   }
              // }

              }
            else{
              this.isUpdate = false;
            }
        });
     }
      openPartsDialog(partsValue, partId,IndexOfParts,data, parts?: any) {
       const dialogRef = this._dialog.open(AddBeckManInvoicePartsDialog, {
          autoFocus: false,
          width: '750px',
          //data: { "OPFNumber": this.data.opf.OPFNumber, "currentPartsData": partsValue, "editParts": parts ? parts : '', "partIndex":partId, 'IndexOfParts':IndexOfParts }
          data: { "OPFNumber": this.data.opf.OPFNumber, "currentPartsData": partsValue, "editParts": parts ? parts : '', "partIndex":partId, 'IndexOfParts':IndexOfParts, 'totalData': data}
        });
        return dialogRef;
      }

  getUnitPrice(unitPrice,percent){
    let price = unitPrice ? unitPrice : 0;
    let totalPrice =  this.percentage(price,percent);
    return totalPrice;
  }
  percentage(num, per) {
    let number =Number(num.toFixed());
    return number > 0 ? this.RoundOFTwoDigit(Number(((number / 100) * per))) : 0;
  }
  getPartsDiscount(partNo, field){
    let part;
    if(this.data.parts && this.data.opf.parts) {
      part = this.data.opf.parts.find(part =>part.partNumber === partNo);
    }
    return part ? part[field] : 0;
  }
  getPartsTotalAmount(){
    if (this.data.parts) {
      this.data.parts.map(response=>{ 
       response.part.reduce((currentAmount, part)=>{
          part['discount'] = this.getPartsDiscount(part['partNumber'],'discount');
          part['HSSNtax'] = this.getPartsDiscount(part['partNumber'], 'HSSNtax');
          part['tax'] = this.getUnitPrice(response['revenue_net_price'] ,part['HSSNtax']);
          part['total_amount'] =  response['revenue_net_price'] + part['tax'];
        },0)
        response.part.filter((part, index)=>{
          if(index == 0) {
            //calculate for header level only
            this.totalTax =  this.RoundOFTwoDigit(Number(( this.totalTax + part['tax'])))
          }
        })
      })
      
    }
  }
  printInvoice(){
    window.print();
  }
  RoundOFTwoDigit(num: any){
    var number = Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
    return number;
  }
  acknowledgeInvoice(){
    if (this.data.revenue.status == 0) {
    this._invoiceService.invoiceStatus(this.invoiceId,{"status" : 1 ,"OPFNumber": this.data.opf.OPFNumber, "parts":this.data.parts});
    } 
    else{
      // const dialogRef = this._dialog.open(purchaseReturnDialog, {
      //   width: '900px',
      //   data: {parts : this.data.parts , OPFNumber :this.data.opf.OPFNumber , invoiceNumber :this.data.revenue.invoiceNumber}
      // });
      // dialogRef.afterClosed().subscribe(result => {
      //   if (result) {
      //     let data = {};
      //     data['OPFNumber'] = this.data.opf.OPFNumber
      //     data['opfType'] = this.data.opf.type
      //     data['cpNumber'] = this.data.cp.cpnumber
      //     data['invoiceNumber'] =this.data.revenue.invoiceNumber
      //     data['orderNumber'] = this.data.revenue.orderNumber
      //     data['awbNumber'] = ''
      //     data['site_id'] = this.data.opf.site_id
      //     data['invoiceDate'] = this.data.revenue.invoiceDate
      //     data['OTLNumber'] = this.data.opf.OTLNumber
      //     data['return_parts'] =  result.items.filter(value=>{
      //       if (value.returnedQuantity) return value;
      //     });
      //     this._invoiceService.addPurchaseReturn(data);
      //   }
      // });
     }
    
  }

  checkTax() {
  if(this.shippingAddress['state'].toLowerCase().replace(/\s/g,'') == BECKMAN_GODOWN.toLowerCase().replace(/\s/g,'')) {
      this.iGst = false;
    }
    else {
      this.iGst = true;
    }
    
  }
  deleteInvoice(){
    this._invoiceService.deleteInvoice(this.invoiceId)
  }
   
  frozenInvoice(id){  
    this._invoiceService.frozenInvoice(id, (res) =>  {

        this._invoiceService.viewInvoice(this.invoiceId,
      (response) => {
        this.data = response;
        console.log(this.data);
        if(response.opf.address){
          this.billingAddress =  response.opf.address.find(address => address.type === BILLING);
          this.shippingAddress = response.opf.address.find(address => address.type === SHIPPING);
           this.checkTax();
        }
        // this.invoiceStatus = response.revenue.status == 0 ? invoiceStatus[1] : invoiceStatus[response.revenue.status]   ;
        this.invoiceStatus =invoiceStatus[response.revenue.status];
        if(response.revenue.status == 0 && this.isChannelPartner){
          this.data.parts.forEach((res, index)=>{
            res.part.forEach((element,partindex)=>{
              element['acknowledgedQuantity']= element['shippedQuantity']
            })
            })
        }
        else{
          if(response.revenue.status == 1 && this.isChannelPartner){
              this.isEditShow = false;
          }
          else if((response.revenue.status == 1 || response.revenue.status == 3) && this.isAdmin){
            this.isAdminEdit = true;
          }
          else{

          }
        }
       response.parts.map((data, index) =>{
         if (this.isAcknowledged) {
           this.isAcknowledged =data.part.find(res => !res.isAcknowledged ) ?   false : true;
         }
       })
        this.getPartsTotalAmount();
      })
            
    });
  
    }


}



@Component({
  selector: 'Purchase-return',
  templateUrl: 'purchase-return-dialog.html',
  styles: [`.mat-dialog-container{
    padding : 0 !important;
    overflow: hidden;
}
.mat-dialog-content{
  padding: 0px;
  margin:0;
}
`],
  encapsulation: ViewEncapsulation.None
})

export class purchaseReturnDialog implements OnInit {
  public returnForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<purchaseReturnDialog>, private fb: FormBuilder,private _FormValidatorService : FormValidatorService,
    private _snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    if (this.data.parts){
    this.returnForm = this.fb.group({  
        items : this.fb.array( this.getReturnArray())
      });
    }
  }

  getReturnArray(){
    let returnItemsarray = [];
    returnItemsarray = this.data.parts.map( (value) => {
      return this.createReturnItems(value);
    });
      return returnItemsarray;
  }

  createReturnItems(data):FormGroup {
      return  this.fb.group({
        partNumber: [data.partNumber],
        lotNumber: [data.lotNumber],
        invoiceNumber:[this.data.invoiceNumber],
        orderedQuantity: [data.orderedQuantity],
        deliveredQuantity:[data.shippedQuantity],
        description : [data.description],
        awbNumber: [''],
        lotExpirationDate: [data.lotExpirationDate],
        returnedQuantity: ['', [this._FormValidatorService.noDecimalsValidation, this._FormValidatorService.maxLength(data.shippedQuantity)]],
        reason: [''],
      },{validators :this._FormValidatorService.dependencyRequiredValidator('returnedQuantity','reason')});
  }
  checkEmptyQuantity(value){
    let Quantity  = this.returnForm.value.items.find(part =>part.returnedQuantity !== '');
    if (!Quantity){
      this._snackBar.loadSnackBar(ErrorMessage.ENTER_ATLEAST_ONE_QUANTITY, colorCodes.ERROR);
    }else{
      this.dialogRef.close(value);
    }
  }
  dialogClose(value?:any): void {
    if (value){
      this.checkEmptyQuantity(value);
    }else{
      this.dialogRef.close();
    }
  }


 


  }



