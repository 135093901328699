import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { Endpoints, ApiMethod, colorCodes, SuccessMessage } from 'src/app/core/services/constants';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Roles } from 'src/app/modules/auth/model/user';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class OtlpartsService {

  constructor(private _http:HttpService, private _StorageService: StorageService,private _snackBar:SnackbarService,private _router: Router) { }

  importOtlParts(partsFile, successResponseCallback) {
    this._http.requestCall(Endpoints.IMPORT_OTLPARTS, ApiMethod.POST, partsFile).subscribe(
      (res) => {
        this._snackBar.loadSnackBar(SuccessMessage.OTL_PARTS_IMPORT, colorCodes.SUCCESS);
        successResponseCallback();
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  exportOtlParts() {
    this._http.requestCall(Endpoints.EXPORT_OTLPARTS,ApiMethod.DOWNLOAD).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "OTLPartsMaster.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar(SuccessMessage.OTL_PARTS_EXPORT, colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  listOtlParts(successResponseCallback, errorResponseCallback) {
    this._http.requestCall(Endpoints.LIST_OTLPARTS, ApiMethod.GET).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        errorResponseCallback(error);
      });
  }

  viewOtlParts(otlPartId, successCallBack,errorResponseCallback) {
    Endpoints['VIEW_OTL_PARTS'] = `/otl_parts/${otlPartId.toString()}`;
    this._http.requestCall(Endpoints['VIEW_OTL_PARTS'], ApiMethod.GET).subscribe(
      (res) => {
        successCallBack(res);
      },
      (error) => {
        errorResponseCallback(error);
      });
  }

  searchOtlParts (otlPayload, successResponseCallback) {
    this._http.requestCall(Endpoints.LIST_OTLPARTS, ApiMethod.GETPARAMS, otlPayload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }
  editOtlParts(otlPartId, otlPayload) {
    Endpoints['EDIT_OTL_PARTS'] = `/otl_parts/${otlPartId.toString()}`;
    this._http.requestCall(Endpoints['EDIT_OTL_PARTS'], ApiMethod.PUT, otlPayload).subscribe(
      (res) => {
        this._snackBar.loadSnackBar(SuccessMessage.OTL_PARTS_EDIT, colorCodes.SUCCESS);
        this.navigateOtlParts()
      },
      (error) => {
        console.log(error);
      });
  }

  deleteOtlParts(otlPartId,successResponseCallback) {
    Endpoints['DELETE_OTL_PARTS'] = `/otl_parts/${otlPartId.toString()}`;
    this._http.requestCall(Endpoints['DELETE_OTL_PARTS'], ApiMethod.DELETE).subscribe(
      (res) => {
        successResponseCallback();
        this._snackBar.loadSnackBar(SuccessMessage.OTL_PARTS_DELETE, colorCodes.SUCCESS);
      },
      (error) => {
        console.log(error);
      });
  }


  addOtlParts(payload){
    this._http.requestCall(Endpoints.LIST_OTLPARTS, ApiMethod.POST,payload).subscribe(
      (res) => {
       this.navigateOtlParts()
       this._snackBar.loadSnackBar(SuccessMessage.OTL_PARTS_ADD, colorCodes.SUCCESS);
      },
      (error) => {
      
      });
  }
  getPartsAndOtlParts(successCallBack){
    let otlParts = this._http.requestCall(Endpoints.GET_OTL_LIST, ApiMethod.GET);
    let parts =  this._http.requestCall(Endpoints.LIST_PARTS, ApiMethod.GET);
    forkJoin([otlParts, parts]).subscribe(results => {
      successCallBack(results);
     });
  }

  navigateOtlParts(){
    let roles = this._StorageService.getUserDetails().role;
    if (roles == Roles.Admin)   this._router.navigate(['beckman/otlparts']);
    else if (roles == Roles.Channel_Partner)   this._router.navigate(['channel-partner/otlparts']);
    else if (roles == Roles.Agent)   this._router.navigate(['agent/otlparts']);
    else this._router.navigate(['beckman-billing/otlparts']);
  }

  navigateEditOtlParts(id){
    
    let roles = this._StorageService.getUserDetails().role;
    if (roles == Roles.Admin)  this._router.navigate(['/beckman/otlparts/edit', id]);
    else if (roles == Roles.Channel_Partner)   this._router.navigate(['/channel-partner/otlparts/edit', id]);
    else if (roles == Roles.Agent)this._router.navigate(['/agent/otlparts/edit', id]);
    else this._router.navigate(['/beckman-billing/otlparts/edit', id]);
  }

  exportOTLPartsData(payload){
    this._http.requestCall(Endpoints.DOWNLOAD_OTL_PARTS_DATA,ApiMethod.DOWNLOAD_PARAMS,payload).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "OTLParts-Master.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar(SuccessMessage.DOWNLOAD_OTL_PARTS_DATA, colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
