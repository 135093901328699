import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MomentService {

  constructor() { }

  getIsoFormat(date){
    return moment(date).format("YYYY-MM-DD");
  }
  getDateTimeFormat (date){
    return moment(date).format("DD-MMM-YYYY hh:mm A");
  }
  getDate1(date){
    return moment(date).format("DD-MM-YYYY");
  }
  getDate (date){
    return moment(date).format("DD-MMM-YYYY");
  }
  // 24 hour to 12 hour format in time 
  convertHours(time){
    return  moment(time, "hh").format('LT') 
  }
  exceedYear(currentDate, number){
    return moment(currentDate).add(number, 'Y').format("YYYY-MM-DD");
  }

  deceedYear (currentDate, number){
    return moment(currentDate).subtract(number, 'Y').format("YYYY-MM-DD");
  }
  deceedDate (currentDate, number){
    return moment(currentDate).subtract(number, 'd').format("YYYY-MM-DD");
  }

  getDateDiff(expiryDate){
    var startDate = moment(expiryDate, 'YYYY-MM-DD');
    var today = moment().format("YYYY-MM-DD");
    return startDate.diff(today, 'days') > 0 ?startDate.diff(today, 'days') : startDate.diff(today, 'days');

  }
  getFilterFormat(date, toDate?:any) {
    if(toDate) {
      return moment(date).format("YYYY-MM-DD 17:29:00.00000")  
    } 
    return moment(date).subtract(1, 'd').format("YYYY-MM-DD 18:30:00.00000")
  }
  getFiltertimeFormat(date, toDate?:any) {
    if(toDate) {
      return moment(date).format("YYYY-MM-DD 00:00:00.00000")  
    } 
    return moment(date).subtract(1, 'd').format("YYYY-MM-DD 00:00:00.00000")
  }
  timeComparator(a ,b){
     a = moment(a, 'HH:mm: A').diff(moment().startOf('day'), 'seconds');
     b = moment(b, 'HH:mm: A').diff(moment().startOf('day'), 'seconds');
     if (a === null && b === null) {
      return 0;
      }
    if (a === null) {
      return -1;
      }
      if (b === null) {
      return 1;
      }
      return a - b;
  }

 

}
