import { Injectable } from '@angular/core';
import { Endpoints, ApiMethod, SuccessMessage, colorCodes } from '../constants';
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';
import { SnackbarService } from '../snackBar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionMenuListService {
  constructor(private _http: HttpService,private _snackBar: SnackbarService, private _storageService :StorageService) {}

  getPermissionMenu(successCallback) {
      this._http.requestCall(Endpoints.MENU_PERMISSION_LIST, ApiMethod.GET).subscribe((menuList) => {
          successCallback(menuList);
          this._storageService.setMenuList(JSON.stringify(menuList));
        },
        (error) => { console.log(error); });
  }


  getUserRoles(successCallback) {
    this._http.requestCall(Endpoints.ROLES, ApiMethod.GET).subscribe((roles) => {
        successCallback(roles);
    },
      (error) => { console.log(error); });
  }



  getActionPermission(payload, successCallback) {
    this._http.requestCall(Endpoints.PERMISSION_LIST, ApiMethod.GETPARAMS,payload).subscribe((actionList) => {
        successCallback(actionList);
      },
      (error) => { console.log(error); });
  }


  getAllPermission(successCallback){
    this._http.requestCall(Endpoints.ALL_PERMISSION_LIST, ApiMethod.GET).subscribe((response) => {
      successCallback(response);
  },
    (error) => { console.log(error); });
  }
  uploadPermission(payload,successCallback){
    this._http.requestCall(Endpoints.POST_PERMISSION_LIST, ApiMethod.POST,payload).subscribe((response) => {
      this._snackBar.loadSnackBar(SuccessMessage.PERMISSION_MATRIX, colorCodes.SUCCESS);
      successCallback(response)
  },
    (error) => { console.log(error); });
  }
}
