import { Injectable } from '@angular/core';
import { Endpoints, ApiMethod,colorCodes, ErrorMessage, SuccessMessage } from 'src/app/core/services/constants';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http/http.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { Roles } from '../../auth/model/user';

@Injectable({
  providedIn: 'root'
})
export class StockService { 

  constructor(private _http:HttpService,private _StorageService: StorageService, private _snackBar: SnackbarService,private _router: Router) { }

  getStockList(successCallback){
    this._http.requestCall(Endpoints.STOCK_LIST, ApiMethod.GET).subscribe(
      (res) => { successCallback(res) },
      (error) => {console.log(error) }
    )
  }

  getOTLParts(payload, successCallback) {
    this._http.requestCall(Endpoints.GET_STOCK_OTL_PARTS, ApiMethod.POST, payload).subscribe(
      (res) => { successCallback(res) },
      (error) => { console.log(error); }
    )
  }
  getRelatedHospitalParts(payload, successCallback) {
    this._http.requestCall(Endpoints.GET_RELEATED_STOCK_PARTS, ApiMethod.POST, payload).subscribe(
      (res) => { 
        if(res.length==0) { 
          this._snackBar.loadSnackBar(ErrorMessage.NO_RELATED_PARTS, colorCodes.ERROR); 
        }
        successCallback(res) },
      (error) => { console.log(error); }
    )
  }

  createStockSwap(payload) {
    this._http.requestCall(Endpoints.STOCK_SWAP, ApiMethod.POST, payload).subscribe(
      (res) => { 
        this.navigateTo()
       },
      (error) => { console.log(error); }
    )
  }


  searchStock(stockPayload, successResponseCallback) {
    this._http.requestCall(Endpoints.STOCK_LIST, ApiMethod.GETPARAMS, stockPayload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }


 getOtl(stockPayload,successCallback){
  this._http.requestCall(Endpoints.ADD_OTL, ApiMethod.GETPARAMS, stockPayload).subscribe(
    (res) => {
      successCallback(res);
    },
    (error) => {
      console.log(error)
    });
 }

getAvailableQty(stockPayload,successCallback){
  this._http.requestCall(Endpoints.STOCK_TRANSFER_AVAILABLE_QTY, ApiMethod.GETPARAMS, stockPayload).subscribe(
    (res) => {
      successCallback(res);
    },
    (error) => {
      console.log(error)
    });
}

 createStockTransfer(payload) {
  this._http.requestCall(Endpoints.STOCK_TRANSFER, ApiMethod.POST, payload).subscribe(
    (res) => { 
      this.navigateTo()
     },
    (error) => { console.log(error); }
  )
}
createStockTransferonSameScreen(payload, successCallback){
  this._http.requestCall(Endpoints.STOCK_TRANSFER, ApiMethod.POST, payload).subscribe(
    (res) => { 
      if(res){

        this._snackBar.loadSnackBar(SuccessMessage.STOCK_TRANSFER, colorCodes.SUCCESS); 
     // this.navigateTo()
   //  return res;
   successCallback(res);
      }
     },
    (error) => { console.log(error); }
  )
}

reduceStockQty(payload, successCallback) {
  this._http.requestCall(Endpoints.STOCK_REDUCE_QTY, ApiMethod.POST, payload).subscribe(
    (res) => { 
      this._snackBar.loadSnackBar(SuccessMessage.STOCK_REDUCE, colorCodes.SUCCESS); 
      successCallback(res);
     },
    (error) => { console.log(error); }
  )
}

getFromCustomerStockdetails(payload,successCallback){
  this._http.requestCall(Endpoints.CP_REVERSAL_STOCK_LIST, ApiMethod.POST, payload).subscribe(
    (res) => {
      successCallback(res);
    },
    (error) => {
      console.log(error)
    });
}
getToCustomerStockdetails(payload,successCallback){
  this._http.requestCall(Endpoints.CP_REVERSAL_STOCK_OPF, ApiMethod.POST, payload).subscribe(
    (res) => {
      successCallback(res);
    },
    (error) => {
      console.log(error)
    });
}


reverseStock(payload) {
  this._http.requestCall(Endpoints.CP_REVERSAL_STOCK, ApiMethod.POST, payload).subscribe(
    (res) => { 
      this._snackBar.loadSnackBar(SuccessMessage.STOCK_REVERSAL, colorCodes.SUCCESS); 
      this.navigateTo()
     },
    (error) => { console.log(error); }
  )
}

  navigateTo(){
    let roles = this._StorageService.getUserDetails().role;

    if (roles == Roles.Admin)   this._router.navigate(['beckman/secondary-sales/stock-transfer']);
    else if (roles == Roles.Channel_Partner) this._router.navigate(['/channel-partner/secondary-sales/stock-transfer'])
    //  else if (roles == Roles.Agent)   this._router.navigate(['agent/secondary-sales/stock-transfer']);
    else this._router.navigate(['beckman-billing/secondary-sales/stock-transfer']);

  }


  navigateToStock(id){
    let roles = this._StorageService.getUserDetails().role;
    if (roles == Roles.Admin)  this._router.navigate(['/beckman/secondary-sales/stock-transfer/reversal/view', id]); 
    else if (roles == Roles.Channel_Partner) {
      this._router.navigate(['/channel-partner/secondary-sales/stock-transfer']);   
    }
    //  else if (roles == Roles.Agent)  this._router.navigate(['/channel-partner/secondary-sales/stock-transfer/reversal/view', id]); 
    else  this._router.navigate(['/beckman-billing/secondary-sales/stock-transfer/reversal/view', id]); 


  }
  navigateToReversal(id){
    let roles = this._StorageService.getUserDetails().role;
    if (roles == Roles.Admin)  this._router.navigate(['/beckman/secondary-sales/stock-transfer/reversal/view', id]); 
    else if (roles == Roles.Channel_Partner) 
      this._router.navigate(['/channel-partner/secondary-sales/stock-transfer/reversal/view', id]);   
    //  else if (roles == Roles.Agent)  this._router.navigate(['/channel-partner/secondary-sales/stock-transfer/reversal/view', id]); 
    else  this._router.navigate(['/beckman-billing/secondary-sales/stock-transfer/reversal/view', id]); 
  }

  exportStockInvoiceFilter(stockPayload){
    this._http.requestCall(Endpoints.STOCK_EXPORT,ApiMethod.DOWNLOAD_PARAMS,stockPayload).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "Stock_Swap_Transfer.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar(SuccessMessage.STOCK_TRANSFER_EXPORT, colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
