import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FilterService {
        public openfilters = new BehaviorSubject<any>(null);
        public bookedfilter = new BehaviorSubject<any>(null);
        public rejectedfilter = new BehaviorSubject<any>(null);
    setFilterData(message, screen) {

        if(screen === 'open')
        {
            this.openfilters.next(message);
        }
        else if(screen === 'booked')
        {
            this.bookedfilter.next(message);
        }
        else if (screen === 'rejected')
        {
            this.rejectedfilter.next(message);
        }
   
     }

     getFilterData(screen): Observable<any> {

         let Observable = null;

        if(screen === 'open')
        {
            Observable = this.openfilters.asObservable();
        }
        else if(screen === 'booked')
        {
            Observable = this.bookedfilter.asObservable();
        }
        else if (screen === 'rejected')
        {
            Observable = this.rejectedfilter.asObservable();
        }
   

        return Observable;
    }
}