import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { ApiMethod, Endpoints, ErrorCodes, colorCodes, SuccessCodes, ErrorMessage, ErrorKeys } from './../constants';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StorageService } from './../storage/storage.service';
import { SnackbarService } from '../snackBar/snackbar.service';
import { QueryParamsService } from '../queryParams/query-params.service';
import { PromptService } from '../../modules/shared/components/prompt/prompt.service';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    private _storageService: StorageService,
    private _snackBar: SnackbarService,
    private _queryParams: QueryParamsService,
    private _PromptService: PromptService
  ) { }

  requestCall(api: Endpoints, method: ApiMethod, data?: any) {
    let response;
    switch (method) {

      case ApiMethod.GET:
        response = this.http.get(`${environment.apiUrl}${api}`)
          .pipe(catchError((err) => this.handleError(err, this)));
        break;

      case ApiMethod.GETPARAMS:
        let reqParams = this._queryParams.getParams(data);
        response = this.http.get(`${environment.apiUrl}${api}`, { params: reqParams })
          .pipe(catchError((err) => this.handleError(err, this)));
        break;
      case ApiMethod.SEARCHDOWNLOADPARAMS:
        response = this.http.get(`${environment.apiUrl}${api}`, { 
          headers: new HttpHeaders({}),
          responseType: 'text',
          params: data
        }).pipe(catchError((err) => this.handleError(err, this)));
        break;

      case ApiMethod.SEARCHPARAMS:
        response = this.http.get<any>(`${environment.apiUrl}${api}`, { params: data })
          .pipe(catchError((err) => this.handleError(err, this)));
        break;

      case ApiMethod.POST:
        response = this.http.post(`${environment.apiUrl}${api}`, data)
          .pipe(catchError((err) => this.handleError(err, this)));
        break;

      case ApiMethod.PUT:
        response = this.http.put(`${environment.apiUrl}${api}`, data)
          .pipe(catchError((err) => this.handleError(err, this)));
        break;

      case ApiMethod.DELETE:
        response = this.http.delete(`${environment.apiUrl}${api}`)
          .pipe(catchError((err) => this.handleError(err, this)));
        break;

      case ApiMethod.DOWNLOAD:
        response = this.http.get(`${environment.apiUrl}${api}`, {
          headers: new HttpHeaders({}),
          responseType: 'text'
        })
          .pipe(catchError((err) => this.handleError(err, this)));
        break;

      case ApiMethod.DOWNLOAD_PARAMS:
        let downloadParams = this._queryParams.getParams(data);
        response = this.http.get(`${environment.apiUrl}${api}`, { 
          headers: new HttpHeaders({}),
          responseType: 'text',
          params: downloadParams
        }).pipe(catchError((err) => this.handleError(err, this)));
        break;

        case ApiMethod.DOWNLOAD_BLOB:
          response = this.http.get(`${environment.apiUrl}${api}`, {
            headers: new HttpHeaders({}),
            responseType: 'blob'
          })
            .pipe(catchError((err) => this.handleError(err, this)));
          break;
      default:
        break;

    }
    return response;

  }

  handleError(error: HttpErrorResponse, self) {
    if (error.error instanceof ErrorEvent) {
      console.error('Error occured', error.error.message);
    }
    else {
      console.log(error);
      if(error.url.includes("app_dashboard/inventory_days_outstanding")){
        let errmsg:any= JSON.parse(error.error)
        this._snackBar.loadSnackBar(errmsg.message, colorCodes.ERROR);
      }else{
        this.whichError(error.status, (error.error.message || error.error.detail) ? (error.error.message ? error.error.message : error.error.detail) : this.formatError(error.status,error.error) );
        return throwError({ error: error.message, status: error.status });
      }
    }
  }

  handleRefreshTokenExpiry(error: HttpErrorResponse, self) {
    this._snackBar.loadSnackBar(ErrorMessage.SESSION_EXPIRED, colorCodes.ERROR);
    this._storageService.clearStorageRedirect();
    return throwError({ error: error.message, status: error.status });
  }

  formatError(errorStatus, error) {  
    let name = Object.keys(error)[0];
    if (errorStatus == ErrorCodes.HTTP_400_BAD_REQUEST && name == ErrorKeys.NON_FIELD_ERRORS) {
      return error[name][0]
    }
    return name + " : " + error[name][0];
  }

  whichError(errorCode: number, errorMessage: string) {
    switch (errorCode) {
      case ErrorCodes.HTTP_400_BAD_REQUEST:
        
        if(errorMessage.length > 150) {
          this._PromptService.openDialog({title : 'Error!',btnLabel : 'OK', content: errorMessage, width:550 }, response =>{
            return false;
          })
        }
        else {
          this._snackBar.loadSnackBar(errorMessage, colorCodes.ERROR);
        }
        break;
      case ErrorCodes.HTTP_403_FORBIDDEN:
        this._snackBar.loadSnackBar(errorMessage, colorCodes.ERROR);
        break;

      case ErrorCodes.HTTP_401_UNAUTHORIZED:
        this._snackBar.loadSnackBar(errorMessage, colorCodes.ERROR);
        break;

      case ErrorCodes.HTTP_404_NOT_FOUND:
        this._snackBar.loadSnackBar(errorMessage, colorCodes.ERROR);
        break;

      case ErrorCodes.HTTP_500_ERROR:
        this._snackBar.loadSnackBar(ErrorMessage.INTERNAL_SERVER_ERROR, colorCodes.ERROR);
        break;

      case ErrorCodes.HTTP_CONN_REFUSED:
        this._snackBar.loadSnackBar(ErrorMessage.SERVER_UNAVAILABLE, colorCodes.ERROR);
        break;

      default:
        this._snackBar.loadSnackBar(ErrorMessage.UNKNOWN_ERROR_CODE, colorCodes.ERROR);
    }
  }

  whichSuccess(successCode: number, successMessage: string) { 
    switch(successCode) {
      case SuccessCodes.HTTP_200_OK:
        this._snackBar.loadSnackBar('Success! Request completed successfully', colorCodes.SUCCESS);
        break;

      case SuccessCodes.HTTP_201_CREATED:
        this._snackBar.loadSnackBar('Success! Created successfully', colorCodes.SUCCESS);
        break;

      default:
        this._snackBar.loadSnackBar('Success! Request completed successfully', colorCodes.SUCCESS);
    }
  }

  refreshToken() {
    const refreshToken = this._storageService.getRefreshToken();
    return this.http.post(`${environment.apiUrl}${Endpoints.REFRESH_TOKEN}`,{refresh: refreshToken}).pipe(
      tap(response => {
        this._storageService.setToken(response['access']);
      }),
      catchError((err) => this.handleRefreshTokenExpiry(err, this))
    )
  }
  

}
