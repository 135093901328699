import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EMAIL_ID_REGEX, MOBILE_REGEX, PINCODE_REGEX, PANCARD_REGEX, GST_REGEX, ALPHANUMERIC, ONLY_NUMBERS_REGEX } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  constructor() { }
  
  emailValidation (control: AbstractControl): { [key: string]: any } | null {
    if(control.value){
      const value = !EMAIL_ID_REGEX.test(control.value);
      return value ? { emailValidate:'Enter a valid email address' } : null;
    }
  }


  dateValidation(startDate:string, endDate:string):  ValidatorFn{
    return (group: FormGroup): ValidationErrors | null => {
      let endDateValue = group.controls[endDate].value;
      let startDateValue = group.controls[startDate].value
      if(endDateValue && startDateValue) {
        if( new Date(endDateValue) < new Date(startDateValue)) {
          return { invalidEndDate: group.controls[endDate].errors ? (group.controls[endDate].errors.matDatepickerMin ?  false : true) :  true }
        }
        if( new Date(endDateValue) == new Date(startDateValue)) {
          return { equalDateValidate: true }
        }
      }
      return null;
     }
  }

 
  maxLength(val: number,params?:any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === 0 && !params){
      return { 'maxLength': 'Zero not allowed' }
    } 
    if (control.value  && val > 0 && (control.value > val || control.value <= 0)) {
      return { 'maxLength': control.value > val ?  `Enter a value below ${val}` : 'Negative value are not allowed' }
    } 
    return null;
  }
}

maxpartsinvoiceLength(val: number,params?:any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === 0 && !params){
      return { 'maxLength': 'Zero not allowed' }
    } 
    if (control.value  && (val > 0 || val == 0)&& (control.value > val || control.value <= 0)) {
      return { 'maxLength': control.value > val ?  `Enter a value less than or equal to ${val}` : 'Negative value are not allowed' }
    } 
    return null;
  }
}

maxTaxLength(val: number,params?:any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value < 0) {
      return { 'negativeValue': 'Negative values are not allowed' }
    } 
  
    if (control.value  && val > 0 && (control.value > val || control.value <= 0)) {
      return { 'maxLength': control.value > val ?  `Enter a value upto ${val}` : 'Negative value are not allowed' }
    } 
    return null;
  }
}
zerolength(val: number,params?:any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === 0 && !params){
      return { 'maxLength': 'Zero not allowed' }
    } 
    if ( (control.value <= 0)) {
      return { 'maxLength':  'Negative value are not allowed' }
    } 
    return null;
  }
}

maxEqualLength(val: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value < 0) {
      return { 'negativeValue': 'Negative values are not allowed' }
    } 
    if ((control.value > 0  && val > 0) && (control.value > val) ) {
      return { 'maxLength': `Enter a value less than or equal to  ${val}` }
    }
    return null;
  }
}

minNumberValidation(maxNumber, params?:any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!params && control.value == 0 && maxNumber > 0 ){
      return { 'maxLength': 'Zero not allowed' }
    } 
    if (control.value  && maxNumber > 0 && (control.value < maxNumber)) {
      return { 'maxLength':  control.value < 0 ? 'Negative value are not allowed' :   `Enter a value above or equal to ${maxNumber}`   }
    } 
    return null;
  }
}


dependencyRequiredValidator(dependentField:string, currentField:string):  ValidatorFn{
  return (group: FormGroup): ValidationErrors | null => {
    let dependentFieldValue = group.controls[dependentField].value;
    let currentFieldValue = group.controls[currentField].value;
    if(dependentFieldValue && !currentFieldValue) {
      const isWhitespace = (currentFieldValue || '').trim().length === 0;
      const isValid = !isWhitespace;
      if(isValid) { currentFieldValue.trim() }
      return isValid ? null : { 'currentFieldValue': true }
    }
    if(!dependentFieldValue && currentFieldValue){
      return { 'dependentFieldValue': true }
    }
    return null;
   }
}



dependencyNumberValidator(val?:any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (val == 0 && control.value != 100){
      return  { 'gstPattern': 'If Price is Zero , the discount will be 100%' } ;
    }else return null; 
   return null;
  }
}

numberValidationWithZero(control: AbstractControl): ValidationErrors | null  {
  if (control.value != 0) {
    const value = Number(control.value) ? false : true ;
    return value ? { 'numberPattern': 'Enter numbers only' } : null;
  } 
}


ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}


  mobilePatternValidation (control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      const value = !MOBILE_REGEX.test(control.value.trim());
      return value ? { 'mobilePattern': 'Invalid Mobile Number, Must be exactly 10 digits' } : null;
    } 
    return null;
  }

  pincodePatternValidation (control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      if (control.value.length != 6){
        return { 'pincodePattern': 'Invalid Pincode, Must be exactly 6 digits' };
      }
      const value = !PINCODE_REGEX.test(control.value.trim());
      return value  ? { 'pincodePattern' :  'Invalid Pincode, Must be exactly 6 digits' } : null;
    } 
    return null;
  }

  negativeValidation(control: AbstractControl): ValidationErrors | null  {
    if (control.value === 0){
      return { 'maxLength': 'Zero not allowed' }
    } 
      if (control.value < 0) {
        return { 'negativeValue': 'Negative values are not allowed' }
      } 
      return null;
  }
  
  pancardValidation (control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      if (control.value.length != 10) return { 'pancardPattern': ' Enter valid Pancard Number' }
      const value = !PANCARD_REGEX.test(control.value.trim());
      return value  ? { 'pancardPattern': ' Enter valid Pancard Number' } : null;
    } 
    return null;
  }

  requireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if(selection) {
      if (typeof selection === 'string' && (selection.trim())) {
        return { incorrect: true };
      }
    }
    return null;
  }

  outOfStock(control: AbstractControl) {
    if (control.value && control.value == 0){
      return {'outOfStock' : true}
    }
    return null;
  }


  numberValidation(control: AbstractControl): ValidationErrors | null  {
    if (control.value) {
      const value = Number(control.value) ? false : true ;
      return value ? { 'numberPattern': 'Enter numbers only' } : null;
    } 
    return null;
  }
  
  gstValidation (control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      if (control.value.length != 15) return { 'gstPattern': ' Enter valid GST Number' }
      const value = !GST_REGEX.test(control.value.trim());
      return value  ? { 'gstPattern': ' Enter valid GST Number' } : null;
    } 
    return null;
  }

  alphaNumericValidation (control: AbstractControl): { [key: string]: any } | null {
    if(control.value){
      const value = !ALPHANUMERIC.test(control.value);
      return value ? { 'gstPattern':'OTL Number should be Alphanumeric' } : null;
    }
  }

  noDecimalsValidation (control: AbstractControl) : { [key: string]:any } | null {
    if(control.value > 0) {
      const value = !ONLY_NUMBERS_REGEX.test(control.value);
      return value ? { 'gstPattern':'Input should not be Decimals' } : null;
    }
  }

  noWhitespaceValidation (control: AbstractControl): { [key: string]:any } | null { 
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    if(isValid) { control.value.trim() }
    // if (control && control.value && !control.value.replace(/\s/g, '').length) {
    //   control.setValue('');
    // }
    return isValid ? null : { 'whitespace': true };
  }
  withZeroAndNegativeValidation(control: AbstractControl): ValidationErrors | null  {
    
      if (control.value < 0) {
        return { 'negativeValue': 'Negative values are not allowed' }
      }
      if (control.value && control.value != 0) {
        const value = Number(control.value) ? false : true ;
        return value ? { 'numberPattern': 'Enter numbers only' } : null;
      } 
     
      return null;
  }

  hsnmaxlengthValidation (control: AbstractControl): { [key: string]: any } | null {
    if(control.value.length !=0){
      let value = (control.value as string).trim();
    if (value.length!=0) {
      if (control.value.length > 12) return { 'hsnlength':' value length maximum  12 or less than 12 ' }
      
    } 
  }

  }
  cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
    if(control.value.length !=0){
    let value = (control.value as string).trim();
    if(value.length == 0){
        return {cannotContainSpace: true}
    }

    return null;
}
  }
  hsncannotContainSpace(control: AbstractControl) : ValidationErrors | null {
    if(control.value.length !=0){
    let value = (control.value as string).trim();
    if(value.length == 0){
        return {hsncannotContainSpace: true}
    }

    return null;
}
  }

  twodecimalallow(params?: any) : ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null =>{
      if(control.value){
        let value = JSON.stringify(control.value);
        let splitvalue = value.split('.');
        if(splitvalue.length == 2){
          let  split = splitvalue[1];
           if(split.length>2){
            console.log('more than')
          return {'decimal': 'Not allowed more than two decimal'}
          }
        }
      }
      return null;
    }
    }
}
