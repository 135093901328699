import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Endpoints, ApiMethod } from 'src/app/core/services/constants';


@Injectable({
  providedIn: 'root'
})

export class LocationService {

    constructor(private _http: HttpService) {}

    getLocationData(successCallback) {
        this._http.requestCall(Endpoints.GET_REGION, ApiMethod.GET).subscribe((res) => {
            successCallback(res);
          },
          (error) => { console.log(error); });
        }
}
