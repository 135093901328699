import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { Router } from '@angular/router';
import { Endpoints, ApiMethod, colorCodes, SuccessMessage } from 'src/app/core/services/constants';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { Roles } from 'src/app/modules/auth/model/user';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private _http: HttpService,private _StorageService: StorageService, private _snackBar: SnackbarService,private _router: Router) { }

  importCSV(endpoints,fileName, revenueFile) {
    this._http.requestCall(endpoints, ApiMethod.POST, revenueFile).subscribe(
      (res) => {
        
        this._snackBar.loadSnackBar(fileName + ' - ' + SuccessMessage.INVOICE_UPLOAD , colorCodes.SUCCESS);
      },
      (error) => {
        
        console.log(error);
      }
    );
  }

  listInvoices(successResponseCallback, errorResponseCallback) {
    this._http.requestCall(Endpoints.LIST_INVOICES, ApiMethod.GET).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        errorResponseCallback(error);
      });
  }

  viewInvoice(invoiceId,successCallBack){
    Endpoints['VIEW_INVOICE'] = `/invoice/${invoiceId.toString()}`;
    this._http.requestCall(Endpoints['VIEW_INVOICE'], ApiMethod.GET).subscribe(
      (res) => {
        successCallBack(res);
      },
      (error) => {
        console.log(error);
      });
  }

  invoiceStatus (invoiceId,invoicePayload){
    let roles = this._StorageService.getUserDetails().role;
    Endpoints['INVOICE_STATUS'] = `/invoice/${invoiceId.toString()}/`;
    this._http.requestCall(Endpoints['INVOICE_STATUS'], ApiMethod.PUT,invoicePayload).subscribe(
      (res) => {
        if(roles == Roles.Admin){
          if(res.revenue_status == 0){
            this._router.navigate(['/beckman/invoices']);
            
            }
            else{
              if(res.revenue_status == 1){
                this._router.navigate(['/beckman/invoices/view/'+`${invoiceId}`]); 
                window.location.reload(); 
                this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ADMIN_ACKNOWLEDGE, colorCodes.SUCCESS); 
              }
              else{
                if(res.revenue_status == 3){
                  this._router.navigate(['/beckman/invoices/view/'+`${invoiceId}`]);  
                  window.location.reload(); 
                }
              }
            
            }

        }
        else{
          if(roles == Roles.Channel_Partner){
            if(res.revenue_status == 1){
              this._router.navigate(['/channel-partner/invoices']);
              this._snackBar.loadSnackBar(SuccessMessage.BECKMAN_ACKNOWLEDGE, colorCodes.SUCCESS);

              }
              else{
      
                this._router.navigate(['/channel-partner/invoices/view/'+`${invoiceId}`]); 
                window.location.reload(); 
              }
          }
        }
       
      },
      (error) => {
        console.log(error);
      });
  }
  addPurchaseReturn(returnPayload){
    this._http.requestCall(Endpoints['ADD_PURCHASE_RETURN'], ApiMethod.POST, returnPayload).subscribe(
      (res) => {
        this._router.navigate(['/channel-partner/invoices']);
      },
      (error) => {
        console.log(error);
      });
  }
  navigateTo(){
    let roles = this._StorageService.getUserDetails().role;
    if (roles == Roles.Admin)  this._router.navigate(['/beckman/invoices']);  
    else if (roles == Roles.Channel_Partner)   this._router.navigate(['/channel-partner/invoices']);
    else if (roles == Roles.Agent) this._router.navigate(['/agent/invoices']); 
    else  this._router.navigate(['/beckman-billing/invoices']);
    
  }
  listReturns(successResponseCallback, errorResponseCallback) {
    this._http.requestCall(Endpoints.ADD_PURCHASE_RETURN, ApiMethod.GET).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        errorResponseCallback(error);
      });
  }

  viewReturns(returnsId,successCallBack) {

    Endpoints['VIEW_RETURN'] = `/returns/${returnsId.toString()}`;
    this._http.requestCall(Endpoints['VIEW_RETURN'], ApiMethod.GET).subscribe(
      (res) => {
        successCallBack(res);
      },
      (error) => {
        console.log(error);
      });

  }
  searchInvoice(invoicePayload, successResponseCallback) {
    this._http.requestCall(Endpoints['LIST_INVOICES'], ApiMethod.GETPARAMS, invoicePayload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }

   importStockCsv(file) {
    this._http.requestCall(Endpoints.IMPORT_STOCK, ApiMethod.POST, file).subscribe(
      (res) => {
        this._snackBar.loadSnackBar("Success! Stock Imported", colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }


  exportInvoiceFilter(payload){
    this._http.requestCall(Endpoints.EXPORT_Invoice,ApiMethod.DOWNLOAD_PARAMS,payload).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "Beckman Invoice.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  deleteInvoice(invoiceNo,successCallBack?:any){
    Endpoints['DELETE_INVOICE'] = `/invoice/${invoiceNo.toString()}`;
    this._http.requestCall(Endpoints['DELETE_INVOICE'], ApiMethod.DELETE).subscribe(
      (res) => {
        this._snackBar.loadSnackBar(SuccessMessage.INVOICE_DELETED, colorCodes.SUCCESS)
        successCallBack ? successCallBack(res) :  this._router.navigate(['/beckman/invoices']);
      });
  }


  frozenInvoice(freezeId,successCallBack) {

    Endpoints['FREEZE_INVOICES'] = `/freeze?invoiceNumber=${freezeId.toString()}`;

    this._http.requestCall(Endpoints['FREEZE_INVOICES'], ApiMethod.GET).subscribe(
      (res) => {
               
        this._snackBar.loadSnackBar(SuccessMessage.INVOICE_FROZEN, colorCodes.SUCCESS);
        successCallBack ? successCallBack(res) :  this._router.navigate(['/channel-partner/invoices']);
   
      },
      (error) => {
        console.log(error);
      });

  }
  exportFreezeDownloadTemplate (){
    this._http.requestCall(Endpoints.EXPORT_FREEZE_TEMPLATE, ApiMethod.DOWNLOAD).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "Freeze_Invoice_Upload_Template.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);        
        this._snackBar.loadSnackBar(SuccessMessage.EXPORT_FREEZE_UPLOAD_TEMPLATE, colorCodes.SUCCESS);

        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  exportFreezeTemplateInstructions(){
    this._http.requestCall(Endpoints.EXPORT_FREEZE_INSTRUCTION, ApiMethod.DOWNLOAD).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "freeze_invoice_instruction.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar(SuccessMessage.EXPORT_FREEZE_UPLOAD_INSTRUCTIONS, colorCodes.SUCCESS);

        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }  
}