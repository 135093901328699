import { Component, NgZone } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router } from '@angular/router';

@Component({
  template: `<button mat-icon-button [matMenuTriggerFor]="gridmenu" >
               <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #gridmenu="matMenu">
              <ng-container *ngFor="let menu of params.menu">
              <button mat-menu-item *ngIf = "menu.link && !menu.newTab"  (click)="navigate(menu.link, menu.id ?  params.data[menu.id] : id)">{{menu.name}}</button>
              <button mat-menu-item *ngIf = "!menu.link && !menu.newTab" (click) = "menu.onMenuAction(id)">{{menu.name}}</button>
               <a style = "text-decoration: none" [routerLink]="[menu.link, menu.id ?  params.data[menu.id] : id]" *ngIf = "menu.link && menu.newTab"  target="_blank"><button mat-menu-item >{{menu.name  }}</button></a>
            </ng-container>
                <ng-container *ngFor="let menus of params.menus">
                <button mat-menu-item *ngIf = "menus.link && !menus.newTab"  (click)="navigate(menus.link, menus.id ?  params.data[menus.id] : id)">{{menus.name}}</button>
                <button mat-menu-item *ngIf = "!menus.link && !menus.newTab" (click) = "menus.onMenuActions(id, menus.name)">{{menus.name}}</button>
                <a style = "text-decoration: none"[routerLink]="[menus.link, menus.id ?  params.data[menus.id] : id]" *ngIf = "menus.link && menus.newTab"  target="_blank"><button mat-menu-item >{{menus.name  }}</button></a>
              </ng-container>
            </mat-menu>`,
  styles: ['.mat-menu-item { line-height: 30px;height: 30px;} ' ]
})

export class AgGridMenuComponent implements AgRendererComponent {
  params: any;
  public id;
  constructor(private ngZone: NgZone,
    private router: Router) { }

  refresh(params: any): boolean {
   return false
  }
  agInit(params: import("ag-grid-community").ICellRendererParams): void {
    this.params = params;
    if (params.data ) this.id = this.params.navigateId ? this.params.data[this.params.navigateId]  :  this.params.data.id ;
  }
  navigate(link, id) {
    this.ngZone.run(() => {
        this.router.navigate([link, id]);
    });
  }
}


