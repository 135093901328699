import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokeninterceptorService } from './core/services/interceptors/tokeninterceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './core/modules/material/material.module';
import { AgGridRouterComponent } from './core/modules/shared/components/ag-grid-router/ag-grid-router.component';
import { AgGridMenuComponent } from './core/modules/shared/components/ag-grid-router/ag-grid-menu.component';
import { PromptComponent } from './core/modules/shared/components/prompt/prompt.component';
import { LoaderComponent } from './core/modules/shared/components/loader/loader.component';
import { AgGridStockComponent } from './core/modules/shared/components/ag-grid-stock/ag-grid-stock.component';
import { InputDialogComponent } from './core/modules/shared/components/input-dialog/input-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { purchaseReturnDialog } from './modules/common/pages/beckman-invoice/invoices-detail/invoices-detail.component';



@NgModule({
  declarations: [
    AppComponent,
    AgGridRouterComponent,
    AgGridMenuComponent,
    PromptComponent,
    AgGridStockComponent,
    LoaderComponent,
    InputDialogComponent,
    purchaseReturnDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    ReactiveFormsModule,
    FormsModule
  ],
  entryComponents:[AgGridRouterComponent,AgGridMenuComponent,PromptComponent, AgGridStockComponent,InputDialogComponent,
    purchaseReturnDialog],
  providers: [{
    provide: HTTP_INTERCEPTORS, 
    useClass: TokeninterceptorService, 
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
