import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, ValidatorFn, ValidationErrors, Validator, Form } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { getState, getCities, getRegions } from 'src/app/core/services/location';

import { FormValidatorService } from 'src/app/core/services/formValidator/form-validator.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { ErrorMessage, colorCodes, SuccessMessage } from 'src/app/core/services/constants';
import { StockService } from '../../../cpadmin/service/stock.service';
import { OtlpartsService } from 'src/app/modules/beckman/service/hospital/otlparts.service';
import { LocationService } from 'src/app/core/services/utils/location.service';
import { SecondarysalesService } from 'src/app/modules/cpadmin/service/secondarysales.service';
import { InvoiceService } from '../../../beckman/service/invoice/invoice.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';

import { CpbookingService } from 'src/app/modules/cpadmin/service/cpbooking.service';
import { Router } from '@angular/router';
import { ActionItems } from 'src/app/core/services/constants';
import { Roles } from 'src/app/modules/auth/model/user';
import { ThrowStmt } from '@angular/compiler';

@Component({
    selector: 'add-shipping',
    templateUrl: 'add-shipping.html',
    styles: [`.mat-dialog-container{padding : 0 !important;margin-top: 20px;margin-bottom: 20px; 
      overflow-x : hidden;
      overflow-y : auto;
    }.pointer:hover {cursor: pointer}`],
    encapsulation: ViewEncapsulation.None
  })

export class AddShippingDialog implements OnInit { 
    constructor(
      private _formValidator: FormValidatorService,
      private dialogRef: MatDialogRef<AddShippingDialog>, private fb: FormBuilder, private _secondarySalesService: SecondarysalesService,private _locationService: LocationService,
        @Inject(MAT_DIALOG_DATA) public data) { }
        public State = [];
        public regionFromService = [];
        public currentCities = [];
      
      public shippingForm: FormGroup

      ngOnInit() {
        this.regionFromService = this.data['regionFromService']
        // this.loadLocationData();
        this.loadShippingAddressForm();
        if (this.data.address) this.setAddressForm();
      }
      loadLocationData() {
        //Location from Service call implementation
        this._locationService.getLocationData((locationData) => {
          this.regionFromService = locationData
        });
      }
      loadShippingAddressForm() {
        this.shippingForm = this.fb.group({
          name: ['', Validators.required],
          addressLine: ['', Validators.required],
          region: [{value: '' , disabled : this.data.address ? true : false}, [Validators.required, this._formValidator.requireMatch]],
          state: [{value: '' , disabled : this.data.address ? true : false}, [Validators.required, this._formValidator.requireMatch]],
          city: ['', [Validators.required, this._formValidator.requireMatch]],
          telephone: ['', this._formValidator.numberValidation],
          pincode: ['', [Validators.required,this._formValidator.pincodePatternValidation]],
          mobile: ['',this._formValidator.mobilePatternValidation],
          fax: ['',this._formValidator.numberValidation],
          contactPerson: [''],
          type: [{value: '' , disabled : this.data.address ? true : false}, Validators.required],
          });
      }

      setAddressForm(){
        this.shippingForm.patchValue({
          "name": this.data.address['name'],
          "addressLine": this.data.address['addressLine'] ? this.data.address['addressLine'] : this.data.address['address'],
          "region" : this.getFilterValue('region', this.data.address['region'] ),
          "state" : this.getFilterValue('state', this.data.address['state'] ),
          "city": this.getFilterValue('city', this.data.address['city']),
          "pincode": this.data.address['pincode'],
          "telephone":  this.data.address['telephone'],
          "mobile":  this.data.address['mobile'],
          "fax":  this.data.address['fax'],
          "contactPerson":  this.data.address['contactPerson'] ? this.data.address['contactPerson'] : this.data.address['contactperson'],
          "type" :  this.data.address['type'] 
        })
      }


      onRegionChange(value) {
        this.currentCities =[];
        this.State =[];
        this.shippingForm.patchValue({
          state : '',
          city : ''
        });
        if (value) {
          //Location from Service call implementation
          this.State = value.states;
        }
      }
    
      onStateChange(value) {
        this.currentCities =[];
        this.shippingForm.patchValue({
          city : ''
        });
        if (value) {
           //Location from Service call implementation
          this.currentCities = value.cities;
        }
      }
      getFilterValue(fieldName, value) {
        let options = {};
        if (fieldName === 'region') {
          // this.onRegionChange(value);
          options = this.regionFromService.filter((val) => {
            return val.name.toLowerCase() === value.toLowerCase()
          });
          this.onRegionChange(options[0]);
        } else if (fieldName === 'state') {
          // this.onStateChange(value);
          options = this.State.filter((val) => {
            return val.name.toLowerCase().replace(/\s/g,'') === value.toLowerCase().replace(/\s/g,'')
          });
          this.onStateChange(options[0]);
        } else {
          options = this.currentCities.filter((val) => {
            return  val.name.toLowerCase() === value.toLowerCase()
          });
        }
        
        return options[0] ? options[0] : '';
      }
      submit() {
        let payload = {};
        let shipAddress= this.shippingForm.value;
        delete shipAddress.state;
        delete shipAddress.region;
        shipAddress.city = this.shippingForm.value.city.id;
        payload['address'] = shipAddress;
        payload['custNumber'] = this.data.custNumber;
        payload['type'] = this.data.address  ? this.data.address['type']  :  this.shippingForm.value.type;
        payload['cpNumber'] = this.data.cpnumber
        if (this.data.address && this.data['address']['addressId']) { 
          this._secondarySalesService.EditAddressDetails(this.data['address']['addressId'],payload, (response) => {
            response['address']['addressId'] = response.id 
            response['address']['address'] = response['address']['addressLine']
            this.dialogRef.close(response);
          });
        }else{
          this._secondarySalesService.addShippingAddress(payload, (response) => {
            response['address']['addressId'] = response.id 
            response['address']['address'] = response['address']['addressLine']
            this.dialogRef.close(response);
          }, this.data.address);
        }
         
      }

      dialogClose(): void {
        this.dialogRef.close();
      }
  }



@Component({
    selector: 'list-ship-address',
    templateUrl: 'list-shipping.html',
    styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
      overflow-x : hidden;
      overflow-y : auto;
    }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}`],
    encapsulation: ViewEncapsulation.None
  })

export class ListShippingAddress implements OnInit {
    constructor(
      public dialogRef: MatDialogRef<ListShippingAddress>, private fb: FormBuilder, public _secondarySalesService: SecondarysalesService,
      @Inject(MAT_DIALOG_DATA) public data) { }
    public currentAddress;
  
    ngOnInit() {
      if (this.data.type )
      this.onCpAddressChange (this.data.currentAddressDetails)
      else 
      this.onAddressChange(this.data.currentAddressDetails);  
    }
  
    dialogClose(): void {
      this.dialogRef.close();
    }

    onAddressChange(data) {
      this.data.details.map(details =>{
            if (data.id == details['id']) {
            details['isActive'] = true;
          } else {
            details['isActive'] = false;
          }
      });
        this.currentAddress = data;
    }

    
    changingAddress() {
      delete this.currentAddress.isActive;
      if (this.data.type ) {
        this.currentAddress['address'] = this.currentAddress['addressLine'] ?this.currentAddress['addressLine'] :this.currentAddress['address'];
        this.currentAddress['edited'] = false;
      }
      this.dialogRef.close(this.currentAddress);
    }

    editAddress(shipAddress){
      shipAddress['edited'] = true;
      this.dialogRef.close(shipAddress);
    }


    onCpAddressChange(data){
      this.data.details.map(details =>{
        if (data.cpSiteId == details.cpSiteId) details['isActive'] = true;
        else  details['isActive'] = false;
      })
      this.currentAddress = data;
    }
}


//Add/Edit Parts in Invoice/DC

// parts dialog 
@Component({
  selector: 'add-parts-dialog',
  templateUrl: 'add-parts.html',
  styles: [`.mat-dialog-container{
    padding : 0 !important;
    margin-top: 20px;
    margin-bottom: 20px;
}
.bgWarning{
  background : #f3e4e4

}.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  overflow: auto;
  pointer-events: auto;  
}`],
  encapsulation: ViewEncapsulation.None
})
export class AddPartsDialog implements OnInit {
  public relatedPartsActive: boolean = false;
  public relatedPartsList;
  public partsForm: FormGroup;
  public maxlength= 0;
  public displayPartsKeys = ['partNumber','description'];
  public partsList = [];
  public editDesc = true;
  public currentParts = {}; // for description  
  public getPartsList = []; //  groupby parts with lotnumber and expiry date
  public currentPartsResponse = [];
  public isstockshow: boolean = false; // storing for bulk parts response
  public stockLogPermission;
  public isADD: any;
  public isEdit:any;
  public Logincpnumber: any;
  public isTransfer: boolean;
  public role: any;
  public isChannel_Partner :boolean = false;
  public isotlcheck: boolean = false;
  public selected_otl: any;
  public isAdmin = false;
  constructor(
    public dialogRef: MatDialogRef<AddPartsDialog>, private _snackBar: SnackbarService,
    private fb: FormBuilder,private _FormValidatorService : FormValidatorService,   private _bookingService: CpbookingService,
    public _secondarySalesService: SecondarysalesService,private _otlPartsService: OtlpartsService,
     private _storageService: StorageService,
    @Inject(MAT_DIALOG_DATA) public data) {
      
     }
  ngOnInit() {
    this.loadPartsForm();
    this.loadStockLogPermission();
    this.isADD=  this.data['isADD'];
    this.isEdit = this.data['isEdit'];
    if (this.data.editParts) {
      let value = this.data.editParts;
      this.setPartsList(value)
    } 
    this.role = this._storageService.getUserDetails()['role'];
    this.isAdmin = this.role ==Roles.Admin;
    if(this.isAdmin){
      this.Logincpnumber = this.data['cpnumber'];
    }else{
  this.Logincpnumber = this.data['LogincpNumber'];
    }
    this.partsForm.get('description').valueChanges.subscribe(value=>{
      this.data.partsOutput['editable'] = true;
    })
  }

  editDescription(){
    this.editDesc = !this.editDesc
  }


  loadPartsForm() {
    this.partsForm = this.fb.group({
      partsList: ['', [Validators.required,this._FormValidatorService.requireMatch]],
      description :  ['',Validators.required],
      OTLNumber: ['', [Validators.required, this._FormValidatorService.requireMatch]],
      edited_quantity : ['']
    },  { validator: this.partsFormValidation})
  }

  onOtlChange(value) {
    this.selected_otl = value.OTLnumber;
    this.role = this._storageService.getUserDetails()['role'];
    this.isChannel_Partner = this.role ==Roles.Channel_Partner;
    if (value) {
      if(this.isChannel_Partner){
     this.isTransfer = this.Logincpnumber != value['cpnumber'] ? true: false;
      }else{
        this.isTransfer = false;
      }
      this.partsForm.get('partsList').setValue('');
      this.data.partsOutput['editable'] = false;
      this.onPartsChange('')
      this._bookingService.getOtlPartsOpf({OTLNumber: value.OTLnumber,  "order_by": "name"},(response) =>{
               this.partsList =response;    
              if(this.partsList){
                this._secondarySalesService.getCpStockAvailable({"cpnumber" : this.Logincpnumber,'custNumber' : value.custNumber, "OTLNumber": value.OTLnumber},response=>{
                  if(response.success){

                  }else{
                    this._snackBar.loadSnackBar(SuccessMessage.STOCK_CHECK, colorCodes.ERROR);
                  }
                })
              }
        });
        this.partsForm.get('partsList').enable();
    }else this.partsForm.get('partsList').disable()

    if (this.partsForm.get('groupByParts'))this.partsForm.removeControl('groupByParts');
   
   this.partsForm.get('partsList').updateValueAndValidity();

  }

  onPartsChange(value) {
    this.currentPartsResponse =[];
    if (value){
      this.partsForm.get('description').setValue(value.cpDescription ? value.cpDescription : value.description);
     this.data.partsOutput['editable'] = false;

      if (this.partsForm.get('groupByParts'))this.partsForm.removeControl('groupByParts');
      //setting current value for parts 
      this.currentParts = value
      this._secondarySalesService.getStock({ "OTLNumber": value.OTLNumber,"cpnumber" : this.data.cpnumber, "siteId": this.data.site_id ,'custNumber' : value.custNumber,'partNumber': value.partNumber}, (response) => {
        //differentiate btwn OTL and FOC parts type 
      
        let otlTypeParts = response.filter(res=> {
          if((res.availableQuantity > 0 && res.unitPurchasePrice >0)){
            res['type'] = 'OTL';
            return res;
          }
        });
        let focTypeParts = response.filter(res=> {
          if(res.availableQuantity > 0 && res.unitPurchasePrice ==0) {
            res['price'] = 0;
            res['type'] = 'FoC';
            return res;
          } });
        
        // holds the whole response which available qty >0 
      //  if(response){
        this.currentPartsResponse = otlTypeParts.concat(focTypeParts);
        if(this.currentPartsResponse && this.currentPartsResponse.length){
      
         this.isstockshow = false
        }
        else{
         
          this.isstockshow = true;
        }
       
        
        // groupby lot number and expiry date and merging OTl and FOC parts in single array 
        this.getPartsList = this.groupBySumOfProperty(otlTypeParts,'lotNumber', 'lotExpiryDate').concat(this.groupBySumOfProperty(focTypeParts,'lotNumber', 'lotExpiryDate'));
       // for editing the value can hide the already added parts in the popup 
        if (this.data.dcEdit) {
          this.getPartsList =  !this.data.currentPartsData.length ? this.getPartsList : this.getPartsList.filter(otlPart => !this.data.currentPartsData.find(part =>( ( otlPart['OTLNumber'] === part['OTLNumber'] )&& (otlPart['partNumber'] === part['partNumber']) && (part['type'] == otlPart['type'] ) && (part['lotNumber'] == otlPart['lotNumber'])))); 
        }
       
        else if(this.data.editForm) {
           // this.getPartsList =  !this.data.currentPartsData.length ? this.getPartsList : this.getPartsList.filter(!part['dcNumber'] && otlPart => !this.data.currentPartsData.find(part =>( ( otlPart['OTLNumber'] === part['OTLNumber'] )&& (otlPart['partNumber'] === part['partNumber']) && (part['type'] == otlPart['type'] ) && (part['lotNumber'] == otlPart['lotNumber']))));
            this.getPartsList = !this.data.currentPartsData.length ? this.getPartsList : this.getPartsList.filter(otlPart => !this.data.currentPartsData.find(part =>( !part['dcNumber'] &&( otlPart['OTLNumber'] === part['OTLNumber'] )&& (otlPart['partNumber'] === part['partNumber']) && (part['type'] == otlPart['type'] ) && (part['lotNumber'] == otlPart['lotNumber']))));
          }else {
          if (this.data.currentPartsData.length) {
        
            this.getPartsList = this.getPartsList.map(part => {
             let currValue =  this.data.currentPartsData.find(currPart => ( currPart['OTLNumber'] === part['OTLNumber'] )&& (currPart['partNumber'] === part['partNumber']) && (part['type'] == currPart['type'] ) && (part['lotNumber'] == currPart['lotNumber']))
              if (currValue && !currValue ['dcNumber']) {
                part['quantity'] = currValue['quantity']
              }
             return part; 
            }) 
          }

        }
        
        if (this.getPartsList.length) {
          this.partsForm.addControl('groupByParts',this.fb.array(this.getPartsListArray()));
        
        }
      });
      
    }
  }

  checkPartsQuantity(){
    if ( typeof this.data.partsOutput['originalQuantity'] != 'undefined' ||  this.data.partsOutput['newParts']) return true;
     let getQty=  this.partsForm.get('groupByParts') && this.partsForm.get('groupByParts').value.find(res => res['quantity'] > 0);
     return getQty ? true : false;
  }




  getPartsListArray() {
    return this.getPartsList.map((data) => {
      return this.fb.group({
        availableQty : [!this.data.editParts ? data.availableQuantity : data.availableQty],
        HSSNcode: [data.HSSNcode],
        quantity : [ data.quantity , [this._FormValidatorService.maxEqualLength(!this.data.editParts ? data.availableQuantity : data.availableQty), this._FormValidatorService.negativeValidation,this._FormValidatorService.noDecimalsValidation]],
        lotNumber :[data.lotNumber],
        lotExpirationDate : [data.lotExpirationDate ? data.lotExpirationDate : data.lotExpiryDate],
        HSSNtax : [data.HSSNtax],
        partNumber :[data.partNumber],
        price:[data.price],
        type:[data.type ],
        OTLNumber : [data.OTLNumber],
        description: [data.cpDescription ? data.cpDescription : data.description],
        productLine :[data.productLine],
        newParts : [data.newParts ? data.newParts : false ]
      });
    });
  }

  groupBySumOfProperty(array, key1, key2){
    let obj= {};
    return array.reduce(function(currentArray, data) {
      var key = data[key1] + '-' + data[key2] 
      if(!obj[key]) {
        obj[key] = Object.assign({}, data);
        currentArray.push(obj[key]);
      }else{
        obj[key]['availableQuantity'] =obj[key]['availableQuantity']+ data['availableQuantity']
      }   
      return currentArray;
    }, [])
  }



  setPartsList(value) {
    this.data.partsOutput['editable'] = false
    this.data.partsOutput['OTLNumber'] = value.OTLNumber
    this.data.partsOutput['partNumber'] = value.partNumber;
    this.partsForm.get('OTLNumber').clearValidators();
    this.partsForm.get('partsList').clearValidators();
    this.partsForm.get('description').clearValidators();
    // for Already raised invoice edit 
    if ( typeof value.originalQuantity != 'undefined'){
      if (this.data.dcEdit){
        this.data.partsOutput['invoicedQuantity'] = value.invoicedQuantity;
        this.partsForm.get('edited_quantity').setValidators([ Validators.required ,this._FormValidatorService.noDecimalsValidation, this._FormValidatorService.maxEqualLength(value.availableQuantity + value.quantity),this._FormValidatorService.minNumberValidation(value.invoicedQuantity)  ])
      }else {
        this.partsForm.get('edited_quantity').setValidators([ Validators.required ,this._FormValidatorService.noDecimalsValidation,this._FormValidatorService.minNumberValidation(value.creditQuantity, 'withZero'), this._FormValidatorService.maxEqualLength(value.availableQuantity + value.quantity) ])
      }
      this.partsForm.get('edited_quantity').setValue(value.edited_quantity)
      this.data.partsOutput['quantity'] = value.quantity;
      this.data.partsOutput['originalQuantity'] = value.originalQuantity;
      this.data.partsOutput['productLine'] = value.part[0].productLine;
      this.data.partsOutput['lotNumber'] = value.lotNumber;
      this.data.partsOutput['lotExpiryDate'] = value.lotExpiryDate 
      this.data.partsOutput['HSSNcode'] = value.HSSNcode;
      this.data.partsOutput['HSSNtax'] = value.HSSNtax;
      this.data.partsOutput['description'] = value.description;
      this.data.partsOutput['type'] = value.type;
      this.data.partsOutput['newParts'] = false;
      this.getEditedQtyTax();
      this.data.partsOutput['price'] = value.price;
      this.data.partsOutput['availableQty'] = value.availableQuantity;
      this.partsForm.get('description').setValue(value.cpDescription ? value.cpDescription : value.description);
    }else{
      // newely raised invoice edit 
     this.currentPartsResponse =  value['groupedData'] 
    this.getPartsList = [value];
    this.data.partsOutput['newParts'] = true;
     this.partsForm.addControl('groupByParts',this.fb.array(this.getPartsListArray()));
     this.partsForm.get('description').setValue(value.description);
     this.data.partsOutput['description'] = value.description;
    }
   
  }
  getEditedQtyTax(){
    let value = this.data.partsOutput  ;
    this.data.partsOutput['edited_quantity'] = this.partsForm.get('edited_quantity').value;
    this.data.partsOutput['total_amount'] = this.RoundOFTwoDigit(Number((value.price * this.partsForm.get('edited_quantity').value)));
    this.data.partsOutput['cgst'] = this.partsForm.get('edited_quantity').value > 0 ? this.RoundOFTwoDigit(Number(((value['total_amount']*(value.HSSNtax/2))/100))): 0; 
    this.data.partsOutput['sgst'] = this.partsForm.get('edited_quantity').value > 0 ? this.RoundOFTwoDigit(Number(((value['total_amount']*(value.HSSNtax/2))/100))): 0; 
    this.data.partsOutput['igst'] = this.partsForm.get('edited_quantity').value > 0 ? this.RoundOFTwoDigit(Number(((value['total_amount']*value.HSSNtax)/100))): 0; 
  }


  
  addParts(data): void { 
    // edit description have to update in OTL parts 
    if ( data && this.data.partsOutput['editable']) {
      let payload = {};
      payload['description'] = this.partsForm.get('description').value
      this._secondarySalesService.editDescription(this.currentParts['otlPartId'], payload);
    }

    // groupBy parts details 
    if ((data && !this.data.editParts &&  this.partsForm.get('groupByParts').value )||  (this.data.editParts.newParts)){
      data['groupByParts'] = this.partsForm.value.groupByParts.filter((part)=>{
        if (part['quantity'] > 0){
          part['description'] = this.partsForm.get('description').value
          part['total_amount'] = this.RoundOFTwoDigit(Number((part.price *  part['quantity'])));    
          part['cgst'] =this.RoundOFTwoDigit(Number((( part['total_amount']*(part.HSSNtax/2))/100)))  
          part['sgst'] = this.RoundOFTwoDigit(Number((( part['total_amount']*(part.HSSNtax/2))/100)));
          part['igst'] =  this.RoundOFTwoDigit(Number((( part['total_amount']*part.HSSNtax)/100)));
          part['groupedData'] =  this.currentPartsResponse.filter(res => (res['partNumber'] == part['partNumber'] && res['lotNumber'] == part['lotNumber'] && res['lotExpiryDate'] == part['lotExpirationDate'] && res['type'] == part['type'] ));
          return part
        }
      });
    }else if (this.data.editParts && !this.data.editParts.newParts) {
        this.getEditedQtyTax();
      data = {};
      data['groupByParts'] = [];
      
      this.data.partsOutput['part'] = this.data.editParts['part']
      data['groupByParts'].push(this.data.partsOutput)
    }
    this.dialogRef.close(data);
    }
    dialogClose(): void {
      this.dialogRef.close();
    }
  // relatedParts
  showRelatedParts(partDetails) {
    this._secondarySalesService.getRelatedParts(partDetails.partNumber, this.data.site_id, (response) => {
      this.relatedPartsList = response;
      if (response.length) {
      // dynamically adding control  to  form group 
        this.partsForm.addControl('relatedParts',this.fb.array(this.getRelatedParts()));
      }else {
        if (this.partsForm.get('relatedParts'))this.partsForm.removeControl('relatedParts');
        this._snackBar.loadSnackBar(ErrorMessage.NO_RELATED_PARTS, colorCodes.ERROR);
      }
    });
  }

 RoundOFTwoDigit(num: any){
      var number = Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
      return number;
    }
  getRelatedParts(){
    return  this.relatedPartsList.map( (value) => {
        return this.createRelatedParts(value);
    });
  }

  createRelatedParts(data):FormGroup {
    return  this.fb.group({
      HSSNcode: [data.HSSNcode],
      HSSNtax: [data.HSSNtax],
      OTLNumber:[data.OTLNumber],
      cpNumber: [data.cpNumber],
      description:[data.description],
      id : [data.id],
      lotNumber: [data.lotNumber],
      lotExpirationDate: [data.lotExpirationDate],
      partNumber: [data.partNumber],
      name: [data.name],
      availableQty : [data.quantity],
      price : [data.price],
      siteId : [data.siteId],
      quantity : [data.quantity == 0 ? 0 : '',[this._FormValidatorService.noDecimalsValidation,this._FormValidatorService.maxEqualLength(data.quantity)]]
    });
}

//  validations for both parts and stock 
  partsFormValidation(group: FormGroup):   ValidationErrors | null  {
    let quantityValue;
    if (group.controls.relatedParts){
      let arrayControls = group.controls.relatedParts as FormArray;
       quantityValue = arrayControls.controls.find(control => control.value.quantity && control.value.quantity > 0);
    }
      // if (group.controls.relatedParts && !quantityValue && group.controls['quantity'].value == 0)return {'requiredQuantity' : true} 
      // else if (group.controls['quantity'].value == 0 && !group.controls.relatedParts) return {'requiredQuantity' : true} 
    return null;
 }

 //Function for popup the swapTransfer
 swapStockTransfer(data: any, isswap: any){
  this._bookingService.validateOtl({"site_id": this.data['site_id'],"OTLNumber" : this.selected_otl,"screen" : "stock_transfer"}, (response)=>{
    if(response.success){
      this.dialogRef.close({data, isswap});
    }
  });

 }
 loadStockLogPermission(){
  this._bookingService.getActionPermission({model : 'stocklog'}, response =>{
    this.stockLogPermission= response['stocklog'];
  });
}
 setActionsPermission(name){
  return this.stockLogPermission && typeof this.stockLogPermission[ActionItems[name]] != 'undefined'  ?  true : false;
}
}


//Reduce and Return parts Dialog

@Component({
  selector: 'reduce-reverse',
  templateUrl: 'reduce-reverse.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}`],
  encapsulation: ViewEncapsulation.None
})

export class ReduceReverseDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ReduceReverseDialog>, private fb: FormBuilder, private _FormValidatorService : FormValidatorService, private _StockService: StockService,
    @Inject(MAT_DIALOG_DATA) public data) { }
    public reduceForm: FormGroup

    ngOnInit() { 
      this.reduceForm = this.fb.group({
        reduceQuantity: ['', [Validators.required, this._FormValidatorService.maxLength(this.data.availableQuantity), this._FormValidatorService.noDecimalsValidation]]
      })
    }

    dialogClose(): void {
      this.dialogRef.close();
    }
    reduceStock(){
      delete this.data['end_customer_address']
      delete this.data['end_customer_city']
      delete this.data['end_customer_pincode']
      delete this.data['end_customer_region']
      delete this.data['end_customer_state']
      let payload = {};
      payload['reduceQuantity'] =  this.reduceForm.get('reduceQuantity').value;
      payload['data'] = this.data;

      this._StockService.reduceStockQty(payload , (res) => {
        this.dialogRef.close(res);
      })
    }
}
''

// load DC parts 
 

//Reduce and Return parts Dialog

@Component({
  selector: 'dc-to-invoice-parts',
  templateUrl: 'dc-to-invoice-parts.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
.mat-checkbox-layout{
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
.mat-checkbox-checked .mat-checkbox-background{
  background-color :#E6313A !important
}

  ` 

],
  encapsulation: ViewEncapsulation.None
})

export class DcToInvoicePartsDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DcToInvoicePartsDialog>, private fb: FormBuilder, private _FormValidatorService : FormValidatorService,  private _secondarySalesService: SecondarysalesService,private _snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data) { }
    public dcInvoiceForm: FormGroup
    public selectAllCheckbox =true;

    ngOnInit() { 
      if(this.data.type == "DC_LIST") {
        this.dcInvoiceForm = this.fb.group({
          dcNumber:[''],
          orderNumber:[''],
          dcInvoiceParts :this.fb.array(this.getPartsListArray(this.data.parts)),
        });
      }else{
          if (this.data['Create_Invoice']) {
            this.dcInvoiceForm = this.fb.group({
              quantity:[this.data.parts['quantity'],[ this._FormValidatorService.maxEqualLength(this.data.parts['originalQty']), this._FormValidatorService.noDecimalsValidation,this._FormValidatorService.negativeValidation,  Validators.required]],
            });
          }else{
            this.dcInvoiceForm = this.fb.group({
              quantity:[this.data.parts['newParts'] ? this.data.parts['quantity'] :this.data.parts['edited_quantity'] ,this.data.parts['newParts'] ? [ this._FormValidatorService.maxEqualLength(this.data.parts['originalQty']), this._FormValidatorService.noDecimalsValidation,this._FormValidatorService.negativeValidation,  Validators.required] : [this._FormValidatorService.noDecimalsValidation, this._FormValidatorService.maxEqualLength(this.data.parts['dcQuantity']),  Validators.required]],
            });
            if  (!this.data.parts.newParts && !this.data.parts.dcQuantity) this.dcInvoiceForm.get('quantity').disable();
          }
          
      
      }
    }
    getPartsListArray(partsList){
      return partsList.map((data) => {
          return  this.creatingControlsForDCList(data)
      });
    }
    creatingControlsForDCList(data) {
      return this.fb.group({
        checked : [true],
        dcNumber:[data.dcNumber],
        orderNumber : [data.orderNumber],
        net_amount:[data.net_amount],
        dcDate:[data.dcDate],
        id : [data.id],
        newParts : [data.newParts ? data.newParts : false]
      });
    }

   

    setCheckBox(value){
      this.selectAllCheckbox =value;
      let parts = this.dcInvoiceForm.get("dcInvoiceParts") as FormArray 
      parts.controls.map(data =>{
        data.patchValue({
          checked : value,
        })
      })
    
    }
    dcPartsGotChecked(){
      let parts = this.dcInvoiceForm.get("dcInvoiceParts") as FormArray 
      return parts &&  parts.controls.find(data =>data.value['checked']) ? true : false
    }
    search(){
      let formValue =  this.dcInvoiceForm.value 
      this.selectAllCheckbox =true;
      this._secondarySalesService.getDcList({dcNumber :  formValue.dcNumber ? formValue.dcNumber : '', orderNumber : formValue.orderNumber ? formValue.orderNumber : '', custNumber :  this.data.custNumber}, (response) => { 
          let dcList  = this.dcInvoiceForm.get('dcInvoiceParts') as FormArray;
          dcList.clear();
          if (response.length){
            response.map(res =>{
              dcList.push(this.creatingControlsForDCList(res));
            })
          }else{
          this._snackBar.loadSnackBar('There is no records found for this search values' , colorCodes.ERROR);
          }
        });
    }
   
    reset(){
      this.dcInvoiceForm.reset();
      this.selectAllCheckbox =true;
      let dcList  = this.dcInvoiceForm.get('dcInvoiceParts') as FormArray;
      dcList.clear();
      this.data.parts.map(res =>{
        dcList.push(this.creatingControlsForDCList(res));
      })
    }
    submitDcList(){
      let array =[];
      this.dcInvoiceForm.get("dcInvoiceParts").value.map(res => {
        if (res.checked) array.push(res.id)
      })
    
      this.dialogRef.close(array) ;
    }

    updateDc(){
      this.dialogRef.close(this.dcInvoiceForm.value) ;
    }

    dialogClose(): void {
      this.dialogRef.close();
    }
   
   
}

@Component({
  selector: 'credit-debit-parts',
  templateUrl: 'credit-debit-part.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
.mat-checkbox-layout{
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
 ` 
], 
  encapsulation: ViewEncapsulation.None
})
export class CreditDebitPartsDialog implements OnInit {
  remainingQty: any = 0;
  constructor(
    public dialogRef: MatDialogRef<DcToInvoicePartsDialog>, private fb: FormBuilder, private _FormValidatorService : FormValidatorService,  private _secondarySalesService: SecondarysalesService,private _snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data) { }
    public creditForm: FormGroup

    ngOnInit() { 
        this.creditForm = this.fb.group({
            creditQty:[this.data.editCredit ? this.data.parts['edited_quantity']  : this.data.parts['creditQuantity'] ,
             this.data.editCredit  ? 
            [this._FormValidatorService.maxEqualLength(this.data.parts['validateQty']) , this._FormValidatorService.noDecimalsValidation,  Validators.required]  : 
            [this._FormValidatorService.maxEqualLength(this.data.parts['validateQty']) , this._FormValidatorService.negativeValidation, this._FormValidatorService.noDecimalsValidation, 
             Validators.required] ],
        });
        if (this.data.editCredit) this.remainingQty = this.data.parts.remainingUpdatedQuantity ? this.getEditRemaningQty( this.creditForm.get('creditQty').value) :this.data.parts.remainingQuantity
        else this.remainingQty = this.getRemainingQty( this.creditForm.get('creditQty').value)

        this.creditForm.get('creditQty').valueChanges.subscribe(res=>{
          if (this.data.editCredit) this.remainingQty = this.getEditRemaningQty(res)
          else  this.remainingQty = this.getRemainingQty(res)
        })
         
    }
    getRemainingQty(response){
      return  Math.abs(this.data.parts.quantity -  this.data.parts.alreadyRaiseCreditQty - response)
    }

    getEditRemaningQty(response){ 
      if(this.data.parts['invoiceQuantity'] ==  response) return 0;
      else return  Math.abs(response - this.data.parts['creditQuantity'] - this.data.parts['remainingQuantity']) 
    }



   
    updateDc(){
      this.dialogRef.close(this.creditForm.value) ;
    }

    dialogClose(): void {
      this.dialogRef.close();
    }
}
@Component({
  selector: 'tax-parts',
  templateUrl: 'tax-part.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
.mat-checkbox-layout{
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
 ` 
], 
  encapsulation: ViewEncapsulation.None
})

export class TaxDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DcToInvoicePartsDialog>, private fb: FormBuilder, private _FormValidatorService : FormValidatorService,  private _secondarySalesService: SecondarysalesService,private _snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data) { }
    public taxForm: FormGroup
  ngOnInit(){
    if(this.data){
    this.taxForm = this.fb.group({
      hssntax:[this.data['parts']['HSSNtax'], [Validators.required, this._FormValidatorService.maxTaxLength(100)]]
    })
  }
  }
  updatetax(){
    this.dialogRef.close(this.taxForm.get('hssntax').value) ;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }
}


@Component({
    selector: 'add-beckman-parts-dialog',
    templateUrl: 'add-beckman-parts-dialog.html',
    styles: [`.mat-dialog-container{
      padding : 0 !important;
      margin-top: 20px;
      margin-bottom: 20px;
  
  .bgWarning{
   background : #f3e4e4
  
  }.cdk-global-overlay-wrapper {
   display: flex;
   position: absolute;
    z-index: 1000;
    overflow: auto;
  pointer-events: auto;  
  `],
    encapsulation: ViewEncapsulation.None
  })
  export class AddBeckManInvoicePartsDialog implements OnInit{
    inputForm: FormGroup;
    inputData: any;
    minimunValue: any;
    isChannelPartner: any;
    isAdmin: any;
    isdirectupdate: boolean = true;
    isEditValue: boolean=true;
    constructor(
      public dialogRef: MatDialogRef<AddBeckManInvoicePartsDialog>, private _snackBar: SnackbarService,
      private fb: FormBuilder,private _FormValidatorService : FormValidatorService, 
      public _secondarySalesService: SecondarysalesService,private _otlPartsService: OtlpartsService, 
      public _UtilsService : UtilsService,
      public _invoiceservice: InvoiceService, private formbuilder: FormBuilder,
      private _StorageService : StorageService,

      @Inject(MAT_DIALOG_DATA) public data) {
        
       }
    ngOnInit() { 
      this.isChannelPartner = this._UtilsService.isCpRole(this._StorageService.getUserDetails().role);
      this.isAdmin = this._UtilsService.isAdminRole(this._StorageService.getUserDetails().role);
      let partsData = this.data;
      let partData = partsData.partIndex;
      const number = partData.partNumber;
      if(this.data.partIndex!=='undefined' && this.data.editparts!=='undefined' && this.data.totalData.revenue!== 'undefined'){
              this.inputData= this.data.partIndex.acknowledgedQuantity;
              if(this.isChannelPartner){
                if(this.data.totalData.revenue.status == 0){
                  this.minimunValue = 0;
                }else{
                  if(this.data.partIndex.isAcknowledged == 0 ){
                    this.minimunValue = 0;
                  }else{
                    this.minimunValue = this.data.partIndex.acknowledgedQuantity;
                  }
                }

              }else{
                if(this.isAdmin){
                  if(this.data.partIndex.isAcknowledged == 3 || this.data.partIndex.isAcknowledged == 1){
                    this.minimunValue = Math.abs(this.data.partIndex.acknowledgedQuantity - this.data.partIndex.stockAvailableQuantity);  
                  }else{
                    if(this.data.partIndex.isAcknowledged ==0){
                      this.minimunValue = 0;
                    }
                  }
                }
              }
            
              this.loadForm();
            }
          }
          loadForm(){
            this.inputForm = this.formbuilder.group({
              ackquantity:[this.inputData, [Validators.required, Validators.max(this.data.partIndex.shippedQuantity), Validators.min(this.minimunValue)]]
            })
          }
          get f() { return this.inputForm.controls; }
        
        
          getLinelevelAcknowledgment(){
            if (this.inputForm.invalid) {
              return;
          }
            if(this.data.totalData.revenue.status == 0){
              this.isdirectupdate = true;
              const partvalue = this.inputForm.value;
              this.data.partIndex.acknowledgedQuantity = partvalue.ackquantity;
              this.dialogRef.close(this.data.partIndex); 
            }
            else{
              this.isdirectupdate = false
              const partvalue = this.inputForm.value;
              this.data.partIndex.acknowledgedQuantity = partvalue.ackquantity; 
            }


    }
    dialogClose(): void {
      this.dialogRef.close();
    }
    isEdit(){
      this.isdirectupdate = true;
    }
    isConfirm(){
      this.dialogRef.close(this.data.partIndex); 
    }
  }

  @Component({
    selector: 'add-beckman-invoice-dialog',
    templateUrl: 'add-beckman-invoice-dialog.html',
    styles: [`.mat-dialog-container{
      padding : 0 !important;
      margin-top: 20px;
      margin-bottom: 20px;
  
  .bgWarning{
   background : #f3e4e4
  
  }.cdk-global-overlay-wrapper {
   display: flex;
   position: absolute;
    z-index: 1000;
    overflow: auto;
  pointer-events: auto;  
  `],
    encapsulation: ViewEncapsulation.None
  })
export class AddBeckmanInvoiceDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddBeckManInvoicePartsDialog>, private _snackBar: SnackbarService,
    private fb: FormBuilder,private _FormValidatorService : FormValidatorService, 
    public _secondarySalesService: SecondarysalesService,private _otlPartsService: OtlpartsService, 
    public _UtilsService : UtilsService,
    public _invoiceservice: InvoiceService, private formbuilder: FormBuilder,
    private _StorageService : StorageService,

    @Inject(MAT_DIALOG_DATA) public data) {
      
     }
     public InvoiceForm: FormGroup;
     selected = 0;
     selectedcheckbox= false;
     public selectedcustomernumber: any;
     public selectedsiteId: any;
     public filter_value: any;
     public issuanceData: any;
     public issuance_key: any;
  ngOnInit(){
    if(this.data){
      this.selectedcustomernumber = this.data['customerNumber'];
      this.selectedsiteId = this.data['siteId'];
      this.issuanceData = this.data['IssuanceId'];
      this.issuance_key = this.data['Issuancekey'];
    }
      this.loadingInvoiceForm();
  }

  loadingInvoiceForm() {
    this.InvoiceForm = this.fb.group({
      InvoiceNumber:[''],
      creditInvoiceParts: this.fb.array(this.getInvoiceListArray(this.data.currentData)),
    })
  }

  getInvoiceListArray(invoiceList){
    return invoiceList.map((data) => {
        return  this.creatingControlsForInvoiceList(data)
    });
  }
  creatingControlsForInvoiceList(data) {
   
    return this.fb.group({
      checked : [false],
      invoiceNumber:[data.invoiceNumber],
      net_amount:[this.issuanceData == 2? data.total_amount: data.net_amount],
      invoiceDate:[data.invoiceDate],
       id:[data.id],
      invoice_identifier:[data.invoiceIdentifier]
    });
  }
  setCheckBox(event:any, key:any, id:any){
  
   this.InvoiceForm.get("creditInvoiceParts").value.map(item => {
      if(item.id == id){
        this.selected= key
          item.checked = event
      }else{
        item.checked = false
      }
    
      return item;
    })
  }
  dcPartsGotChecked(){
    let parts = this.InvoiceForm.get("creditInvoiceParts") as FormArray 
      return parts &&  parts.controls.find(data =>data.value['checked']) ? true : false
}
  submitInvoiceList(){
    let array =[];
    this.InvoiceForm.get("creditInvoiceParts").value.map(res => {
      
      if (res.checked) {
        this.filter_value = this.data['currentData'].filter(item=> item['invoiceNumber'] == res['invoiceNumber'])
       
      }
    })   
 this.dialogRef.close(this.filter_value) ;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }
  search(){
    let invoicevalue =  this.InvoiceForm.get('InvoiceNumber').value;
   // this.selectAllCheckbox =true;
   this._secondarySalesService.getInvoiceListwithTax({"invoiceNumber" :  invoicevalue, "custNumber" : this.selectedcustomernumber,"siteId":this.selectedsiteId}, (response) => { 
        let invoiceList  = this.InvoiceForm.get('creditInvoiceParts') as FormArray;
        invoiceList.clear();
        if (response.length){
          response.map(res =>{
            invoiceList.push(this.creatingControlsForInvoiceList(res));
          })
        }else{
        this._snackBar.loadSnackBar('There is no records found for this search values' , colorCodes.ERROR);
        }
      });
  }
  reset(){
    this.InvoiceForm.reset();
   // this.selectAllCheckbox =true;
    let invoiceList  = this.InvoiceForm.get('creditInvoiceParts') as FormArray;
    invoiceList.clear();
    this.data.currentData.map(res =>{
      invoiceList.push(this.creatingControlsForInvoiceList(res));
    })
  }
}

@Component({
  selector: 'add-in-parts',
  templateUrl: 'add-in-parts.html',
  styles: [`.mat-dialog-container{
    padding : 0 !important;
    margin-top: 20px;
    margin-bottom: 20px;

.bgWarning{
 background : #f3e4e4

}.cdk-global-overlay-wrapper {
 display: flex;
 position: absolute;
  z-index: 1000;
  overflow: auto;
pointer-events: auto;  
`],
  encapsulation: ViewEncapsulation.None
})

export class AddInParts implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddInParts>, private _snackBar: SnackbarService,
    private fb: FormBuilder,private _FormValidatorService : FormValidatorService, 
    public _secondarySalesService: SecondarysalesService,private _otlPartsService: OtlpartsService, 
    public _UtilsService : UtilsService,
    public _invoiceservice: InvoiceService, private formbuilder: FormBuilder,
    private _StorageService : StorageService,

    @Inject(MAT_DIALOG_DATA) public data) {
      
     }
     public partsList: [];
     partsForm: FormGroup;
     public selectAllCheckbox = true;
     public invoiceIdentifier: any;
     public select_parts:any;
     public updated_parts:any;
     public arrayvalue: any;
     public issuance:any;
     public part =[];
     public issuanceKey: any;
     public cust_num: any;
     public site_id: any;
     public select_type: any;

  ngOnInit(){

    if(this.data && this.data['selectedInvoiceValue']){
      this.issuance = this.data['SelectedIssuance']['value'];
      this.invoiceIdentifier = this.data['selectedInvoiceValue'][0]['invoiceIdentifier'];
       this.issuanceKey = this.data['Issuancekey'];   
       this.select_type = this.data['selected_type'];  
        if(this.issuanceKey == 'value_parts'){
          this._secondarySalesService.getPartsBasedOnInvoiceNo({invoiceIdentifier: this.invoiceIdentifier, issuance_type: this.issuance, cd_type: this.select_type}, res=>{
            if(res){
            this.partsList = res;
            if(this.partsList){
            
              this.loadTableForm();
            }
            }
          });
        }else{
          this._secondarySalesService.getPartsBasedOnInvoiceNo({invoiceIdentifier: this.invoiceIdentifier, issuance_type:  this.issuance, cd_type:this.select_type}, res=>{
            if(res){
            this.partsList = res;
  
            if(this.partsList){
            
              this.loadTableForm();
            }
            }
          });
        }

    }
   
  }

  loadTableForm(){
    this.partsForm = this.fb.group({
      invoicenumber:[''],
      PartsDataList: this.fb.array(this.getPartsListArray(this.partsList)),
    })
  }
  getPartsListArray(parts: any){
 
    return parts.map((data) => {
        return  this.creatingControlsForPartsList(data)
    });
  }
 
  creatingControlsForPartsList(data) {
    return this.fb.group({
      checked : [true],
      OTLNumber:[data.OTLNumber],
      partNumber:[data.partNumber],
      description:[data.description],
      quantity:[data.quantity],
      //tax:[data.cgst],
      igst: [this.RoundOFTwoDigit(data.igst)],
      cgst:[this.RoundOFTwoDigit(data.cgst)],
      sgst:[this.RoundOFTwoDigit(data.sgst)],
      lotNumber:[data.lotNumber],
      lotExpiryDate:[data.lotExpiryDate],
     total_amount:[data.total_amount],
     HssNcode: [data.HSSNcode],
     HSSNtax: [data.HSSNtax],
     creditQuantity:[data.creditQuantity],
     dcNumber:[data.dcNumber],
     dcQuantity:[data.dcQuantity],
     originalQuantity:[data.originalQuantity],
     price:[data.price],
     stockAvailableQuantity:[data.stockAvailableQuantity],
     net_amount:[data.net_amount],
     type:[data.type],
      invoiceNumber : this.data['selectedInvoiceValue'][0]['invoiceNumber'],
    invoiceIdentifier :this.data['selectedInvoiceValue'][0]['invoiceIdentifier'],
    invoiceDate : this.data['selectedInvoiceValue'][0]['invoiceDate'],
    //part :this.data['part']


    });
  }
  setCheckBox(value){
    this.selectAllCheckbox =value;
    let parts = this.partsForm.get("PartsDataList") as FormArray 
    parts.controls.map(data =>{
      data.patchValue({
        checked : value,
      })
    })
   }
 
   dcPartsGotChecked(){
      let parts = this.partsForm.get("PartsDataList") as FormArray 
      return parts &&  parts.controls.find(data =>data.value['checked']) ? true : false
    }
   submitPartsList(){
    let array =[];
//     this.partsForm.get("PartsDataList").value.map(res => {
//       if (res.checked) {
//         array.push(res);
//     }
//   });
//   var resArr = [];
//   array.filter(function(item){
//   var i = resArr.findIndex(x => (x.lotNumber == item.lotNumber && x.partNumber == item.partNumber && x.OTLNumber == item.OTLNumber));
//   if(i <= -1){
//         resArr.push(item);
//   }
//   return null;
// });
    this.partsForm.get("PartsDataList").value.map(res => {
      if (res.checked) {
     
    
       this.select_parts=this.partsList.find(item =>item['lotNumber'] == res['lotNumber'] && item['partNumber'] == res['partNumber'])
   
        this.select_parts['invoiceNumber'] = this.data['selectedInvoiceValue'][0]['invoiceNumber'];
        this.select_parts['invoiceIdentifier'] = this.data['selectedInvoiceValue'][0]['invoiceIdentifier'];
        this.select_parts['invoiceDate'] = this.data['selectedInvoiceValue'][0]['invoiceDate'];
     //  if(this.select_parts['total_amount']){
          // this.select_parts['net_amount'] = this.select_parts['total_amount']
          // delete this.select_parts['total_amount']
      // }
      array.push(this.select_parts)
      }
  
    
    })
  this.dialogRef.close({array, 'invoice_identifier':this.invoiceIdentifier}) ;

   }
   RoundOFTwoDigit(num: any){
    var number = Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
    return number;
  }
   dialogClose(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'edit-value-parts',
  templateUrl: 'edit-value-parts.html',
  styles: [`.mat-dialog-container{
    padding : 0 !important;
    margin-top: 20px;
    margin-bottom: 20px;

.bgWarning{
 background : #f3e4e4

}.cdk-global-overlay-wrapper {
 display: flex;
 position: absolute;
  z-index: 1000;
  overflow: auto;
pointer-events: auto;  
`],
  encapsulation: ViewEncapsulation.None
})

export class EditValueParts implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditValueParts>, private _snackBar: SnackbarService,
    private fb: FormBuilder,private _FormValidatorService : FormValidatorService, 
    public _secondarySalesService: SecondarysalesService,private _otlPartsService: OtlpartsService, 
    public _UtilsService : UtilsService,
    public _invoiceservice: InvoiceService, private formbuilder: FormBuilder,
    private _StorageService : StorageService,
    @Inject(MAT_DIALOG_DATA) public data) {
      
     }
     editvalueform: FormGroup;
     max_value :any
     credit_debit_value : any;
   ngOnInit(){
    if(this.data){
      this.credit_debit_value = this.data['type'];
    }
    if(this.data['type'] == 'credit')
   {
     if(this.data['creditId']){
      this.max_value = Math.abs(this.data['parts']['total_amount'] -this.data['parts']['raised_value'] + this.data['parts']['net_amount']);
       
    this.editvalueform = this.fb.group({
      creditvalue:[Math.abs(this.data['parts']['net_amount']), [this._FormValidatorService.maxpartsinvoiceLength(this.max_value),
        Validators.required]
    ]
    })
     }else{
        this.max_value = Math.abs(this.data['parts']['raised_value'] - this.data['parts']['total_amount']);
         
    this.editvalueform = this.fb.group({
      creditvalue:[Math.abs(this.data['parts']['net_amount']), [this._FormValidatorService.maxpartsinvoiceLength(this.max_value),
        Validators.required]
    ]
    })
     }
    

   }
   else{
    this.editvalueform = this.fb.group({
      creditvalue:[this.data['parts']['net_amount'], 
       [this._FormValidatorService.negativeValidation,
      Validators.required]]
    })
   }

  }
  updatecredit(){
    this.dialogRef.close(this.editvalueform.get('creditvalue').value)

  }
  dialogClose(){
    this.dialogRef.close();
  }
}


@Component({
  selector: 'add-input-items',
  templateUrl: 'add-input-items.html',
  styles: [`.mat-dialog-container{
    padding : 0 !important;
    margin-top: 20px;
    margin-bottom: 20px;

.bgWarning{
 background : #f3e4e4
}.cdk-global-overlay-wrapper {
 display: flex;
 position: absolute;
  z-index: 1000;
  overflow: auto;
pointer-events: auto;  
`],
  encapsulation: ViewEncapsulation.None
})


export class AddInputItems implements OnInit{
  inputForm: FormGroup;
 // submitted = false;
 // registerForm: FormGroup;
  submitted = false;
  type: any;
  constructor(
    public dialogRef: MatDialogRef<AddInputItems>, private _snackBar: SnackbarService,
    private fb: FormBuilder,private _FormValidatorService : FormValidatorService, 
    public _secondarySalesService: SecondarysalesService,private _otlPartsService: OtlpartsService, 
    public _UtilsService : UtilsService,
    public _invoiceservice: InvoiceService, private formbuilder: FormBuilder,
    private _StorageService : StorageService,

    @Inject(MAT_DIALOG_DATA) public data){}
  
  ngOnInit() {
    if(this.data){
      this.type = this.data['Creditdebittype'];
    }
    this.inputForm = this.fb.group({
        creditvalue: ['', [Validators.required, Validators.min(1)]],
        description: ['', Validators.required],
    });
}

// convenience getter for easy access to form fields
get f() { return this.inputForm.controls; }

onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.inputForm.invalid) {
        return;
    }
    else{
      this.dialogRef.close({'inputData':this.inputForm.value})
    }
}

dialogClose(){
  this.dialogRef.close();
}

}
@Component({
  selector: 'tax-valuefree-parts',
  templateUrl: 'tax-valuefree-parts.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
.mat-checkbox-layout{
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
 ` 
], 
  encapsulation: ViewEncapsulation.None
})

export class TaxValueFreeDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DcToInvoicePartsDialog>, private fb: FormBuilder, private _FormValidatorService : FormValidatorService,  private _secondarySalesService: SecondarysalesService,private _snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data) { }
    public taxValueForm: FormGroup;
    indexvalue: number;
  ngOnInit(){
    if(this.data){
      this.indexvalue = this.data['index'];
    this.taxValueForm = this.fb.group({
      hssntax:[this.data['parts']['HSSNtax'], [Validators.required, this._FormValidatorService.maxTaxLength(100), this._FormValidatorService.twodecimalallow()]]
    })
  }
  }
  updatetax(){
    this.dialogRef.close(this.taxValueForm.get('hssntax').value) ;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'edit-value-free-amount',
  templateUrl: 'edit-value-free-amount.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
.mat-checkbox-layout{
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
 ` 
], 
  encapsulation: ViewEncapsulation.None
})

export class EditValueFreeAmount implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DcToInvoicePartsDialog>, private fb: FormBuilder, private _FormValidatorService : FormValidatorService,  private _secondarySalesService: SecondarysalesService,private _snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data) { }
    public EditAmountForm: FormGroup
    indexvalue: number;
  ngOnInit(){
   
    if(this.data){
      this.indexvalue = this.data['index'];
    this.EditAmountForm = this.fb.group({
      amount:[this.data['parts']['net_amount'], [Validators.required, this._FormValidatorService.zerolength(0)]]
    })
  }
  }
  updateValue(){
    this.dialogRef.close(this.EditAmountForm.get('amount').value) ;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'tax-value-invoice',
  templateUrl: 'tax-value-invoice.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
.mat-checkbox-layout{
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
 ` 
], 
  encapsulation: ViewEncapsulation.None
})

export class TaxValueInvoiceDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TaxValueInvoiceDialog>, 
    private fb: FormBuilder, private _FormValidatorService : FormValidatorService,  
    private secondarySalesService: SecondarysalesService,private snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data) { }
    public taxvalueInvoiceForm: FormGroup
  ngOnInit(){
    if(this.data){
    this.taxvalueInvoiceForm = this.fb.group({
      HSSNtax:[this.data['parts']['HSSNtax'], [this._FormValidatorService.maxTaxLength(100),Validators.required]]
    })
  }
  }
  updatetax(){
    this.dialogRef.close(this.taxvalueInvoiceForm.get('HSSNtax').value) ;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'value-invoice',
  templateUrl: 'value-invoice.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
.mat-checkbox-layout{
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
 ` 
], 
  encapsulation: ViewEncapsulation.None
})

export class ValueInvoiceDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ValueInvoiceDialog>, 
    private fb: FormBuilder, private _FormValidatorService : FormValidatorService,  
    private secondarySalesService: SecondarysalesService,private snackBar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data) { }
    public valueInvoiceForm: FormGroup
    public credit_debit_value : any;
    public max_value =0;
  ngOnInit(){
    if(this.data){
     
        this.credit_debit_value = this.data['type'];
      
    // this.valueInvoiceForm = this.fb.group({
    //   creditvalue:[this.data['parts']['net_amount'], 
    //   [this._FormValidatorService.negativeValidation, this._FormValidatorService.noDecimalsValidation, 
    //   Validators.required]]
    // })
    if(this.data['type'] == 'credit')
    {
      if(this.data['creditId']){
       this.max_value = Math.abs(this.data['parts']['total_amount'] -this.data['parts']['raised_value'] + this.data['parts']['net_amount']);
        
     this.valueInvoiceForm = this.fb.group({
       creditvalue:[Math.abs(this.data['parts']['net_amount']), [ this._FormValidatorService.maxpartsinvoiceLength(this.max_value),
         Validators.required]
     ]
     })
 
      }else{
         this.max_value = Math.abs(this.data['parts']['raised_value'] - this.data['parts']['total_amount']);
          
     this.valueInvoiceForm = this.fb.group({
       creditvalue:[Math.abs(this.data['parts']['net_amount']), [this._FormValidatorService.maxpartsinvoiceLength(this.max_value),
         Validators.required]
     ]
     })
      }
     
 
    }

    else{
     this.valueInvoiceForm = this.fb.group({
       creditvalue:[this.data['parts']['net_amount'], 
        [this._FormValidatorService.negativeValidation,
       Validators.required]]
     })
    }
  }
  }
  updatetax(){
    this.dialogRef.close(this.valueInvoiceForm.get('creditvalue').value) ;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }
}
@Component({
  selector: 'add-stock-transfer',
templateUrl: 'add-stock-transfer.html',
styles: [`.mat-dialog-container{
  padding : 0 !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
.bgWarning{
background : #f3e4e4

}
.otlBg {
background: #F8F8F8;
}
.transfer-icon {
font-size: 95px;
height: auto;
width: auto;
color: #d0d0d0;
}
small {
color:#888;
}
.cdk-global-overlay-wrapper {
display: flex;
position: absolute;
z-index: 1000;
overflow: auto;
pointer-events: auto;  
}`],
encapsulation: ViewEncapsulation.None

})
export class AddStockSwapTransfer implements OnInit {

public displayClientKeys = ['name','address']
public displayPartsType = ['partNumber','description','type']
public stockId;
public stockTransferForm: FormGroup;
public clientNames = [];
public otlList = [];
public partsList =[];
public clientNumber;
public otlPartsList = [];
public partsData = [];
public transferDetails = [];
public viewStock = false;
public relatedParts =[];
public transferPartsToDetails= [];
public active: any;
public client_name: any;
public otl_num: any;
public partNumberLength: boolean = false;
public selected_Part: any;
public resetpart_number: any;
constructor(public dialogRef: MatDialogRef<AddStockSwapTransfer>, private fb: FormBuilder, private _bookingService: CpbookingService, private _formValidator: FormValidatorService ,private _secondarysalesService :SecondarysalesService, 
   private _router: Router,private _snackBar :SnackbarService,private _StorageService: StorageService,
  private _stockService:StockService,  @Inject(MAT_DIALOG_DATA) public data
  ) { }

ngOnInit() {
    if(this.data && this.data['swapData']){
    this.client_name = this.data['swapData']['clientName']['name'];
    this.otl_num = this.data['swapData']['otlNumber']['OTLnumber'];
    }

  this._bookingService.getActionPermission({model : 'stocklog'}, response =>{
    if (!response['stocklog'] || !response['stocklog'][ActionItems['ADD_STOCK_TRANSFER']])this.cancel();
  })
  if(this.data){
  this.loadStockTransferForm();
  if (this._StorageService.getUserDetails().role == Roles.Channel_Partner){
    this._secondarysalesService.cpModuleAccess(res => {
      if (res['stockTransferLock'] == 1 )   this._router.navigate(['/channel-partner/secondary-sales/stock-transfer']);
     });
  }   
  this.stockTransferForm.get('desiredQty').valueChanges.subscribe(value => {
    if (this.stockTransferForm.get('relatedParts')  ) {
      let arrayControl = this.stockTransferForm.get('relatedParts') as FormArray;
      arrayControl.controls.map((control) => {
        value ? control.get('desiredQuantityForSwapTransfer').enable() :control.get('desiredQuantityForSwapTransfer').disable() ;
        control.updateValueAndValidity();
      });
    }
    
  })

  this._bookingService.getOtlPartsOpf({OTLNumber: this.data['swapData']['otlNumber']['OTLnumber'], partNumber: this.data['swapData']['partNumber']['partNumber']},(response) =>{
    if(response){
        if(response.length == 1){
          this.resetpart_number = response[0];
          this.partNumberLength = true;
          response = response.map(res =>{
            if (res.price == 0){
              res['type'] = 'FoC'
            }else{
              res['type'] = "OTL"
            }
            return res;
          })
          this.stockTransferForm.patchValue({
            partNumber: response[0]['partNumber']
          })

         // this.partsList = response;
          this.onpartsChange(response[0])
        }else{
          this.partNumberLength = false
          response = response.map(res =>{
            if (res.price == 0){
              res['type'] = 'FoC'
            }else{
              res['type'] = "OTL"
            }
            return res;
          })
          this.partsList = response;

        }

     
    }
  })
  }
  if (this._StorageService.getUserDetails().role == Roles.Channel_Partner){
    this._secondarysalesService.cpModuleAccess(res => {
      if (res['stockTransferLock'] == 1 )   this._router.navigate(['/channel-partner/secondary-sales/stock-transfer']);
     });
  } 
}

loadStockTransferForm() {
  this.stockTransferForm = this.fb.group({ 
    fromHospital: [this.data['swapData']['clientName']['name']],
    fromOtl: [this.data['swapData']['otlNumber']['OTLnumber']],
    fromCustNumber: [this.data['swapData']['clientNumber']],
    fromSiteId: [this.data['swapData']['otlNumber']['site_id']],
    desiredQty: ['', Validators.required],
    partNumber: ['',[Validators.required, this._formValidator.requireMatch]],
    type:['Temporary']
  }, { validator: this.transferPartsValidation });
}
onpartsChange(value){
  this.resetParts();
  this.selected_Part = value.partNumber;
  if (value.isActive == 0){
    this._snackBar.loadSnackBar( value.partNumber + "-"  +ErrorMessage.IN_ACTIVE_PARTS , colorCodes.ERROR);
  }else{
    this._stockService.getAvailableQty({"custNumber": this.data['swapData']['clientNumber'], "OTLNumber":value.OTLNumber,"partNumber":value.partNumber,"discount" : value.discount}, (response) => {
      this.partsData = this.groupBySumOfProperty(response.filter(res => res['availableQuantity'] > 0), 'partNumber', 'lotNumber','lotExpiryDate');
      // used to send a details to server request 
      if (response.length ) {
        response[0]['unitPurchasePrice'] =   response.find(part => part['unitPurchasePrice'] > 0 ) ?response.find(part => part['unitPurchasePrice'] > 0 )['unitPurchasePrice'] : 0
        this.transferPartsToDetails = response[0];
      }else {
        this.transferPartsToDetails = value;
        this.transferPartsToDetails ['unitPurchasePrice'] = value.price;
      }
      this.callingRelatedParts(value);
    })
  }
 
}

callingRelatedParts(value){
  this._stockService.getRelatedHospitalParts({custNumber : this.data['swapData']['clientNumber'], 
  partNumber :value.partNumber, siteId:this.data['swapData']['otlNumber']['site_id']}, response=> {
    this.relatedParts = response;
      let totalTransferPartsQty = response.reduce((currentAmount, control) => {
        return currentAmount + Number(control.availableQuantity)
      }, 0);
      this.stockTransferForm.get('desiredQty').setValidators([Validators.required, this._formValidator.noDecimalsValidation, this._formValidator.negativeValidation, this._formValidator.maxEqualLength(totalTransferPartsQty)]);
    // groupby response 
   let filteredResponse =  this.groupBySumOfProperty(response, 'OTLNumber', 'lotNumber','lotExpiryDate');
    this.stockTransferForm.addControl('relatedParts', this.fb.array(this.getRelatedParts(filteredResponse)));
  })

}

onpartsDataChange(value){
  this.resetParts();
  this.selected_Part = value.partNumber;
  if (value.isActive == 0){
    this._snackBar.loadSnackBar( value.partNumber + "-"  +ErrorMessage.IN_ACTIVE_PARTS , colorCodes.ERROR);
  }else{
    this._stockService.getAvailableQty({"custNumber": this.data['swapData']['clientNumber'], "OTLNumber":value.OTLNumber,"partNumber":value.partNumber,"discount" : value.discount}, (response) => {
      this.partsData = this.groupBySumOfProperty(response.filter(res => res['availableQuantity'] > 0), 'partNumber', 'lotNumber','lotExpiryDate');
      // used to send a details to server request 
      if (response.length ) {
        response[0]['unitPurchasePrice'] =   response.find(part => part['unitPurchasePrice'] > 0 ) ?response.find(part => part['unitPurchasePrice'] > 0 )['unitPurchasePrice'] : 0
        this.transferPartsToDetails = response[0];
      }else {
        this.transferPartsToDetails = value;
        this.transferPartsToDetails ['unitPurchasePrice'] = value.price;
      }
     // this.callingRelatedParts(value);
    })
  }
}

groupBySumOfProperty(array, key1, key2 , key3){
let obj= {};
return array.reduce(function(currentArray, data) {
  var key = data[key1] + '-' + data[key2] + '-' + data[key3];
  if(!obj[key]) {
    obj[key] = Object.assign({}, data);
    currentArray.push(obj[key]);
  }else{
    obj[key]['availableQuantity'] =obj[key]['availableQuantity']+ data['availableQuantity']
  }   
  return currentArray;
}, [])
}

getRelatedParts(relatedParts) {
 
  const data= relatedParts.map((data) => {
    return  this.fb.group({
      custNumber: [data.custNumber],
      name: [data.name],
      address: [data.address],
      state: [data.state],
      city: [data.city],
      pincode: [data.pincode],
      OTLNumber: [data.OTLNumber],
      partNumber: [data.partNumber],
      lotNumber: [data.lotNumber],
      lotExpiryDate: [data.lotExpiryDate],
      originalQuantity: [data.originalQuantity],
      secondarySalesQuantity: [data.secondarySalesQuantity],
      availableQuantity: [data.availableQuantity],
      siteId: [data.siteId],
      unitPurchasePrice: [data.unitPurchasePrice],
      cpNumber: [data.cpNumber],
      origin: [data.origin],
      purchaseRowIdentifier: [data.purchaseRowIdentifier],
      region: [data.region],
      id: [data.id],
      description: [data.description],
      price : [data.unitPurchasePrice],
      desiredQuantityForSwapTransfer: [{ value: '', disabled: true }, [this._formValidator.maxEqualLength(data.availableQuantity), this._formValidator.noDecimalsValidation]]
    });
  });
  return data;
 
}

transferPartsValidation(group: FormGroup): ValidationErrors | null {
  if (group.controls['desiredQty'].value && group.controls.relatedParts) {
    let arrayControls = group.controls.relatedParts as FormArray;
    let totaldesiredQtySwapTransfer = arrayControls.controls.reduce((currentAmount, control) => {
      return currentAmount + Number(control.value.desiredQuantityForSwapTransfer)
    }, 0);

    if (totaldesiredQtySwapTransfer && group.controls['desiredQty'].value < totaldesiredQtySwapTransfer) return { 'swapDesiredQuantity': true }
  }
  return null;
}


cancel() {
 // this._stockService.navigateTo();
 this.dialogRef.close({datavalue:this.data, iscancel:'cancel'});
}

getTotalDesiredQtyTransfer() {
  let data = this.stockTransferForm.value.relatedParts && this.stockTransferForm.value.relatedParts.reduce((currentAmount, control) => {
    return currentAmount + Number(control.desiredQuantityForSwapTransfer)
  }, 0);
  return data
}

resetParts(){
  this.partsData = [];
  this.stockTransferForm.get('desiredQty').setValue('');
 // if (this.stockTransferForm.get('relatedParts')) this.stockTransferForm.removeControl('relatedParts');
  if(!this.partNumberLength){
    
    if (this.stockTransferForm.get('relatedParts')) this.stockTransferForm.removeControl('relatedParts');
  }
}

resetStock() {

  if(!this.partNumberLength){
    this.stockTransferForm.get('partNumber').reset();
    this.stockTransferForm.get('type').setValue('Temporary')
    this.resetParts();
  }else{
    this.stockTransferForm.get('type').setValue('Temporary')
    this.resetParts();
    if(this.stockTransferForm.get('relatedParts').value['length']){
     this.stockTransferForm.get('relatedParts')['controls'][0]['controls']['desiredQuantityForSwapTransfer'].setValue('')
    this.onpartsChange(this.resetpart_number);
    }else{
      this.onpartsDataChange(this.resetpart_number);
    }
  }

 // this.stockTransferForm.reset();
  
}

viewStockTransferDetails(value) {
    this.viewStock = value;
 // this.dialogRef.close(this.data);
}

createStockTransfer() {
    let data ={};
    data['from_customer'] = {};
    data['from_customer']['totalAvailableQuantity']  = this.partsData.reduce((currentQty, qty) => { 
      return currentQty + Number(qty.availableQuantity)
    },0);
    let fromData = Object.assign({}, this.transferPartsToDetails);
    data['from_customer']['type'] =  this.stockTransferForm.get('type').value
    data['from_customer']['partType'] =  this.stockTransferForm.get('partNumber').value.type ?  this.stockTransferForm.get('partNumber').value.type : 'OTL'
    data['from_customer']['desiredQuantityForSwapTransfer'] = Number(this.stockTransferForm.get('desiredQty').value);
    fromData['id'] =0
    fromData['custNumber'] = this.stockTransferForm.get('fromCustNumber').value
    fromData['siteId'] = this.stockTransferForm.get('fromSiteId').value
    fromData['lotExpiryDate'] = ''
    fromData['lotNumber'] =''
    fromData['originalQuantity'] = 0
    fromData['secondarySalesQuantity'] = 0
    fromData['availableQuantity'] =0
    fromData['cpNumber'] = this.stockTransferForm.get('fromOtl').value.cpnumber 
    fromData['origin']  = 'OPF'
    fromData['desiredQuantityForSwapTransfer']  = 0
    fromData['purchaseRowIdentifier']  = ''
    data['from_customer']['data'] =  [fromData];
    let array =[];
    this.stockTransferForm.value.relatedParts.map((data) => {
      let  obj = {};
      if (data['desiredQuantityForSwapTransfer'] > 0){
         obj['desiredQuantityForSwapTransfer'] = data['desiredQuantityForSwapTransfer'];
         obj['data'] =  this.relatedParts.filter(response => {
           if(response['OTLNumber'] == data['OTLNumber'] && response['lotNumber'] == data['lotNumber'] && response['lotExpiryDate'] == data['lotExpiryDate']){
             delete response['unitPurchasePrice'];
           response['desiredQuantityForSwapTransfer'] = 0;
            return response;
           }
          });
         array.push(obj);
      }
    });
    data['with_customers']  =array;
    if(this.data['isSecondaryScreen']){
      data['origin'] = 'INV'
    }
    if(this.data['isDcScreen']){
      data['origin'] ="DC"
    }
 this._stockService.createStockTransferonSameScreen(data, (response=>{
   if(response){
     this.dialogRef.close({datavalue:this.data, isSwap:'swap'});
   }
 }));
 
}
}
@Component({
  selector: 'back-to-parts-dialog',
templateUrl: 'back-to-parts-dialog.html',
styles: [`.mat-dialog-container{
  padding : 0 !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
.bgWarning{
background : #f3e4e4

}
.otlBg {
background: #F8F8F8;
}
.transfer-icon {
font-size: 95px;
height: auto;
width: auto;
color: #d0d0d0;
}
small {
color:#888;
}
.cdk-global-overlay-wrapper {
display: flex;
position: absolute;
z-index: 1000;
overflow: auto;
pointer-events: auto;  
}`],
encapsulation: ViewEncapsulation.None

})

export class BackTopartsDialog  implements OnInit{

  public relatedPartsActive: boolean = false;
  public relatedPartsList;
  public partsForm: FormGroup;
  public maxlength= 0;
  public displayPartsKeys = ['partNumber','description'];
  public partsList = [];
  public editDesc = true;
  public currentParts = {}; // for description  
  public getPartsList = []; //  groupby parts with lotnumber and expiry date
  public currentPartsResponse = [] // storing for bulk parts response
  public otlList: any[];
  public otlNumber: any;
  public site_id: any;
  public cpNumber: any;
  public custNumber: any;
  public partNumber: any;
  public stockLogPermission;
  public isstockshow: boolean = false;
  public isADD: any;
  public isEdit: any;
  public Logincpnumber: any;
  public isTransfer: boolean =false;
  public role:any;
  public isChannel_Partner: boolean= false;
  public selected_otl: any;
  public isAdmin: boolean = false;
  constructor(public dialogRef: MatDialogRef<BackTopartsDialog>, private fb: FormBuilder, private _bookingService: CpbookingService, private _formValidator: FormValidatorService ,private _secondarysalesService :SecondarysalesService, 
   private _router: Router,private _FormValidatorService : FormValidatorService,  public _secondarySalesService: SecondarysalesService,
    private _snackBar :SnackbarService,private _StorageService: StorageService, private _storageService: StorageService,
  private _stockService:StockService,  @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(){
    this.currentPartsResponse = [];
    this.isADD=  this.data['isADD'];
    this.isEdit = this.data['isEdit'];
    this.role = this._storageService.getUserDetails()['role'];
      this.isAdmin = this.role ==Roles.Admin;
      if(this.isAdmin){
        this.Logincpnumber = this.data['cpnumber'];
      }else{
    this.Logincpnumber = this.data['LogincpNumber'];
      }
   this.loadStockLogPermission();
    if(this.data['partsData']){
      this.otlNumber = this.data['partsData']['otlNumber']['OTLnumber'];
      this.site_id = this.data['partsData']['siteId'];
      this.cpNumber = this.data['partsData']['otlNumber']['cpnumber'];
      this.custNumber = this.data['partsData']['otlNumber']['custNumber'];
      this.partNumber = this.data['partsData']['partNumber']['partNumber'];
      this.selected_otl = this.otlNumber;
      this._bookingService.getOtlBySiteId({ "custNumber":this.data['partsData']['clientNumber'], "order_by":"otl_number" }, (response) => { 
        this.otlList = response;
      });
      // this._secondarySalesService.getStock({ "OTLNumber": this.otlNumber,"cpnumber" :  this.cpNumber, "siteId":  this.site_id ,'custNumber' : this.custNumber, 'group_by' : 1}, (response) => {
      //   this.partsList =response;
      //    })
      this._bookingService.getOtlPartsOpf({OTLNumber: this.otlNumber,  "order_by": "name"},(response) =>{
        this.partsList =response;    
       if(this.partsList){
         this._secondarySalesService.getCpStockAvailable({"cpnumber" : this.Logincpnumber,'custNumber' : this.custNumber, "OTLNumber": this.otlNumber},response=>{
           if(response.success){

           }else{
             this._snackBar.loadSnackBar(SuccessMessage.STOCK_CHECK, colorCodes.ERROR);
           }
         })
       }
 });
     
        this._secondarySalesService.getStock({ "OTLNumber": this.otlNumber,"cpnumber" : this.cpNumber, 
        "siteId": this.data.site_id ,'custNumber' : this.site_id,'partNumber': this.partNumber}, (response) => {
          let otlTypeParts = response.filter(res=> {
          if((res.availableQuantity > 0 && res.unitPurchasePrice >0)){
            res['type'] = 'Value';
            return res;
          }
        });
        let focTypeParts = response.filter(res=> {
          if(res.availableQuantity > 0 && res.unitPurchasePrice ==0) {
            res['price'] = 0;
            res['type'] = 'FoC';
            return res;
          } });
          this.currentPartsResponse = otlTypeParts.concat(focTypeParts);
          if(this.currentPartsResponse && this.currentPartsResponse.length){
    
            this.isstockshow = false
           }
           else{
            
             this.isstockshow = true;
           }
          this.getPartsList = this.groupBySumOfProperty(otlTypeParts,'lotNumber', 'lotExpiryDate').concat(this.groupBySumOfProperty(focTypeParts,'lotNumber', 'lotExpiryDate'));

          if (this.getPartsList.length) {
            this.partsForm.addControl('groupByParts',this.fb.array(this.getPartsListArray()));
          }
  });
  this.loadPartsForm();
  this.onloadotlandPartsVaule(this.data);
    }
 }

 editDescription(){
  this.editDesc = !this.editDesc
}


loadPartsForm() {
  this.partsForm = this.fb.group({
    partsList: ['', [Validators.required,this._FormValidatorService.requireMatch]],
    description :  ['',Validators.required],
    OTLNumber: ['', [Validators.required, this._FormValidatorService.requireMatch]],
    edited_quantity : ['']
  },  { validator: this.partsFormValidation})
}
onloadotlandPartsVaule(data: any){
  this.partsForm.patchValue({
    partsList:this.data['partsData']['partNumber'],
    OTLNumber: this.data['partsData']['otlNumber'],
    description: this.data['partsData']['partNumber']['description']
  })
}

onOtlChange(value) {
  this.selected_otl = value.OTLnumber;
  this.role = this._storageService.getUserDetails()['role'];
  this.isChannel_Partner = this.role ==Roles.Channel_Partner;
  if (value) {
    if(this.isChannel_Partner){
   this.isTransfer = this.Logincpnumber != value['cpnumber'] ? true: false;
    }else{
      this.isTransfer = false;
    }
  }
    this.partsForm.get('partsList').setValue('');
    this.data.partsOutput['editable'] = false;
    this.onPartsChange('')
  if (value) {
      this._bookingService.getOtlPartsOpf({OTLNumber: value.OTLnumber,  "order_by": "name"},(response) =>{
        this.partsList =response;    
       if(this.partsList){
         this._secondarySalesService.getCpStockAvailable({"cpnumber" : this.data.LogincpNumber,'custNumber' : value.custNumber, "OTLNumber": value.OTLnumber},response=>{
           if(response.success){

           }else{
             this._snackBar.loadSnackBar(SuccessMessage.STOCK_CHECK, colorCodes.ERROR);
           }
         })
       }
 });
      this.partsForm.get('partsList').enable();
  }else this.partsForm.get('partsList').disable()

  if (this.partsForm.get('groupByParts'))this.partsForm.removeControl('groupByParts');
 
 this.partsForm.get('partsList').updateValueAndValidity();

}

onPartsChange(value) {
   this.currentPartsResponse =[];
  if (value){
    this.partsForm.get('description').setValue(value.cpDescription ? value.cpDescription : value.description);

    if (this.partsForm.get('groupByParts'))this.partsForm.removeControl('groupByParts');
    //setting current value for parts 
    this.currentParts = value
    this._secondarySalesService.getStock({ "OTLNumber": value.OTLNumber,"cpnumber" : this.data.cpnumber, 
    "siteId": this.data.site_id ,'custNumber' : value.custNumber,'partNumber': value.partNumber}, (response) => {
      //differentiate btwn OTL and FOC parts type 
      let otlTypeParts = response.filter(res=> {
        if((res.availableQuantity > 0 && res.unitPurchasePrice >0)){
          res['type'] = 'OTL';
          return res;
        }
      });
      let focTypeParts = response.filter(res=> {
        if(res.availableQuantity > 0 && res.unitPurchasePrice ==0) {
          res['price'] = 0;
          res['type'] = 'FoC';
          return res;
        } });
      
      this.currentPartsResponse = otlTypeParts.concat(focTypeParts);
      if(this.currentPartsResponse && this.currentPartsResponse.length){
    
        this.isstockshow = false
       }
       else{
        
         this.isstockshow = true;
       }
    
      this.getPartsList = this.groupBySumOfProperty(otlTypeParts,'lotNumber', 'lotExpiryDate').concat(this.groupBySumOfProperty(focTypeParts,'lotNumber', 'lotExpiryDate'));
      if (this.data.dcEdit) {
        this.getPartsList =  !this.data.currentPartsData.length ? this.getPartsList : this.getPartsList.filter(otlPart => !this.data.currentPartsData.find(part =>( ( otlPart['OTLNumber'] === part['OTLNumber'] )&& (otlPart['partNumber'] === part['partNumber']) && (part['type'] == otlPart['type'] ) && (part['lotNumber'] == otlPart['lotNumber'])))); 
      }
      else if(this.data.editForm) {
        this.getPartsList =  !this.data.currentPartsData.length ? this.getPartsList : this.getPartsList.filter(otlPart => !this.data.currentPartsData.find(part =>( !part['dcNumber'] &&( otlPart['OTLNumber'] === part['OTLNumber'] )&& (otlPart['partNumber'] === part['partNumber']) && (part['type'] == otlPart['type'] ) && (part['lotNumber'] == otlPart['lotNumber']))));
      }else {
        if (this.data.currentPartsData.length) {
          this.getPartsList = this.getPartsList.map(part => {
           let currValue =  this.data.currentPartsData.find(currPart => ( currPart['OTLNumber'] === part['OTLNumber'] )&& (currPart['partNumber'] === part['partNumber']) && (part['type'] == currPart['type'] ) && (part['lotNumber'] == currPart['lotNumber']))
            if (currValue && !currValue ['dcNumber']) {
              part['quantity'] = currValue['quantity']
            }
           return part; 
          }) 
        }

      }
      
      if (this.getPartsList.length) {
        this.partsForm.addControl('groupByParts',this.fb.array(this.getPartsListArray()));
      }
    });
  }
}

checkPartsQuantity(){
  if ( typeof this.data.partsOutput['originalQuantity'] != 'undefined' ||  this.data.partsOutput['newParts']) return true;
   let getQty=  this.partsForm.get('groupByParts') && this.partsForm.get('groupByParts').value.find(res => res['quantity'] > 0);
   return getQty ? true : false;
}




getPartsListArray() {
  return this.getPartsList.map((data) => {
    return this.fb.group({
      availableQty : [data.availableQuantity],
      HSSNcode: [data.HSSNcode],
      quantity : [ data.quantity , [this._FormValidatorService.maxEqualLength(data.availableQuantity), this._FormValidatorService.negativeValidation,this._FormValidatorService.noDecimalsValidation]],
      lotNumber :[data.lotNumber],
      lotExpirationDate : [data.lotExpirationDate ? data.lotExpirationDate : data.lotExpiryDate],
      HSSNtax : [data.HSSNtax],
      partNumber :[data.partNumber],
      price:[data.price],
      type:[data.type ],
      OTLNumber : [data.OTLNumber],
      description: [data.cpDescription ? data.cpDescription : data.description],
      productLine :[data.productLine],
      newParts : [data.newParts ? data.newParts : false ]
    });
  });
}

groupBySumOfProperty(array, key1, key2){
  let obj= {};
  return array.reduce(function(currentArray, data) {
    var key = data[key1] + '-' + data[key2] 
    if(!obj[key]) {
      obj[key] = Object.assign({}, data);
      currentArray.push(obj[key]);
    }else{
      obj[key]['availableQuantity'] =obj[key]['availableQuantity']+ data['availableQuantity']
    }   
    return currentArray;
  }, [])
}



setPartsList(value) {
  this.data.partsOutput['editable'] = false
  this.data.partsOutput['OTLNumber'] = value.OTLNumber
  this.data.partsOutput['partNumber'] = value.partNumber;
  this.partsForm.get('OTLNumber').clearValidators();
  this.partsForm.get('partsList').clearValidators();
  this.partsForm.get('description').clearValidators();
  // for Already raised invoice edit 
  if ( typeof value.originalQuantity != 'undefined'){
    if (this.data.dcEdit){
      this.data.partsOutput['invoicedQuantity'] = value.invoicedQuantity;
      this.partsForm.get('edited_quantity').setValidators([ Validators.required ,this._FormValidatorService.noDecimalsValidation, this._FormValidatorService.maxEqualLength(value.availableQuantity + value.quantity),this._FormValidatorService.minNumberValidation(value.invoicedQuantity)  ])
    }else {
      this.partsForm.get('edited_quantity').setValidators([ Validators.required ,this._FormValidatorService.noDecimalsValidation,this._FormValidatorService.minNumberValidation(value.creditQuantity, 'withZero'), this._FormValidatorService.maxEqualLength(value.availableQuantity + value.quantity) ])
    }
    this.partsForm.get('edited_quantity').setValue(value.edited_quantity)
    this.data.partsOutput['quantity'] = value.quantity;
    this.data.partsOutput['originalQuantity'] = value.originalQuantity;
    this.data.partsOutput['productLine'] = value.part[0].productLine;
    this.data.partsOutput['lotNumber'] = value.lotNumber;
    this.data.partsOutput['lotExpiryDate'] = value.lotExpiryDate 
    this.data.partsOutput['HSSNcode'] = value.HSSNcode;
    this.data.partsOutput['HSSNtax'] = value.HSSNtax;
    this.data.partsOutput['description'] = value.description;
    this.data.partsOutput['type'] = value.type;
    this.data.partsOutput['newParts'] = false;
    this.getEditedQtyTax();
    this.data.partsOutput['price'] = value.price;
    this.data.partsOutput['availableQty'] = value.availableQuantity;
    this.partsForm.get('description').setValue(value.cpDescription ? value.cpDescription : value.description);
  }else{
    // newely raised invoice edit 
   this.currentPartsResponse =  value['groupedData'] 
  this.getPartsList = [value];
  this.data.partsOutput['newParts'] = true;
   this.partsForm.addControl('groupByParts',this.fb.array(this.getPartsListArray()));
   this.partsForm.get('description').setValue(value.description);
   this.data.partsOutput['description'] = value.description;
  }
 
}
getEditedQtyTax(){
  let value = this.data.partsOutput  ;
  this.data.partsOutput['edited_quantity'] = this.partsForm.get('edited_quantity').value;
  this.data.partsOutput['total_amount'] = this.RoundOFTwoDigit(Number((value.price * this.partsForm.get('edited_quantity').value)));
  this.data.partsOutput['cgst'] = this.partsForm.get('edited_quantity').value > 0 ? this.RoundOFTwoDigit(Number((value['total_amount']*(value.HSSNtax/2))/100)): 0; 
  this.data.partsOutput['sgst'] = this.partsForm.get('edited_quantity').value > 0 ? this.RoundOFTwoDigit(Number((value['total_amount']*(value.HSSNtax/2))/100)): 0; 
  this.data.partsOutput['igst'] = this.partsForm.get('edited_quantity').value > 0 ? this.RoundOFTwoDigit(Number((value['total_amount']*value.HSSNtax)/100)): 0; 
}

partsFormValidation(group: FormGroup):   ValidationErrors | null  {
  let quantityValue;
  if (group.controls.relatedParts){
    let arrayControls = group.controls.relatedParts as FormArray;
     quantityValue = arrayControls.controls.find(control => control.value.quantity && control.value.quantity > 0);
  }
    // if (group.controls.relatedParts && !quantityValue && group.controls['quantity'].value == 0)return {'requiredQuantity' : true} 
    // else if (group.controls['quantity'].value == 0 && !group.controls.relatedParts) return {'requiredQuantity' : true} 
  return null;
}

addParts(data): void { 
  // edit description have to update in OTL parts 
  if ( data && this.data.partsOutput['editable']) {
    let payload = {};
    payload['description'] = this.partsForm.get('description').value
    this._secondarySalesService.editDescription(this.currentParts['otlPartId'], payload);
  }

  // groupBy parts details 
  if ((data && !this.data.editParts &&  this.partsForm.get('groupByParts').value )||  (this.data.editParts.newParts)){
    data['groupByParts'] = this.partsForm.value.groupByParts.filter((part)=>{
      if (part['quantity'] > 0){
        part['description'] = this.partsForm.get('description').value
        part['total_amount'] = this.RoundOFTwoDigit(Number(part.price *  part['quantity']));    
        part['cgst'] = this.RoundOFTwoDigit(Number((part['total_amount']*(part.HSSNtax/2))/100)); 
        part['sgst'] =this.RoundOFTwoDigit(Number((part['total_amount']*(part.HSSNtax/2))/100));
        part['igst'] =  this.RoundOFTwoDigit(Number((part['total_amount']*part.HSSNtax)/100));
        part['groupedData'] =  this.currentPartsResponse.filter(res => (res['partNumber'] == part['partNumber'] && res['lotNumber'] == part['lotNumber'] && res['lotExpiryDate'] == part['lotExpirationDate'] && res['type'] == part['type'] ));
        return part
      }
    });
  }else if (this.data.editParts && !this.data.editParts.newParts) {
      this.getEditedQtyTax();
    data = {};
    data['groupByParts'] = [];
    
    this.data.partsOutput['part'] = this.data.editParts['part']
    data['groupByParts'].push(this.data.partsOutput)
  }
  this.dialogRef.close(data);
  }
  dialogClose(){
    this.dialogRef.close();
  }
swapStockTransfer(data: any, isswap: any){
  this._bookingService.validateOtl({"site_id": this.data['site_id'],"OTLNumber" : this.selected_otl,"screen" : "stock_transfer"}, (response)=>{

    if(response.success){
      this.dialogRef.close({data, isswap});
    }
  })
 }

loadStockLogPermission(){
this._bookingService.getActionPermission({model : 'stocklog'}, response =>{
  this.stockLogPermission= response['stocklog'];
      });
    }
setActionsPermission(name){
  return this.stockLogPermission && typeof this.stockLogPermission[ActionItems[name]] != 'undefined'  ?  true : false;
 }
 RoundOFTwoDigit(num: any){
  var number = Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
  return number;
}
}


@Component({
  selector: 'cprt-billing',
  templateUrl: 'cprt-billing.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
.mat-checkbox-layout{
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
.mat-checkbox-checked .mat-checkbox-background{
  background-color :#E6313A !important
}
.mat-menu-item:hover:not([disabled]) {
  background-color :#E6313A !important
  }
  .mat-menu-item.cdk-keyboard-focused:not([disabled]) {
    background-color :#E6313A !important
  }
  .mat-menu-item .mat-icon-no-color{
    color: #F7F7F7 !important
  }
  .error{
    color:#E6313A;
  }
  ` 

],
  encapsulation: ViewEncapsulation.None
})

export class CPRTBILLING implements OnInit{

  public cprt_form : FormGroup;
  public submitted: boolean = false;
  constructor(public _fb: FormBuilder, public dialogRef: MatDialogRef<CPRTBILLING>,  @Inject(MAT_DIALOG_DATA) public data, 
  private _formValidator: FormValidatorService){}

  ngOnInit() {
    this.cprt_form = this._fb.group({
      Rows: this._fb.array([this.listofcolumn()])
    })
      
  }

  get formArr(){
    return this.cprt_form.get("Rows") as FormArray;
  }
 listofcolumn() {
   return this._fb.group({
     test_name : ['', [Validators.required, this._formValidator.cannotContainSpace]],
     hsn: ['',[Validators.required, Validators.min(1), this._formValidator.hsnmaxlengthValidation, this._formValidator.hsncannotContainSpace]],
     no_of_tests:['', [Validators.required, Validators.min(1), this._formValidator.noDecimalsValidation]],
     cost_per_test:['', [Validators.required, Validators.min(0)]]
   })
 }
 addNewRows(){
   this.formArr.push(this.listofcolumn());
 }

 deleteRow(index: number){
  this.formArr.removeAt(index)
 }
 dialogClose(){
  this.dialogRef.close({'CPRT':false});
 }

 submit(){
   this.submitted = true;
   if(this.cprt_form.invalid){
     return
   }
   let value = this.cprt_form.value['Rows'];
   this.dialogRef.close({value, 'CPRT':true});

 }
}


@Component({
  selector: 'edit_cprt_billing',
  templateUrl: 'edit_cprt_billing.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
.mat-checkbox-layout{
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
.mat-checkbox-checked .mat-checkbox-background{
  background-color :#E6313A !important
}
.mat-menu-item:hover:not([disabled]) {
  background-color :#E6313A !important
  }
  .mat-menu-item .mat-icon-no-color{
    color: #F7F7F7 !important
  }
  .error{
    color:#E6313A;
  }
  ` 

],
  encapsulation: ViewEncapsulation.None
})
export class EditCPRT implements OnInit{

    public editcprt_form : FormGroup;
    public submitted: boolean = false;
    public viewInvoice = false;
    public accessControls = [];    
    public role;
  constructor(public dialogRef: MatDialogRef<EditCPRT>, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data, private _formValidator: FormValidatorService,
  private _bookingService: CpbookingService, private _secondarySalesService: SecondarysalesService, private _storageService: StorageService){}
  ngOnInit() {
    this.getInvoicePermissionAccessControl();
if(this.data){
      this.loadEditCPRTForm();
    }
    this.role = this._storageService.getUserDetails()['role'];
      
  }
  loadEditCPRTForm(){
    this.editcprt_form = this.fb.group({
      desc:[this.data['parts']['test_name'], [Validators.required, this._formValidator.cannotContainSpace]],
      hsn:[this.data['parts']['HSSNcode'], [Validators.required, Validators.min(1), this._formValidator.noDecimalsValidation ]],
      no_of_tests: [this.data['parts']['quantity'], [Validators.required, Validators.min(1), this._formValidator.noDecimalsValidation ]],
      cost_per_tests:[this.data['parts']['price'], [Validators.required, Validators.min(0)]],
    })
  }
  get f() { return this.editcprt_form.controls; }

  onSubmit(){
      this.submitted = true;
      if(this.editcprt_form.invalid){
        return;
      }
     let value = this.editcprt_form.getRawValue();
      this.dialogRef.close(value)
  }

  dialogClose(){
    this.dialogRef.close();
  }
  cancel(){
    this._secondarySalesService.navigateInvoiceList()
   }
  getInvoicePermissionAccessControl(){
        this._bookingService.getPermissionAccessControls({module : 'Invoice_Edit'}, response =>{
        this.accessControls = response.field_permission;
        if (!response.parent_permission[0].is_allowed  &&  this.role != Roles.Admin)this.cancel();
        if (this.role == Roles.Channel_Partner &&   response.field_permission.length){
          this.getEditInvoiceAccessControl();
        }
       
    })
  }

  getEditInvoiceAccessControl(){
      this.accessControls.map((editControl) => {
      if (typeof this.editcprt_form.controls[editControl.name] !== 'undefined'){
       
         if ( editControl['is_allowed'] ) {
          this.editcprt_form.controls[editControl.name].enable();
         }
         else this.editcprt_form.controls[editControl.name].disable();
       }
           else if (editControl.name == 'hssn_code'){ 
         
        if (editControl['is_allowed'])  this.editcprt_form.get('hsn').enable();
        else this.editcprt_form.get('hsn').disable();
      }
      else if (editControl.name == 'test_name'){ 
         
        if (editControl['is_allowed'])  this.editcprt_form.get('desc').enable();
        else this.editcprt_form.get('desc').disable();
      }
      else if (editControl.name == 'cost_per_test'){ 
         
        if (editControl['is_allowed'])  this.editcprt_form.get('cost_per_tests').enable();
        else this.editcprt_form.get('cost_per_tests').disable();
      }
     });
  }



}

@Component({
  selector: 'edit_status',
  templateUrl: 'edit_status.html',
  styles: [`.mat-dialog-container {padding : 0 !important;margin-top: 20px;margin-bottom: 20px;
    overflow-x : hidden;
    overflow-y : auto;
  }.pointer:hover {cursor: pointer} .pointer{margin-left: -12px;}.bg-green{background:green !important;}
  thead  th {
    background-color: rgba(94, 88, 88, 0.15);
  }
  tr:nth-child(even) {
    background-color: rgba(174, 174, 174, 0.15);
}
  ` 

],
  encapsulation: ViewEncapsulation.None
})
export class EditStatus implements OnInit {
  status_form: FormGroup;
  status_list: any;
  selected_status: any;
  public view_data: boolean = false;
  constructor(public dialogRef: MatDialogRef<EditStatus>, public _fb : FormBuilder, 
    public _secondarySalesService: SecondarysalesService,private _formValidator: FormValidatorService,
     @Inject(MAT_DIALOG_DATA) public data){}
  ngOnInit() {
    this._secondarySalesService.getstatus((response)=>{
    this.status_list = response;
   if(response){
    this.loadStatus();
    }   
    })
 }
  loadStatus(){
    this.status_form = this._fb.group({
      status:['', [Validators.required, this._formValidator.requireMatch]]
  })
  }
  onStatusChange(event: any){
    this.selected_status = event;
  }
  dialogClose(){
    this.dialogRef.close();
  }
  viewData(status){
    this.view_data = status;
  //  this.status_form.get('status').setValue(this.status_form.get('status').value['Name'])
  
  }
  onSubmit(){
    this.dialogRef.close(this.selected_status);
  }
}
