import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { Endpoints, ApiMethod, colorCodes, SuccessMessage, ErrorMessage } from 'src/app/core/services/constants';
import { Router } from '@angular/router';
import { Roles } from '../../auth/model/user';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { LoaderService } from 'src/app/core/services/utils/loader.service';
@Injectable({
  providedIn: 'root'
})
export class SecondarysalesService {

  constructor(private _http:HttpService,private _StorageService :StorageService , private _loaderService:LoaderService ,private _snackBar: SnackbarService,private _router: Router) { }

  listInvoices(payload, successResponseCallback, errorResponseCallback) {

    this._http.requestCall(Endpoints.CP_INVOICE, ApiMethod.GETPARAMS, payload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        errorResponseCallback(error);
      });
      
  }
  searchClientInvoice(Payload, successResponseCallback){

    this._http.requestCall(Endpoints.CP_INVOICE, ApiMethod.GETPARAMS, Payload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }
  getissuanceDeatails(successCallBack){
    let issuanceBasedValue = [
      {id: 1, value:'quantity', Name:'Quantity'},
      {id: 2, value:'value_invoice', Name:'Value-Invoice'},
      {id: 3, value:'value', Name:'Value'},
      {id: 4, value:'value_parts', Name:'Value-Parts'}
    ]
    successCallBack(issuanceBasedValue);
  }
  getDebitissuanceDeatails(successCallBack){
    let issuanceBasedValue = [
     
      {id: 2, value:'value_invoice', Name:'Value-Invoice'},
      {id: 3, value:'value', Name:'Value'},
      {id: 4, value:'value_parts', Name:'Value-Parts'}
    ]
    successCallBack(issuanceBasedValue);
  }
  getType(successCallBack){
      let Typelist = [
        {id:1, value:'debit', Name:'Debit'},
        {id:2, value:'credit', Name:'Credit'}
      ]
      successCallBack(Typelist);
  }
  getstatus(SuccessCallBack){
    let StatusList =[
     
      {id:0, value:'not_invoiced', Name: 'Not Invoiced'},
      {id:1, value:'partially_invoiced', Name:'Partially Invoiced'},
      {id:2, value:'invoiced', Name:'Invoiced'}
    ]
    SuccessCallBack(StatusList);
  }
  getInvoiceType(SuccessCallBack){
    let invoice_type_list = [
      {id:1, value:'cprt', Name:'CPRT'},
      {id:2, value:'invoice', Name:'Invoice'}
    ]
    SuccessCallBack(invoice_type_list);
  }
  getIsCPRT(successCallBack){
    let is_cprt =[
      {id:1, value:'true', Name:'CPRT DC'},
      {id:2, value:'false', Name:'DC'}
    ]
    successCallBack(is_cprt);
  }
  getCustomerAddress(payload,successCallBack) {
    this._http.requestCall(Endpoints.GET_CUSTOMER_ADDRESS, ApiMethod.GETPARAMS,payload).subscribe(
      (res) => {
        successCallBack(res);
      });
  }

 
  getCpSiteAddress(payload, successCallBack){
    this._http.requestCall(Endpoints.CP_SITE_ID, ApiMethod.GETPARAMS,payload).subscribe(
      (res) => {
        successCallBack(res);
      });
  }


  getAddressList(payload,successResponseCallback) { 
    this._http.requestCall(Endpoints.GET_HOSPITAL_ADDRESS, ApiMethod.GETPARAMS,payload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  } 

  getDistinctHospitals( successCallback) {
    this._http.requestCall(Endpoints.GET_DISTINCT_HOSPITAL, ApiMethod.GET).subscribe(
      (res) => { successCallback(res);},
      (error) => { console.log(error) }
    )
  }

  addShippingAddress(addressPayload, successResponseCallback,type?:any,) {
    this._http.requestCall(Endpoints.GET_HOSPITAL_ADDRESS, ApiMethod.POST, addressPayload).subscribe(
      (res) => { 
        successResponseCallback(res);
        this._snackBar.loadSnackBar(type ? SuccessMessage.SHIPPING_ADDRESS_EDIT : SuccessMessage.SHIPPING_ADDRESS_ADD, colorCodes.SUCCESS);
       },
      (error) => { 
        console.log(error); 
      });
  }

  getStock(payload, successResponseCallback) {
    this._http.requestCall(Endpoints.GET_STOCK, ApiMethod.GETPARAMS, payload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      })
  }

  addInvoice(payload, successCallBack){
    this._http.requestCall(Endpoints.CP_INVOICE, ApiMethod.POST, payload).subscribe(
      (res) => {   
        successCallBack(res);
        this._snackBar.loadSnackBar(SuccessMessage.INVOICE_ADD, colorCodes.SUCCESS);
       },
      (error) => { 
        console.log(error); 
      }
    )
  }

  navigateInvoiceList() {
    let roles = this._StorageService.getUserDetails().role;
    if (roles == Roles.Admin)   this._router.navigate(['beckman/secondary-sales/invoice']);
    else if (roles == Roles.Channel_Partner)   this._router.navigate(['channel-partner/secondary-sales/invoice']);
     else if (roles == Roles.Agent)   this._router.navigate(['agent/secondary-sales/invoice']);
    else this._router.navigate(['beckman-billing/secondary-sales/invoice']);
  }
  navigateDCList() {
    let roles = this._StorageService.getUserDetails().role;
    if (roles == Roles.Admin)   this._router.navigate(['beckman/secondary-sales/delivery-challan']);
    else if (roles == Roles.Channel_Partner)   this._router.navigate(['channel-partner/secondary-sales/delivery-challan']);
     else if (roles == Roles.Agent)   this._router.navigate(['agent/secondary-sales/delivery-challan']);
    else this._router.navigate(['beckman-billing/secondary-sales/delivery-challan']);
  }

  navigateDCNoteList() {
   let roles = this._StorageService.getUserDetails().role;
   if (roles == Roles.Admin)   this._router.navigate(['beckman/secondary-sales/credit']);
   else if (roles == Roles.Channel_Partner)   this._router.navigate(['channel-partner/secondary-sales/credit']);
    else if (roles == Roles.Agent)   this._router.navigate(['agent/secondary-sales/credit']);
   else this._router.navigate(['beckman-billing/secondary-sales/credit']);
  }
  

  viewInvoice(invoiceId, successCallBack, errorCallback){
    Endpoints['VIEW_CP_INVOICE'] = `/cp/invoice/${invoiceId.toString()}`;
    this._http.requestCall(Endpoints['VIEW_CP_INVOICE'], ApiMethod.GET).subscribe(
      (res) => {
        successCallBack(res);
      },
      (error) => {
        errorCallback(error);
      });
  }
  editInvoice(invoiceId,invoicePayload){
    Endpoints['EDIT_CP_INVOICE'] = `/cp/invoice/${invoiceId.toString()}`;
    this._http.requestCall(Endpoints['EDIT_CP_INVOICE'], ApiMethod.PUT, invoicePayload).subscribe(
      (res) => {
       this.navigateInvoiceList();
        this._snackBar.loadSnackBar(SuccessMessage.INVOICE_EDIT, colorCodes.SUCCESS);
      },
      (error) => {
        console.log(error);
      });
  }
  editInvoiceParts(partsId, partsPayload, successResponseCallback) {
    Endpoints['EDIT_INVOICE_PARTS'] = `/cp/invoice/parts/${partsId.toString()}`;
    this._http.requestCall(Endpoints['EDIT_INVOICE_PARTS'], ApiMethod.PUT, partsPayload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error);
      });
  }

  deleteInvoiceParts(partsId,successResponseCallback){
    Endpoints['DELETE_INVOICE_PARTS'] = `/cp/invoice/parts/${partsId.toString()}`;
    this._http.requestCall(Endpoints['DELETE_INVOICE_PARTS'], ApiMethod.DELETE).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error);
      });

  }

  exportSecondaryInvoiceFilter(invoicePayload){
    this._http.requestCall(Endpoints.EXPORT_SECONDARY_INVOICE,ApiMethod.DOWNLOAD_PARAMS,invoicePayload).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "Secondarysales-invoice.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar(SuccessMessage.SECONDARY_INVOICE_EXPORT, colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }


  //Delivery Challan

  listDeliveryChallan(payload, successResponseCallback, errorResponseCallback) {

    this._http.requestCall(Endpoints.CP_DELIVERY_CHALLAN, ApiMethod.GETPARAMS,payload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        errorResponseCallback(error);
      });
      
  }
  DClist(Payload, successResponseCallback){
    this._http.requestCall(Endpoints.CP_DELIVERY_CHALLAN, ApiMethod.GETPARAMS, Payload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }

  addDeliveryChallan(payload, successCallBack) {
    this._http.requestCall(Endpoints.CP_DELIVERY_CHALLAN, ApiMethod.POST, payload).subscribe(
      (res) => {   
        successCallBack(res);
        this._snackBar.loadSnackBar(SuccessMessage.DC_ADD, colorCodes.SUCCESS);
       },
      (error) => { 
        console.log(error); 
      }
    )

  }

 

  viewDeliveryChallan(dcId, successCallBack, errorCallback){
    Endpoints['VIEW_CP_DC'] = `/cp/dc/${dcId.toString()}`;
    this._http.requestCall(Endpoints['VIEW_CP_DC'], ApiMethod.GET).subscribe(
      (res) => {
        successCallBack(res);
      },
      (error) => {
        errorCallback(error);
      });
  }
  editDeliveryChallan(dcId,dcPayload){
    Endpoints['EDIT_DC'] = `/cp/dc/${dcId.toString()}`;
    this._http.requestCall(Endpoints['EDIT_DC'], ApiMethod.PUT, dcPayload).subscribe(
      (res) => {
        this.navigateDCList()
        this._snackBar.loadSnackBar(SuccessMessage.DC_EDIT, colorCodes.SUCCESS);
      },
      (error) => {
        console.log(error);
      });
  }
  editDcParts(partsId, partsPayload, successResponseCallback) {
    Endpoints['EDIT_DC'] = `/cp/dc/parts/${partsId.toString()}`;
    this._http.requestCall(Endpoints['EDIT_DC'], ApiMethod.PUT, partsPayload).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error);
      });
  }

  deleteDcParts(partsId,successResponseCallback){
    Endpoints['DELETE_DC'] = `/cp/dc/parts/${partsId.toString()}`;
    this._http.requestCall(Endpoints['DELETE_DC'], ApiMethod.DELETE).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error);
      });

  }

  exportDCFilter(dcPayload) {
    this._http.requestCall(Endpoints.EXPORT_DC,ApiMethod.DOWNLOAD_PARAMS,dcPayload).subscribe(
      (res) => {
        var downloadLink = document.createElement("a");
        const blob = new Blob([res], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "DeliveryChallan.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this._snackBar.loadSnackBar(SuccessMessage.DELIVERY_CHALLAN_EXPORT, colorCodes.SUCCESS);
        return false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  moveDctoInvoice(dcPayload) {
    this._http.requestCall(Endpoints.MOVE_DC_TO_INVOICE, ApiMethod.PUT, dcPayload).subscribe(
      (res) => {
        this._snackBar.loadSnackBar(SuccessMessage.DC_TO_INVOICE, colorCodes.SUCCESS);
        this.navigateDCList()
      },
      (error) => {
        console.log(error);
      });
  }

  getRelatedParts(partNumber, siteId, successCallback) {
    let payload = {"partNumber": partNumber, "siteId": siteId};
    this._http.requestCall(Endpoints.RELATED_PARTS, ApiMethod.GETPARAMS,payload).subscribe(
      (res) => {
        successCallback(res);
      },
      (error) => {
        this._snackBar.loadSnackBar(ErrorMessage.STOCK_RELATED_PARTS, colorCodes.ERROR);
        console.log(error);
      });
  }

  listStockTransfer(successResponseCallback, errorResponseCallback) {
    this._http.requestCall(Endpoints.LIST_STOCK_TRANSFER, ApiMethod.GET).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        errorResponseCallback(error);
      });
  }
  getPrintConfiguration( successResponseCallback, isInvoice?:any) {    
    this._http.requestCall(Endpoints.CP_CONFIGURATION, ApiMethod.GET).subscribe(
      (res) => {
        if (isInvoice && !res['secondaryInvoiceNamingConvention'] && !res['secondaryInvoiceRunningNumber']){
          this._snackBar.loadSnackBar(ErrorMessage.INVOICE_NAMING_CONVENTION, colorCodes.ERROR);
          this.navigateInvoiceList()
        }
        successResponseCallback(res);

      },
      (error) => {
        this._snackBar.loadSnackBar(ErrorMessage.CONFIGURATION, colorCodes.ERROR);
      });
  }


  getPrintDCConfiguration( successResponseCallback, isChallan?:any) {
    this._http.requestCall(Endpoints.CP_CONFIGURATION, ApiMethod.GET).subscribe(
      (res) => {
        if (isChallan && !res['secondaryDCNamingConvention'] && !res['secondaryDCRunningNumber']){
          this._snackBar.loadSnackBar(ErrorMessage.DC_NAMING_CONVENTION, colorCodes.ERROR);
          this.navigateDCList();
        }
        successResponseCallback(res);

      },
      (error) => {
        this._snackBar.loadSnackBar(ErrorMessage.CONFIGURATION, colorCodes.ERROR);
      });
  }




  getPrintDNoteConfiguration( successResponseCallback, isChallan?:any) {
    this._http.requestCall(Endpoints.CP_CONFIGURATION, ApiMethod.GET).subscribe(
     (res) => {
       if (
         
        (isChallan && !res['secondaryDebitNamingConvention'] && !res['secondaryDebitRunningNumber']) ||
        (!res['secondaryCreditNamingConvention'] && !res['secondaryCreditRunningNumber']) 
       
       ){
         this._snackBar.loadSnackBar(ErrorMessage.CD_NAMING_CONVENTION,  colorCodes.ERROR);
         this.navigateDCNoteList();
       }
       successResponseCallback(res);

     },
     (error) => {
       this._snackBar.loadSnackBar(ErrorMessage.CONFIGURATION, colorCodes.ERROR);
     });
 }

  // getConfiguration(payload, role ,successResponseCallback, isInvoice?:any){
  //   this._http.requestCall(Endpoints.CP_CONFIGURATION, ApiMethod.GETPARAMS, payload).subscribe( 
  //     (res) =>{
  //       if (isInvoice && !res['secondaryInvoiceNamingConvention'] && !res['secondaryInvoiceRunningNumber']){
  //         this._snackBar.loadSnackBar(ErrorMessage.INVOICE_NAMING_CONVENTION, colorCodes.ERROR);
  //        this.navigateInvoiceList()
  //       }
  //       successResponseCallback(res);

  //     }
  //   )
  // }

  updatePrintConfiguration(configPayload, successResponseCallback) {
    this._http.requestCall(Endpoints.CP_CONFIGURATION, ApiMethod.PUT, configPayload).subscribe(
      (res) => {
        successResponseCallback(res);
        this._snackBar.loadSnackBar(SuccessMessage.CONFIG_SUCCESS, colorCodes.SUCCESS);
      },
      (error) => {
        this._snackBar.loadSnackBar(ErrorMessage.CONFIG_ERROR, colorCodes.ERROR);
       
      });
  }

  getAddressDetails(id, successResponseCallback) {
    Endpoints['GET_HOSPITAL_ADDRESS_DETAILS'] = `/cp/hospital-address/${id.toString()}`
    this._http.requestCall(Endpoints['GET_HOSPITAL_ADDRESS_DETAILS'], ApiMethod.GET).subscribe(
      (res) => {
        successResponseCallback(res);
      },
      (error) => {
        console.log(error)
      });
  }

  EditAddressDetails(id, payload, successResponseCallback) {
    Endpoints['EDIT_HOSPITAL_ADDRESS_DETAILS'] = `/cp/hospital-address/${id.toString()}`
    this._http.requestCall(Endpoints['EDIT_HOSPITAL_ADDRESS_DETAILS'] , ApiMethod.PUT,payload).subscribe(
      (res) => {
        successResponseCallback(res);
        this._snackBar.loadSnackBar(SuccessMessage.SHIPPING_ADDRESS_EDIT, colorCodes.SUCCESS);
      },
      (error) => {
        console.log(error)
      });
  }

  editDescription(id,payload){
    Endpoints['EDIT_DESCRIPTION'] = `/otl_parts/${id.toString()}/update-description`;
    this._http.requestCall(Endpoints['EDIT_DESCRIPTION'], ApiMethod.PUT,payload).subscribe(
      (res) => {
        this._snackBar.loadSnackBar(SuccessMessage.OTL_PARTS_DESCRIPTION, colorCodes.SUCCESS);
      },
      (error) => {
        console.log(error);
      });
  }

  showErrorMessage(message){
    this._snackBar.loadSnackBar(message, colorCodes.ERROR)
  }


  cpModuleAccess(successResponseCallback){
  this._http.requestCall(Endpoints.CHANNEL_PARTNER_ACCESS, ApiMethod.GET).subscribe(
    (res) => {
      successResponseCallback(res);
    });
}

retriggerInvoice(payload, successCallBack){
  this._http.requestCall(Endpoints.RETRIGGER_INVOICE, ApiMethod.GETPARAMS,payload).subscribe(
    (res) => {
      successCallBack(res);
    });
}


retriggerDC(payload, successCallBack){
  this._http.requestCall(Endpoints.RETRIGGER_DC, ApiMethod.GETPARAMS,payload).subscribe(
    (res) => {
      successCallBack(res);
    });
}
  

// get DC parts details 
getDcParts(payload, successCallBack){
  this._http.requestCall(Endpoints.GET_DC_PARTS, ApiMethod.GETPARAMS,payload).subscribe(
    (res) => {
      successCallBack(res);
    });
}
getDcList(payload, successCallBack){
  this._http.requestCall(Endpoints.GET_DC_LIST, ApiMethod.GETPARAMS,payload).subscribe(
    (res) => {
      successCallBack(res);
    });
}



// credit and debit notes 
searchCreditDebitList(payload, successResponseCallback) {
  this._http.requestCall(Endpoints.SECONDARY_CREDIT, ApiMethod.GETPARAMS,payload).subscribe(
    (res) => {
      successResponseCallback(res);
    });
}

addCredit(payload) {
  this._http.requestCall(Endpoints.SECONDARY_CREDIT, ApiMethod.POST,payload).subscribe(
    (res) => {
      this._snackBar.loadSnackBar(SuccessMessage.CREDIT, colorCodes.SUCCESS);
      this.navigateCreditDebitList()
    });
}

updateCredit(creditId, payload) {
  Endpoints['UPDATE_CREDIT_DETAILS'] = `/cp/secondary-credit/${creditId.toString()}`;
  this._http.requestCall( Endpoints['UPDATE_CREDIT_DETAILS'], ApiMethod.PUT,payload).subscribe(
    (res) => {
      this._snackBar.loadSnackBar(SuccessMessage.UPDATE_CREDIT, colorCodes.SUCCESS);
      this.navigateCreditDebitList()
    });
}
getInvoiceListwithTax(payload, successCallBack){
  this._http.requestCall(Endpoints.GET_INVOICE_NUMBER_WITH_TAX, ApiMethod.GETPARAMS,payload).subscribe(
    (res) => {
      successCallBack(res);
    });

}
getInvoiceNumber(payload, successResponseCallback) {
  this._http.requestCall(Endpoints.GET_INVOICE_NUMBER, ApiMethod.GETPARAMS,payload).subscribe(
    (res) => {
      successResponseCallback(res);
    });
}

getPartsBasedOnInvoiceNo(payload, successResponseCallback) {
  this._http.requestCall(Endpoints.GET_PARTS_BASED_INVOICE_NO, ApiMethod.GETPARAMS,payload).subscribe(
    (res) => {
      successResponseCallback(res);
    });
}
getCreditDetails(creditId, successResponseCallback) {
  this._loaderService.show();
  Endpoints['CREDIT_DETAILS'] = `/cp/secondary-credit/${creditId.toString()}`;
  this._http.requestCall(Endpoints['CREDIT_DETAILS'], ApiMethod.GET).subscribe(
    (res) => {
      this._loaderService.hide();
      successResponseCallback(res);
    });
}
getIssuanceList(successResponseCallback) {
  this._http.requestCall(Endpoints.GET_ISSUANCE_NUMBER_LIST, ApiMethod.GET).subscribe(
    (res) => {
      successResponseCallback(res);
    });
}
navigateCreditDebitList(){
  let roles = this._StorageService.getUserDetails().role;
  if (roles == Roles.Admin)   this._router.navigate(['beckman/secondary-sales/credit']);
  else if (roles == Roles.Channel_Partner)   this._router.navigate(['channel-partner/secondary-sales/credit']);
   else if (roles == Roles.Agent)   this._router.navigate(['agent/secondary-sales/credit']);
  else this._router.navigate(['beckman-billing/secondary-sales/credit']);
}

navigateToEditInvoice(id){ 
  let roles = this._StorageService.getUserDetails().role;
  if (roles == Roles.Admin)     this._router.navigate(['/beckman/secondary-sales/invoice/edit/' ,id ]);
  else if (roles == Roles.Channel_Partner)    this._router.navigate(['/channel-partner/secondary-sales/invoice/edit/' ,id ]);
  else  this._router.navigate(['/beckman-billing/secondary-sales/invoice/edit/' ,id ]);

}


navigateToEditDc(id){ 
  let roles = this._StorageService.getUserDetails().role;
  if (roles == Roles.Admin)     this._router.navigate(['/beckman/secondary-sales/delivery-challan/edit/' ,id ]);
  else if (roles == Roles.Channel_Partner)    this._router.navigate(['/channel-partner/secondary-sales/delivery-challan/edit/' ,id ]);
  else  this._router.navigate(['/beckman-billing/secondary-sales/delivery-challan/edit/' ,id ]);

}

navigateCreditEdit(id){
  let roles = this._StorageService.getUserDetails().role;
  if (roles == Roles.Admin)     this._router.navigate(['/beckman/secondary-sales/credit/edit/' ,id ]);
  else if (roles == Roles.Channel_Partner)    this._router.navigate(['/channel-partner/secondary-sales/credit/edit/' ,id ]);
  else  this._router.navigate(['/beckman-billing/secondary-sales/credit/edit/' ,id ]);
}

getPartsList(Payload, successResponseCallback){

  this._http.requestCall(Endpoints.CP_INVOICE, ApiMethod.GETPARAMS, Payload).subscribe(
    (res) =>{
      successResponseCallback(res);
    },
    (error) => {
      console.log(error)
    });
  }
getCpStockAvailable(payload,successResponseCallback) { 
  this._http.requestCall(Endpoints.CP_STOCK_AVAILABLE, ApiMethod.GETPARAMS,payload).subscribe(
    (res) => {
      successResponseCallback(res);
    },
    (error) => {
      console.log(error)
    });
}


getUpdateStatus(payload, successCallBack){
    this._http.requestCall(Endpoints.Change_DC_Status, ApiMethod.POST, payload).subscribe(
      (res) => {   
        successCallBack(res);
        this._snackBar.loadSnackBar(SuccessMessage.DC_STATUS, colorCodes.SUCCESS);
       },
      (error) => { 
        console.log(error); 
      }
    )

}

}
