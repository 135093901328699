
export enum ApiMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  DOWNLOAD = "DOWNLOAD",
  GETPARAMS = "GETPARAMS",
  SEARCHDOWNLOADPARAMS = "SEARCHDOWNLOADPARAMS",
  SEARCHPARAMS = "SEARCHPARAMS",
  DOWNLOAD_PARAMS = "DOWNLOAD_PARAMS",
  DOWNLOAD_BLOB = "DOWNLOAD_BLOB"
}



export enum Endpoints {
  // LOGIN = "/rest-auth/login/",
  LOGIN = "/api/token/",
  CHANGE_PASSWORD = "/change_password",
  FORGOT_PASSWORD = "/request-password-reset/",
  RESET_PASSWORD = "/complete-password-reset",
  REFRESH_TOKEN = "/api/token/refresh/",
  AUTH_ENDPOINT = "/authenticated_endpoint/",
  IMPORT_HOSPITAL = "/import_hospital",
  LIST_HOSPITAL = "/hospitals",
  ADD_HOSPITAL = "/hospitals/",
  EXPORT_HOSPITAL = "/export_hospital",
  IMPORT_CP = "/import_cp",
  EXPORT_CP = "/export_cp",
  LIST_CP = "/cp/",
  IMPORT_PARTS = "/import_parts",
  EXPORT_PARTS = "/export_parts",
  LIST_PARTS = "/parts/",
  IMPORT_OTLMASTER = "/import_otl",
  IMPORT_OTLPARTS = "/import_otl_parts",
  EXPORT_OTLMASTER = "/export_otl",
  DOWNLOAD_OTLDATA ="/download_otl",
  DOWNLOAD_OTL_PARTS_DATA ="/download_otl_parts/",
  EXPORT_OTLPARTS = "/export_otl_parts",
  GET_PRODUCTLINE = "/product_line/",
  LIST_OTLPARTS = "/otl_parts/",
  ADD_OTL = "/otl/",
  CP_DETAILS = "/get_cp",
  GET_DISTINCT_HOSPITAL = "/hospitals/cp",
  GET_INVOICE_BILLTOSHIPTO = "/billtoshipto?",
  GET_SITES_HOSPITAL = "/hospitals/sites",
  GET_OTL_BY_SITE = "/otl/",
  GET_OTL_PARTS_OPF = "/otl_parts/",
  ADD_BOOKING = "/opf/",
  TRAINING_VIDEOS = "/training_videos/",
  LIST_OPF = "/opf/",
  ADD_SHIPPING_ADDRESS = "/address/",
  ADD_ATTACHMENT_OPF = "/opf/attachment",
  IMPORT_REVENUE ="/import_revenue/", 
  IMPORT_SHIPPING= "/import_shipping/",
  IMPORT_CP_OUTBOUND = "/import_outbound/",
  IMPORT_FREEZE = "/import_freeze_invoice/",
  LIST_INVOICES = "/invoice/",
  LIST_PURCHASE_ENTRIES = "/get-purchase-entry/",
  ADD_PURCHASE_ENTRIES = "/purchase-entry/",
  ADD_PURCHASE_RETURN = "/returns/",
  SEARCH_BA ="/opf/ba",
  HOSPITAL_CP_SEARCH = "/hospitals/cp",
  HOSPITAL_SP_SEARCH = "/hospitals/sp",
  GET_STOCK = "/cp/stock/",
  CP_INVOICE = "/cp/invoice",
  CP_DELIVERY_CHALLAN = "/cp/dc/",
  MOVE_DC_TO_INVOICE = "/cp/dc/invoice/",
  SP_CHANNEL_PARTNER = "/sp/cp",
  SP_PARTS = "/sp/otl_parts",
  USERS = "/users/",
  IMPORT_USERS = '/import_users',
  EXPORT_USERS = '/export-users',
  EXPORT_OPF= "/export-opf",
  RELATED_PARTS = "/cp/stock/related",
  GET_PRIMARY_SECONDARY_DASHBOARD_LIST = "/app_report/primary_secondary_report",
  DASHBOARD_INVENTORY = "/dashboard/inventory-value",
  DASHBOARD_PRIMARY_SECONDARY_SALES_ORDER ="/app_dashboard/primary_secondary_sales_order_value", //"/app_dashboard/primary_secondary_sales_order_value",
  DASHBOARD_INVENTORY_DAYS_OUTSTANDING = "/app_dashboard/inventory_days_outstanding",
  INVENTORY_DIO = "/dashboard/inventory-sku",
  DASHBOARD_PRIMARY_TREND_LINE = "/app_dashboard/primary_trend_line",
  DASHBOARD_PRIMARY_TREND_REPORT = "/app_dashboard/primary_trend_report",
  DASHBOARD_SECONDARY_TREND_LINE = "/app_dashboard/secondary_trend_line",
  DASHBOARD_SECONDARY_TREND_REPORT = "/app_dashboard/secondary_trend_report",
  DASHBOARD_PRIMARY_SECONDARY_TREND_LINE = "/app_dashboard/primary_secondary_trend_line",
  LIST_STOCK_TRANSFER = "/stock-transfer/",
  GET_REGION = "/region",
  GET_STOCK_OTL_PARTS = "/cp/stock/customer_stocks_in_lot",
  GET_RELEATED_STOCK_PARTS="/cp/stock/related_stocks_with_other_customers",
  STOCK_SWAP= "/cp/stock/swap",
  STOCK_LIST= "/cp/stock/stock_log/",
  STOCK_TRANSFER = "/cp/stock/transfer",
  STOCK_TRANSFER_AVAILABLE_QTY = "/cp/stock/stock_availability_sum/?",
  CP_CONFIGURATION = "/cp/configuration",
  STOCK_REDUCE_QTY ="/cp/stock/reduce_quantity",
  CP_REVERSAL_STOCK_LIST= "/cp/stock/reversal_stock_list",
  CP_REVERSAL_STOCK_OPF = "/cp/stock/reversal_stock_through_opf",
  CP_REVERSAL_STOCK = "/cp/stock/reversal",
  IMPORT_OPF ="/import_opf",
  IMPORT_OPF_PARTS ="/import_opf_parts",
  GET_HOSPITAL_ADDRESS = "/cp/hospital-address",
  EXPORT_SECONDARY_INVOICE ="/export-cp-invoice",
  GET_OPF_PARTS_QTY = "/cp/stock/get_stock_for_part",
  IMPORT_STOCK = "/cp/stock_import",
  EXPORT_DC = "/export-cp-dc",
  EXPORT_Invoice = "/export-invoice",
  GET_NOTIFICATION_LIST = '/notifications',
  GET_CP_STOCK_FOR_SALES = '/otl/cp/sales',
  GET_OTL_LIST= "/otl-list/",
  CP_SITE_ID = "/cp/site-id",
  VALIDATE_HOSPITAL = "/validate-hospital",
  VALIDATE_OTL = "/validate-otl",
  CHANNEL_PARTNER_ACCESS = "/cp/access",
  ORACLE_OUTBOUND ="/oracle-outbound/",
  CHECK_SYNC_STATUS = "/status-sync/",
  START_SYNC = "/resume-sync/",
  STOP_SYNC="/stop-sync/",
  EXPORT_OUTBOUND = "/export-outbound",
  ORACLE_INBOUND ="/oracle-inbound/",
  ORACLE_OUTBOUND_ACK_MANUALLY = "/inbound-acknowledge-manual",
  EXPORT_INBOUND = "/export-inbound",
  EXPORT_ORACLE_DIFFERENCE_REPORT = "/export-oraclejson",
  GET_REPORTS_CP_PARTS= "/reports/cp/parts",
  GET_REPORTS_SITE_ID = "/reports/cp/sites",
  GET_REPORTS_CITIES = "/reports/city",
  STOCK_REPORT_List = "/reports/stock-report",
  EXPORT_STOCK_REPORT_LIST = "/reports/export-stock-report",
  GET_DC_PARTS = "/get-dc-parts",
  GET_DC_LIST = "/get-dc-list",
  EXPORT_OPF_INSTRUCTIONS = "/export-opf-instructions",
  EXPORT_OPF_TEMPLATE = "/export-opf-template",
  MENU_PERMISSION_LIST = "/menu_permission_list",
  ROLES = "/roles",
  PERMISSION_LIST = "/permission_list",
  GET_BA_EDIT_ACCESS ="/permissions/get-ba-edit-access",
  RETRIGGER_OPF_PART = "/retrigger-opf-part",
  GET_CP_EDIT_ACCESS = "/permissions/get-cp-edit-access",
  RETRIGGER_INVOICE = "/cp-invoice/retrigger",
  RETRIGGER_DC = "/retrigger-dc-part",
  FILTER_HOSPITALS = "/filter_hospitals",
  FILTER_HOSPITAL_AUDIT_LOG = "/filter_hospitals_audit_log/",
  FILTER_CP ="/filter_cp",
  GROUPED_USER_VIEW ="/grouped-user-view",
  STOCK_SWAP_TRANSFER = "/reports/swap-transfer-report",
  EXPORT_STOCK_SWAP_TRANSFER="/reports/export-swap-transfer-report",
  SECONDARY_CREDIT = "/cp/secondary-credit",
  GET_INVOICE_NUMBER ="/cp/invoice_without_cd",
  GET_INVOICE_NUMBER_WITH_TAX='/cp/invoice_without_cd/invoice',
  GET_PARTS_BASED_INVOICE_NO = "/cp-invoice-parts-withoutcd",
  STOCK_OVERVIEW_REPORT = "/reports/stock-overview-report",
  OPF_INVOICE_REPORT = "/app_report/opf_invoice_report",
  PRIMARY_SECONDARY_REPORT ="/app_report/primary_secondary_report", 
  DC_INVOICE_REPORT = "/app_report/dc_invoice_report",
  GET_ISSUANCE_NUMBER_LIST = "/cp/secondary-credit-filter",
  EXPORT_STOCK_OVERVIEW_REPORT = "/reports/export-stock-overview-report",
  ALL_PERMISSION_LIST ="/group_permission_list",
  POST_PERMISSION_LIST ="/group_permission_list/",
  STOCK_EXPORT ="/cp/stock/stock_log/export",
  GET_CUSTOMER_ADDRESS ="/get_customer_address",
  EXPORT_CREDITDEBIT_NOTE ="/export-credit-debit",
  Check_CP ="/check_cp_has_otl",
  Change_DC_Status = '/change-dc-part-status',
  CP_STOCK_AVAILABLE ="/cp/stock/check",
  EXPORT_FREEZE_TEMPLATE = "/export-freeze-invoice-template",
  EXPORT_FREEZE_INSTRUCTION ="/export-freeze-invoice-instructions",

  //vino
  GET_OTL_PARTS = "/get-otl-parts",
  GET_OTL_TRANSFER_LIST = "/otl_transfer",
  CREATE_OTL_TRANSFER_ = "/otl_transfer/",
  GET_CP_NAMES = "/hospitals/cp",
  GET_OTL_LIST_FOR_CP = "/otl",
  EXPORT_OTL_FILTER = "/otl_transfer/",
  GET_OTL_TRANSFER_REPORT_LIST = "/otl_transfer/report/",
  EXPORT_OTL_REPORT_FILTER = "/otl_transfer/report/",
  GET_AUDIT_LIST = "/audit_log"

 }

export enum SuccessCodes {
  HTTP_200_OK = 200, 
  HTTP_201_CREATED = 201,
  HTTP_202_ACCEPTED = 202,
}

export enum ErrorCodes {
  HTTP_400_BAD_REQUEST = 400,
  HTTP_401_UNAUTHORIZED = 401,
  HTTP_403_FORBIDDEN = 403,
  HTTP_404_NOT_FOUND = 404,
  HTTP_500_ERROR = 500,
  HTTP_CONN_REFUSED = 0,
}
export enum ErrorKeys {
  NON_FIELD_ERRORS = 'non_field_errors'
}

export enum colorCodes {
  SUCCESS = "Success",
  WARNING = "Warning",
  ERROR = "Error",
  INFO = "Info"
}
export enum BookingStatus {
  OPEN = "Open"
}
export enum sectors {
  'GOVT' = 'govt',
  'CE' = 'C&E' 
}
export const invoiceStatus  = {
  0 : 'OPEN',
  1 : 'ACKNOWLEDGE',
  2 : 'FROZEN',
  3 : 'Partially Acknowledged'
}
export enum OTL_params  {
  FOC = 'foc-contract',
}
export enum OPF_Type_Value {
  'OTL' = 'OTL',
  'Free of Cost - Contract' = 'FOC',
  'Free of Cost - Damaged' = 'FOC-D',
  'Free of Cost - Service Replacement' = 'FOC-S',
  'Free of Cost - Application Replacement' = 'FOC-A',
  'Free of Cost - Others' = 'FOC-O' 
}
export enum OPF_type  {
  'OTL' = 'OTL',
  'FOC' = 'Free of Cost - Contract',
  'FOC-O' = 'Free of Cost - Others' , 
  'FOC-D' = 'Free of Cost - Damaged',
  'FOC-S' = 'Free of Cost - Service Replacement',
  'FOC-A' = 'Free of Cost - Application Replacement'
}
export enum ORACLE {
'Not_Sent' = "Not Sent",
'Manual' = "Manual",
'Success' = "Success",
'Error' = "Error"
}

export enum invoiceType {
  'Standard_IN' = 'Standard Invoice',
  'NCMS_IN' = 'NCMS Invoice',
  'NCMS_L_IN' = 'Free of Cost Invoice',
  'Bill Only_L_IN' = 'Bill Only Invoice',
  'Credit Only_L_IN' ='Credit Only Invoice',
  'RMA Credit w/ Receipt_L_IN' = 'Credit Invoice with return'
}

export const Modules = {
  hospitals : 'view_hospitals',
  channelPartner  : 'view_channelpartner',
  otl :'view_otl',
  parts: 'view_parts', 
  otlParts:'view_otlparts',
  beckmanInvoice :'view_beckman_invoice',
  clientInvoice:'view_cpinvoice',
  dc:'view_deliverychallan',
  stockLog:'view_stocklog',
  config:'view_cpconfiguration',
  opf:'view_opf',
  inbound:'view_inbound',
  outbound:'view_outbound',
  users:'view_customuser',
  returns : "view_returns",
  stockReport :"view_stock_report",
  stockOverviewReport:"view_stockoverview_report",
  opfOverviewReport:"view_opf_invoice_report",
  primarySecondaryReport : "view_primary_secondary_report",
  dcInvoiceReport : "view_dc_invoice_report",
  dashboardSalesReport : "view_dashboard_sales_report",
  dashboardInventoryReport : "view_dashboard_inventory_report",
  dashboardPrimaryReport :  "view_dashboard_primary_report",
  dashboardSecondaryReport : "view_dashboard_secondary_report",
  dashboardPrimarySecondaryReport : "view_dashboard_primary_secondary_report",
  stockSwapTransferReport : "view_stocklog_report",
  creditDebit : "view_creditdebit",
  OtlTransfer : "view_otltransfer",//vino
  OtlTransferReport : "view_otl_transfer_report",//vino
  
  
}

export enum ActionItems {
  EDIT = 'Edit',
  ADD ='Add',
  EXPORT='Export',
  IMPORT ='Import',
  VIEW ='View',
  DELETE ="Delete",
  DIFFERENCE_REPORT = 'Export Difference Report',
  EXPORT_INBOUND ='Export Inbound',
  EXPORT_BECKMAN_INVOICE = 'Export Beckman Invoice',
  IMPORT_BECKMAN_INVOICE ='Import Beckman Invoice',
  IMPORT_CP_STOCK ="Import CP Stock",
  EXPORT_FR_TEMPLATE = "Export freeze invoice template", 
  EXPORT_FR_INSTRUCTION = "Export freeze invoice instruction", 
  EXPORT_CP_STOCK = "Export CP Stock",
  EDIT_ORACLE_SYNC= "Edit Oracle Sync",
  VIEW_ORACLE_SYNC="View Oracle Sync",
  EXPORT_STOCKLOG_REPORT = "Export stocklog report",
  EXPORT_STOCK_REPORT = "Export stock report",
  EXPORT_STOCK_OVERVIEW_REPORT= "Export stockoverview report",
  EXPORT_OPF_REPORT  = "Export opf invoice report",
  EXPORT_Primary_Secondary_REPORT  = "Export primary secondary report",   
  SYNC_OPF_REPORT  = "Sync opf invoice report",
  SYNC_DC_REPORT  = "Sync dc invoice report",
  REDUCE ="Reduce",
  REVERSE = "Reverse",
  ADD_STOCK_SWAP ="Add Stock Swap",
  ADD_STOCK_TRANSFER ="Add Stock Log Stock transfer",
  EXPORT_STOCK_LOG_STOCK_TRANSFER = "Export Stock Log Stock transfer"
}
export const dcType = {
  0 : 'Not Invoiced',
  1: 'Partially Invoiced',
  2 : 'Invoiced',
  3 : 'Cancelled'
}

export const OPFControls  = {
  custName : 'Client Name',
  type : 'OPF Type',
  clientPOnumber : 'Client PO Number',
  OTLNumber : 'OTL Number',
  transcationType : 'Transaction Type',
  priority : 'Priority',
  bill_to : 'Bill to Name and Address',
  ship_to: 'Ship to Name and Address',
  add_parts : 'Add Parts',
  quantity : 'Quantity',
  comment: 'Comments',
  attachments : 'Attachments',
  show_sync: 'Show Oracle Sync'
}

export const SecondaryControls  = {
  custName : 'Client Name',
  invoiceDate : 'Invoice Date',
  orderNumber : "Buyer's Order Number",
  orderDate :"Buyer's Order Date",
  deliveryNote:"Delivery Note",
  deliveryNoteDate:"Delivery Note  Date",
  supplierRef: "Supplier’s Ref",
  supplierRefDate:"Supplier's Ref Date" ,
  dispatchDocNo:"Dispatch Doc No.",
  dispatchDocDate:"Dispatch Doc Date",
  waybillNumber:"E-Way Bill Number" ,
  waybillDate:"E-Way Bill Date",
  dispatchThrough:"Dispatch Through" ,
  referenceNumber: "Reference No." ,
  destination:"Destination",
  otherRef : "Others Reference" ,
  termsOfPayment : "Terms of Payment (In Days)" ,
  bill_to : 'Bill to Name and Address',
  ship_to: 'Ship to Name and Address',
  add_parts : 'Add Parts',
  quantity : 'Quantity',
  price :"Unit Price",
  tax: "Tax",
  tcs :"TCS",
  cess: "CESS",
  remarks : "Remarks",
  discount :"Discount",
  packageAmount  : "Package Amount",
  cp_site :"CP Bill-to Site",
  email :"Email",
  dcDate : "DC Date",
  add_dc : "Add DC",
  cprt_add: 'Add CPRT',
  cprt_tax: 'CPRT Tax',
  cprt_dc: 'CPRT DC',
  test_name : 'Test Name',
  cost_per_test : 'Cost Per Test',
  hssn_code : 'HSN Code'


}

















export const EMAIL_ID_REGEX = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
export const MOBILE_REGEX = /^(\+\d{1,3}[- ]?)?\d{10}$/
export const PANCARD_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}/
export const PINCODE_REGEX = /[1-9][0-9]{5}$/
export const GST_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
export const ALPHANUMERIC = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/
export const ONLY_NUMBERS_REGEX = /^\d+$/





export const BECKMAN_GODOWN = "Tamil Nadu"
export const SHIPPING ="shipping";
export const BILLING = "billing";
export const BILL_TO = "bill_to";
export const SHIP_TO = "ship_to";






export enum SuccessMessage {
  HOSPITAL_IMPORT = "Success! Hospital Master Imported",
  HOSPITAL_DELETE = "Success! Hospital Deleted",
  HOSPITAL_ADD="Success! Hospital Added",
  HOSPITAL_EDIT = "Success! Hospital Updated",
  OTL_ADD = "Success! OTL Added",
  OTL_EDIT = "Success! OTL Updated",
  OTL_DELETE = "Success! OTL Deleted",
  CP_IMPORT =  "Success! Channel Partner Imported",
  CP_ADD =  "Success! Channel Partner Added",
  CP_EDIT= "Success! Channel Partner Updated",
  CP_DELETE = "Success! Channel Partner Deleted",
  PARTS_IMPORT= "Success! Parts Master Imported",
  PARTS_ADD="Success! Parts Added",
  PARTS_DELETE="Success!  Parts Deleted",
  PARTS_EDIT="Success!  Parts Updated",
  OTL_PARTS_IMPORT = "Success! OTL Parts Imported",
  OTL_PARTS_EDIT = "Success! OTL Parts Updated",
  OTL_PARTS_DELETE =  "Success! OTL Parts Deleted",
  OPF_EDIT =  "OPF Updated Successfully",
  OPF_ADD = "Success! Booking  Added",
  OPF_UPDATE = "OPF Updated successfully",
  PURCHASE_ENTRIES_ADD = "Success! Purchase Entry Added",
  USER_ADD = "Success! User Added",
  USER_DELETE="Success!  User Deleted",
  USER_IMPORT = "Success! Users Imported",
  USERS_EXPORT = "Success! Users Exported",
  USER_EDIT = "Success! User Updated",
  OTL_IMPORT= "Success! OTL Master Imported",
  INVOICE_ADD = "Success! Invoice Raised Successfully",
  INVOICE_EDIT= "Invoice Updated Successfully",
  DC_ADD = "Success! Delivery Challan Created Successfully",
  DC_EDIT= "DC Updated Successfully",
  DC_TO_INVOICE = "DC converted to Invoice Successfully",
  PASSWORD_CHANGE = "Success! Password Changed",
  SHIPPING_ADDRESS_ADD = "Success!  Address Added",
  SHIPPING_ADDRESS_DELETE = "Success!  Address Deleted",
  SHIPPING_ADDRESS_EDIT = "Success!  Address Updated",
  INVOICE_UPLOAD = "Invoice Uploaded Successfully",
  HOSPITAL_EXPORT = "Success! Hospital Master Exported",
  OTL_EXPORT = "Success! OTL Master Exported",
  CP_EXPORT = "Success! Channel Partner Exported",
  PARTS_EXPORT = "Success! Parts Master Exported",
  OTL_PARTS_EXPORT = "Success! OTL Parts Exported",
  BOOKING_EXPORT = "Success! Booking Master Exported",
  CONFIG_SUCCESS = "Success! Print Configuration updated",
  STOCK_REVERSAL = "Stock Reversed Successfully ",
  STOCK_REDUCE = "Stock Reduced Successfully ",
  OTL_PARTS_DESCRIPTION = "Updated  OTL Parts Description Successfully ",
  SECONDARY_INVOICE_EXPORT = "Success! Secondary Invoice Exported",
  DELIVERY_CHALLAN_EXPORT = "Success! Delivery Challan Exported",
  OTL_PARTS_ADD = 'Success! OTL Parts Added',
  INVOICE_DELETED = "Invoice Deleted Successfully",
  INVOICE_FROZEN = "Invoice Frozen Successfully",
  OUTBOUND_EXPORT = "Success! Outbound Exported",
  INBOUND_ACK_MANUALLY ="Success! Sales order number is added manually to this OPF",
  INBOUND_EXPORT = "Success! Inbound Exported",
  INBOUND_DIFFERENCE_REPORT = "Success! Inbound Difference Report Exported",
  EXPORT_STOCK_REPORT_LIST = "Success! Stock Report Downloaded",
  EXPORT_OPF_UPLOAD_TEMPLATE = "Success!  OPF Upload Template Downloaded",
  EXPORT_OPF_UPLOAD_INSTRUCTIONS = "Success!  OPF Instructions Format Downloaded",
  EXPORT_FREEZE_UPLOAD_TEMPLATE = "Success! FREEZE Upload Template Downloaded",
  EXPORT_FREEZE_UPLOAD_INSTRUCTIONS = "Success!  FREEZE Instructions Format Downloaded",
  EXPORT_STOCK_SWAP_REPORT = "Success! Stock Transfer & Swap Report Downloaded",
  CREDIT = " Success! Created Successfully ",
  UPDATE_CREDIT = " Success! Updated Successfully ",
  EXPORT_STOCK_OVERVIEW_REPORT = "Success! Stock Overview Report Downloaded",
  EXPORT_OPF_INVOICE_REPORT = "Success! OPF Invoice Report Downloaded",
  EXPORT_DC_INVOICE_REPORT = "Success! DC Invoice Report Downloaded",
  EXPORT_PRIMARY_SECONDARY_INVOICE_REPORT = "Success! Primary Secondary Invoice Report Downloaded",
  PERMISSION_MATRIX = "Success! Permission Changed Successfully",
  STOCKS_REVERTED_TO_DC_AGAIN = 'The Stock details will be updated in DC once after the submission of Invoice',
  BECKMAN_ACKNOWLEDGE = "Successfully Acknowledged",
  BECKMAN_ADMIN_ACKNOWLEDGE = "Successfully updated the Acknowledged Quantity.",
  STOCK_TRANSFER_EXPORT = "Success! Stock Transfer Exported",
  STOCK_TRANSFER = "Transferred Successfully",
  DONT_ALLOW_ZERO_NETAMOUNT = 'Dont Allow Net Amount As Zero For FoC',
  DC_STATUS = 'Status Updated Successfully!',
  STOCK_CHECK = "The selected OTL has no Stock available.",
  //vino
  CREATE_OTL_TRANSFER= "Transferred Successfully",
  EXPORT_OTL_LIST = "Success! OTL List Downloaded",
  EXPORT_INVENTORY_LIST = "Success! Inventory Report Downloaded",
  EXPORT_SALES_LIST = "Success! Sales Report Downloaded",
  EXPORT_PRIMARY_LIST = "Success! Primary Report Downloaded",
  EXPORT_SECONDARY_LIST = "Success! Secondary Report Downloaded",
  EXPORT_PRIMARY_SECONDARY_LIST = "Success! Primary Secondary Report Downloaded",
  EXPORT_AUDIT_LIST = "Success! Audit Log Downloaded",
  DOWNLOAD_OTL_DATA = "Success! OTL Master List Downloaded",
  DOWNLOAD_OTL_PARTS_DATA = "Success! OTL Parts List Downloaded",
}
export enum ErrorMessage  {
 SHIPPING_ADDRESS_LIST =  "Error! When Getting Address List",
 SHIPPING_ADDRESS_DETAIL = "Error! When Getting Address",
 STOCK_RELATED_PARTS = "Error! In fetching Related parts",
 ENTER_ATLEAST_ONE_QUANTITY = "Please enter atleast one return quantity",
 SESSION_EXPIRED=  "Session Expired",
 UNKNOWN_ERROR_CODE =  'Unknown Error Code',
 SERVER_UNAVAILABLE = "Server is unavailable",
 INTERNAL_SERVER_ERROR ="Internal Server Error",
 REFRESH_TOKEN_EXPIRY = "Token is invalid or expired" ,// Don't delete as it is used for server check for token expiry
 NO_RELATED_PARTS = "No related parts for this stock",
 OTL_TYPE = "Error! This Part can't be added as it is belongs to Free of Cost - Contract",
 CONFIGURATION ="Error! Unable to Fetch the Configuration",
 CONFIG_ERROR = "Error! Unable to update configuration",
 EMPTY_PARTS= "There is no parts for relevant OTLNumber",
 INVOICE_NAMING_CONVENTION = "Navigate to the configuration and Please configure the Invoice naming convention.",
 DC_NAMING_CONVENTION ="Navigate to the configuration and Please configure the Delivery Challan naming convention.",
 //C_NAMING_CONVENTION ="Navigate to the configuration and Please configure the D_NAMING_CONVENTION ="Navigate to the configuration and Please configure the Debit naming convention.", naming convention.",
 //D_NAMING_CONVENTION ="Navigate to the configuration and Please configure the Debit naming convention.",
 CD_NAMING_CONVENTION = "Navigate to the configuration and Please configure both Credit and Debit naming convention.",
 NO_OTLNUMBER_STOCK = "There is no Stock available for a client",
 IN_ACTIVE_PARTS= "The selected part has been made Inactive",
 ATTACHMENT_FILE_NAME_LENGTH = "File name is too long, Kindly select the filename within 200 character",
 ATTACHMENT_FILE =  "Please attach (pdf ,png ,jpg) file",
 NOT_ACK_MANUALLY = "Oracle sync is in progress, you couldn't add sales order number manually. Please contact admin",
 ALREADY_INVOICED_UNABLE_TO_EDIT_DC = "has already been fully Invoiced",
 NO_INVOICE_RAISED_FOR_THIS_HOSPITAL = "No Invoice Raised for this Hospital or Unmapped Invoice",
 CREDIT_QTY_REDUCED_PART_STOCK = "Credit quantity cannot be reduced for this part, stock reduced "
}


export const CreditDebitControls  = {
  issuance_comment: 'Issuance Comment',
  tax : 'Tax',
  edit_line : 'Edit Line',
  cp_bill_to : 'CP Bill To',
  cp_ship_to : 'CP Ship To'
}

export const OtlTransferControls  = {
   otl_transfer_add : 'Add',
  otl_transfer_export : 'Export'
 
}