import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Roles } from 'src/app/modules/auth/model/user';
import { ActionItems } from '../constants';


@Injectable(
  {providedIn: 'root'}
  )
export class UtilsService {
  
  private sidebarToggle = new BehaviorSubject(false);
  sidebarStatus = this.sidebarToggle.asObservable();

  constructor() { }

  toggleSiderbar(status: boolean) {
    this.sidebarToggle.next(status);
  }

  isActiveStatus (status : any){
      if (status == 1) {
        return 'Active';
      }
      return 'Inactive';
  }

  invoiceStatus (status:any) {
    
    switch(status) {
      case 0:
        return 'Open'
      case 1:
        return 'Acknowledged'
      case 2:
        return 'Frozen'
      case 3:
        return 'Partially Acknowledged'
    }
    
 
  }

camelCase(value:string) { 
    return value.slice(0,1).toUpperCase() + value.slice(1,value.length).toLowerCase();
}

rupeeFormat(value :any){
  return Number(value).toLocaleString('en-IN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    // style: 'currency',
    // currency: 'INR'
  })
}

toUpper = function(x){ 
  return x.toUpperCase();
}

// returns [{},{}]
groupByProperty(array,key1, key2?:string ){
  let obj= {};
  return array.reduce(function(currentArray, data) {
    var key = key2 ? data[key1]  : (data[key1] + '-' + data[key2]);
    
    if(!obj[key]) {
      obj[key] = Object.assign({}, data);// create new object 
      currentArray.push(obj[key]);
    }   
    return currentArray;
  }, [])
}

numberSorting (param1, param2) {
  if (param1 === null && param2 === null) {
  return 0;
  }
  // not an number 
  if(isNaN(param1)){ return -1}
  if(isNaN(param2)){ return 1}
  // for null value 
  if (param1 === null) {
  return -1;
  }
  if (param2 === null) {
  return 1;
  }
  return param1 - param2;
}

alphaNumbericSorting(a, b) {
  if(a === b){
    return  0
  }
  if (!isNaN(a) && !isNaN(b)){
    return a -b;
  } 
  if (isNaN(a) === isNaN(b)) {
      return a < b ? -1 : 1;
  }
  // number and string type
  return isNaN(a)  < isNaN(b) ? -1 : 1;

}

// params:- [], fieldName ; return an array with unique records 
getDistinctRecords(data, field){    
  let arrValue =  [...new Set(data.map(item => item[field]))]
   let  distinctClients = arrValue.map((res)=>{
          return data.find((value)=> {
            if(value[field] == res)  return value;
          });
   });
   return distinctClients;
  }   
//params- [], filedName- [] ; return an array with unique records 
  groupByMultipleKeys(array, fieldArray ){
    let obj = Object.create(null);
     let result = array.filter(o => {
        var key = fieldArray.map(k => o[k]).join('|');
        if (!obj[key]) {
          obj[key] = true;
            return true;
        }
    });
    return result;
  }

  isAdminRole (currentUserRole){
    return currentUserRole.replace(/\s/g, "").toLowerCase() == Roles.Admin.toLowerCase()
  }

  isCpRole(currentUserRole){
    return currentUserRole.replace(/\s/g, "").toLowerCase() == Roles.Channel_Partner.replace(/\s/g, "").toLowerCase()
  }

  isBaRole(currentUserRole){
    return currentUserRole.replace(/\s/g, "").toLowerCase() == Roles.Agent.replace(/\s/g, "").toLowerCase()
  }

  isAdminBACpRole(currentUserRole){
   currentUserRole = currentUserRole.replace(/\s/g, "").toLowerCase();
  return currentUserRole == Roles.Admin.toLowerCase() ||  currentUserRole == Roles.Channel_Partner.replace(/\s/g, "").toLowerCase() || currentUserRole == Roles.Agent.replace(/\s/g, "").toLowerCase() ? true : false
  
  }


  moduleName(){
   let  role = JSON.parse(localStorage.getItem('user'))['role'];
    if (role == Roles.Admin) return 'beckman'
    else if (role == Roles.Channel_Partner) return 'channel-partner'
    else if (role == Roles.Agent) return 'agent'
    else return 'beckman-billing'
  }

  getActionsPermission(moduleName , actionName){
    let actionsList = JSON.parse(localStorage.getItem('actions'));
    return  actionsList && typeof actionsList[moduleName][ActionItems[actionName]] != 'undefined'  ?  true : false;
  }
}
