import { Injectable } from '@angular/core';
import { CanLoad, Router, Route, UrlSegment } from '@angular/router';
import { AuthService } from './../../modules/auth/service/auth.service';
import { StorageService } from '../services/storage/storage.service';
import { Roles } from 'src/app/modules/auth/model/user';
import { UtilsService } from '../services/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private _authService: AuthService, private _storage: StorageService,private _utils : UtilsService,
    private _router: Router) {

  }
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const redirectUrl = segments.reduce((path, currentSegment) => {
      return `${path}/${currentSegment.path}`;
    }, '');
    if(this._authService.isLoggedIn()) {
      const currentUser = this._storage.getUserDetails();
      if(route.data.role && (route.data.role !== Roles.OTHERUSERS && route.data.role.toLowerCase().indexOf(currentUser.role.toLowerCase()) === -1) 
      || (route.data.role == Roles.OTHERUSERS && this._utils.isAdminBACpRole(currentUser.role) ) ) {
        this._storage.redirectBasedonRoles(currentUser);
        return false;
      } 
      return true;
    }
    else {
      this._router.navigate(['auth'], {queryParams:{'redirectURL':redirectUrl}});
      return false;
    }
    
  }
  
}

