import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { StorageService } from './../storage/storage.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/service/auth.service';
import { Router } from '@angular/router';
import { HttpService } from '../http/http.service';
import { LoaderService } from '../utils/loader.service';
import { ErrorMessage, Endpoints } from '../constants';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor{

  private refreshingInProgress: boolean;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(private _storage: StorageService, private _authService: AuthService, private router: Router, private _http: HttpService, private _loaderService: LoaderService) { }

 
  intercept(req: HttpRequest<any>, next: HttpHandler, isload?:any): Observable<HttpEvent<any>> {
      const accessToken = this._storage.getToken();
     if(req.body === "noindicator")
     {
      Object.defineProperty(req, 'body', {
        value: '',
        writable: false
      });
      this._loaderService.loaderHide();    
     }
      else
      {
        this._loaderService.loaderShow();
      }
     

      return next.handle(this.addAuthorizationHeader(req,accessToken)).pipe(catchError(err => {
        if(err instanceof HttpErrorResponse && err.status === 401) { 
            if(err.url !==`${environment.apiUrl}${Endpoints.REFRESH_TOKEN}`){
            const refreshToken = this._storage.getRefreshToken();
            if(refreshToken && accessToken) {
              return this.refreshToken(req,next);
            }
          }
          else {
            this._http.handleRefreshTokenExpiry(err, this);
          }
        }
       

        if (err instanceof HttpErrorResponse && err.status === 403) {
          // logout and redirect to login page
          return this.logoutAndRedirect(err);
        }
       
       
        

       this._http.handleError(err,this);
       this._loaderService.loaderHide();
      }), finalize(() => this._loaderService.loaderHide()));
  }

  private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
    }
    return request;
  }

  private logoutAndRedirect(err): Observable<HttpEvent<any>> {
    
   this._authService.logout();
    this.router.navigateByUrl('/login');
    return throwError(err);
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);
     console.log(this.accessTokenSubject, '--78--')
      return this._http.refreshToken().pipe(
        switchMap((token:any) => {

          this.refreshingInProgress = false;
          this.accessTokenSubject.next(token.access);
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request, token.access));
        })
      );
  }
}
