import { Injectable } from '@angular/core';
import { HttpService } from './../../../core/services/http/http.service';
import { StorageService } from './../../../core/services/storage/storage.service';
import { LoginPayload, ForgotPwd } from './../interface/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { Endpoints, ApiMethod, SuccessCodes, colorCodes } from 'src/app/core/services/constants';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MomentService } from 'src/app/core/services/utils/moment.service';
import { PermissionMenuListService } from 'src/app/core/services/utils/permission-menu-list.service';
import { FilterService } from '../../agent/filterService';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = new BehaviorSubject(null);
  private redirectUrl;
  constructor(
    private _http: HttpService,
    private _storage: StorageService,
    private _router: Router,
    private _momentService :MomentService,
    private _route: ActivatedRoute,
    private _permissionMenuListService : PermissionMenuListService,
    public _FilterService : FilterService,
    private _snackBar: SnackbarService,
  ) { }

  login(loginPayload: LoginPayload, errorResponseCallback): void {
    this.redirectUrl = this._route.snapshot.queryParams["redirectURL"];
    this._http.requestCall(Endpoints.LOGIN, ApiMethod.POST, loginPayload)
    .pipe(tap(
      res => {
        if (res['license_details']){
          res['license_details'] =  res['license_details'].map (res => {
            return {siteId : res.siteId, dlExpiry :this._momentService.getDateDiff(res.dlExpiry),dl20b: res.dl20b, dl21b: res.dl21b,primaryLock : res.primaryLock, secondaryLock : res.secondaryLock , gst: res.gst, pan: res.pan , state : res.state, stockTransferLock: res.stockTransferLock}
          });
          return res['license_details'];
        }
      }

    )).subscribe((res)=> {
      this.user$.next(res);
      this._storage.setToken(res.access);
      this._storage.setRefreshToken(res.refresh);
      this._storage.setUserDetails(JSON.stringify({'email':res.email,'role':res.role,'license_details' : res.license_details}));
      // to access all the roles and set in local storage 
      this.getPermissionMenu();
    }, (error) => {errorResponseCallback()});
  }

  forgot(forgotPayload: ForgotPwd, errorResponseCallback): void {
    this._http.requestCall(Endpoints.FORGOT_PASSWORD, ApiMethod.POST, forgotPayload)
    .subscribe(() => {           
        this._snackBar.loadSnackBar('Password reset link is sent to the entered email id', colorCodes.SUCCESS);
    },  (error) => {errorResponseCallback()})
  }

  getPermissionMenu(){
    this._http.requestCall(Endpoints.MENU_PERMISSION_LIST, ApiMethod.GET).subscribe((menuList) => {
      this._storage.setMenuList(JSON.stringify(menuList));
      this.getRoles();
    },
    (error) => { console.log(error); });
  }
  getRoles(){
    this._permissionMenuListService.getUserRoles(roles =>{
      this._storage.setRoles(JSON.stringify(roles));
      this.redirect();
    })
  }

  isLoggedIn() {
    return !!this._storage.getToken();
  }


  logout() {
    this._storage.deleteToken();
    this.user$.next(null);
    this._router.navigate(['auth']);
    this._FilterService.openfilters = new BehaviorSubject<any>(null);
    this._FilterService.bookedfilter  = new BehaviorSubject<any>(null);
    this._FilterService.rejectedfilter = new BehaviorSubject<any>(null);
  }
  redirect() {
    const currentUser = this.getCurrentUser();
    if(this.redirectUrl) {
      this._router.navigate([this.redirectUrl]);
    }
    else {
      this._storage.redirectBasedonRoles(currentUser);
    }
    
  }

  getCurrentUser() {
    return this.user$.value;
  } 

}
