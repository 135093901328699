import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class InputDialogComponent implements OnInit {
  public dialogForms: FormGroup;
  constructor( 
    public _dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    )  { }

  ngOnInit() {
    let groupingFields ={};
    this.data.fields.forEach(field=>{
      groupingFields[field.fieldName] = new FormControl('', field.validator);  
    });
    this.dialogForms =new FormGroup(groupingFields);
  } 
  dialogClose(params?:any){
    this._dialogRef.close(params ? this.dialogForms.value : '');
  }


}
